import React from "react";
import { useNavigate } from "react-router-dom";

import projectorIcon from "../images/icons/projector.svg";

const FooterIconBar = (props) => {
  const navigate = useNavigate();

  const navigateToHome = () => {
    navigate("/");
  };

  return (
    <div className="footer-icon-container">
      <div className="home-icon" onClick={navigateToHome}></div>
      <div className="tutorial-icon" onClick={props.onOpenTutorial}></div>
      <img className="footer-icon" src={projectorIcon} alt=""  onClick={props.onOpenPresentaionView} />
      <div 
        className="full-screen-icon"
        onClick={props.onFullscreenToggle}
      ></div>
    </div>
  );
};

export default FooterIconBar;
