import React, { useRef, useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import tutorialSlideOne from "../images/tutorials/tutorial_0.png";
import tutorialSlideTwo from "../images/tutorials/tutorial_4.png";
import tutorialSlideThree from "../images/tutorials/tutorial_3.png";
import tutorialSlideFour from "../images/tutorials/tutorial_5.png";
import tutorialSlideFive from "../images/tutorials/tutorial_2.png";


const TutorialSlider = (props) => {

  const voicesEnglish = [
    {
      id: 1,
      url: "https://res.cloudinary.com/ditejyeth/video/upload/v1708322507/sample_audio_z7kvt6.mp3",
    },
    {
      id: 2,
      url: "https://res.cloudinary.com/ditejyeth/video/upload/v1708322507/sample_audio_z7kvt6.mp3",
    },
    {
      id: 3,
      url: 'https://res.cloudinary.com/ditejyeth/video/upload/v1708322507/sample_audio_z7kvt6.mp3',
    },
    {
      id: 4,
      url: 'https://res.cloudinary.com/ditejyeth/video/upload/v1708322507/sample_audio_z7kvt6.mp3',
    },
    {
      id: 5,
      url: 'https://res.cloudinary.com/ditejyeth/video/upload/v1708322507/sample_audio_z7kvt6.mp3',
    },
  ];

  const voicesTamil = [
    {
      id: 1,
      url: "https://res.cloudinary.com/ditejyeth/video/upload/v1708321804/1-T-Tutorial_imhmnb.mp3",
    },
    {
      id: 2,
      url: "https://res.cloudinary.com/ditejyeth/video/upload/v1708322288/2-T-Tutorial_qh158z.mp3",
    },
    {
      id: 3,
      url: 'https://res.cloudinary.com/ditejyeth/video/upload/v1708322392/3-T-Tutorial_oa5pcr.mp3',
    },
    {
      id: 4,
      url: 'https://res.cloudinary.com/ditejyeth/video/upload/v1708322393/4-T-Tutorial_r1mjrb.mp3',
    },
    {
      id: 5,
      url: 'https://res.cloudinary.com/ditejyeth/video/upload/v1708322392/5-T-Tutorial_nx0u8p.mp3',
    },
  ];


  const swiperRef = useRef();
  const [audioRefId, setAudioRefId] = useState(null);
  const [playAudio, setPlayAudio] = React.useState(false);
  const audioRefOne = useRef();
  const audioRefTwo = useRef();
  const audioRefThree = useRef();
  const audioRefFour = useRef();
  const audioRefFive = useRef();

  useEffect(() => {

  }, [props.variable]);


  const resetSlider = () => {
    setTimeout(() => {
      switch (audioRefId) {
        case 0:
          audioRefOne.current.pause();
          break;
        case 1:
          audioRefTwo.current.pause();
          break;
        case 2:
          audioRefThree.current.pause();
          break;
        case 3:
          audioRefFour.current.pause();
          break;
        case 4:
          audioRefFive.current.pause();
          break;
        default:
          break;
      }
      setPlayAudio(false);
      swiperRef.current.slideTo(0)
    }, 1000);
  };


  const onNextSlideClick = () => {
    switch (audioRefId) {
      case 0:
        audioRefOne.current.pause();
        break;
      case 1:
        audioRefTwo.current.pause();
        break;
      case 2:
        audioRefThree.current.pause();
        break;
      case 3:
        audioRefFour.current.pause();
        break;
      case 4:
        audioRefFive.current.pause();
        break;
      default:
        break;
    }
    setPlayAudio(false);
  };

  const handleAudioPlay = (id) => {
    setAudioRefId(id);
    switch (id) {
      case 0:
        audioRefOne.current.play();
        break;
      case 1:
        audioRefTwo.current.play();
        break;
      case 2:
        audioRefThree.current.play();
        break;
      case 3:
        audioRefFour.current.play();
        break;
      case 4:
        audioRefFive.current.play();
        break;
      default:
        break;
    }
    setPlayAudio(true);
  };

  const pauseAudioPlay = () => {
    switch (audioRefId) {
      case 0:
        audioRefOne.current.pause();
        break;
      case 1:
        audioRefTwo.current.pause();
        break;
      case 2:
        audioRefThree.current.pause();
        break;
      case 3:
        audioRefFour.current.pause();
        break;
      case 4:
        audioRefFive.current.pause();
        break;
      default:
        break;
    }
    setPlayAudio(false);
  };

  const handleEnd = () => {
    setPlayAudio(false);
  };


  return (
    <section>

      <Swiper
        spaceBetween={50} allowTouchMove={false}
        slidesPerView={1}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
      >
        <SwiperSlide>
          <div className="flex-column-container">
            <div className="tutorial-content-row">
              <img className="tutorial-slide-img" src={tutorialSlideOne} alt="" />
              <div className="hotspot-circle-container">
                <div className="hotspot-circle-out">
                  <div className="hotspot-circle-in"></div>
                </div>
                <div className="hotspot-label">
                  <span>Hotspot</span>
                </div>
              </div>
              {localStorage.getItem("language") == 'en' && (
                <audio className="hide-audio" ref={audioRefOne} controls onEnded={handleEnd}>
                  <source src={voicesEnglish[0].url} type="audio/mp3" />
                  Your browser does not support the audio element.
                </audio>
              )}
              {localStorage.getItem("language") == 'sl' && (
                <audio className="hide-audio" ref={audioRefOne} controls onEnded={handleEnd}>
                  <source src={voicesEnglish[0].url} type="audio/mp3" />
                  Your browser does not support the audio element.
                </audio>
              )}
              {localStorage.getItem("language") == 'tm' && (
                <audio className="hide-audio" ref={audioRefOne} controls onEnded={handleEnd}>
                  <source src={voicesTamil[0].url} type="audio/mp3" />
                  Your browser does not support the audio element.
                </audio>
              )}
              <section className="tutorial-content-desc-container">
                <section className="tutorial-audio-container mb-2">
                  {!playAudio && (
                    <div className="flex-row-center">
                      <span className="info-view-text">Click to play</span>
                      <div className="audio-icon-container" onClick={() => handleAudioPlay(0)}>
                        <div className="audio-img"></div>
                      </div>
                    </div>
                  )}
                  {playAudio && (
                    <div className="tutorial-audio-contain audio-container" onClick={pauseAudioPlay}>
                      <ul className="tutorial-audio-div audio-div">
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                      </ul>
                      <div className="audio-icon-container" onClick={() => handleAudioPlay(0)}>
                        <div className="audio-pause-img"></div>
                      </div>
                    </div>
                  )}
                </section>
                <span className="tutorial-desc">
                  {localStorage.getItem("language") == 'en' && (
                    <span>To access detailed information about a particular hotspot, simply click on the yellow bubble associated with it.</span>
                  )}
                  {localStorage.getItem("language") == 'sl' && (
                    <span>විශේෂිත හොට්ස්පොට් එකක් පිළිබඳ සවිස්තරාත්මක තොරතුරු වෙත ප්‍රවේශ වීමට, එයට සම්බන්ධ කහ බුබුල මත ක්ලික් කරන්න.</span>
                  )}
                  {localStorage.getItem("language") == 'tm' && (
                    <span>ஒரு குறிப்பிட்ட ஹாட்ஸ்பாட் பற்றிய விரிவான தகவலை அணுக, அதனுடன் தொடர்புடைய மஞ்சள் குமிழியைக் கிளிக் செய்யவும்.</span>
                  )}
                </span>
              </section>

            </div>
            <div className="ellipse-group">
              <div
                className="ellipse-circle-active"
                onClick={() => { swiperRef.current.slideTo(0); onNextSlideClick(); }}
              ></div>
              <div
                className="ellipse-circle"
                onClick={() => { swiperRef.current.slideTo(1); onNextSlideClick(); }}
              ></div>
              <div
                className="ellipse-circle"
                onClick={() => { swiperRef.current.slideTo(2); onNextSlideClick(); }}
              ></div>
              <div
                className="ellipse-circle"
                onClick={() => { swiperRef.current.slideTo(3); onNextSlideClick(); }}
              ></div>
              <div
                className="ellipse-circle-final"
                onClick={() => { swiperRef.current.slideTo(4); onNextSlideClick(); }}
              ></div>
            </div>
            <Button
              className="tutorial-next-btn"
              variant="contained"
              onClick={() => { swiperRef.current.slideNext(); onNextSlideClick(); }}
            >
              {localStorage.getItem("language") == 'en' && (
                <span>Next</span>
              )}
              {localStorage.getItem("language") == 'sl' && (
                <span>ඊළඟ</span>
              )}
              {localStorage.getItem("language") == 'tm' && (
                <span>அடுத்தது</span>
              )}
            </Button>
            <span className="skip-link" onClick={() => { props.onSliderEndClick(); resetSlider(); }}>
              {localStorage.getItem("language") == 'en' && (
                <span>Skip</span>
              )}
              {localStorage.getItem("language") == 'sl' && (
                <span>මඟ හරින්න</span>
              )}
              {localStorage.getItem("language") == 'tm' && (
                <span>தவிர்க்கவும்</span>
              )}
            </span>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex-column-container">
            <div className="tutorial-content-row">
              <img className="tutorial-slide-img" src={tutorialSlideTwo} alt="" />
              {localStorage.getItem("language") == 'en' && (
                <audio className="hide-audio" ref={audioRefTwo} controls onEnded={handleEnd}>
                  <source src={voicesEnglish[1].url} type="audio/mp3" />
                  Your browser does not support the audio element.
                </audio>
              )}
              {localStorage.getItem("language") == 'sl' && (
                <audio className="hide-audio" ref={audioRefTwo} controls onEnded={handleEnd}>
                  <source src={voicesEnglish[1].url} type="audio/mp3" />
                  Your browser does not support the audio element.
                </audio>
              )}
              {localStorage.getItem("language") == 'tm' && (
                <audio className="hide-audio" ref={audioRefTwo} controls onEnded={handleEnd}>
                  <source src={voicesTamil[1].url} type="audio/mp3" />
                  Your browser does not support the audio element.
                </audio>
              )}
              <section className="tutorial-content-desc-container">
                <section className="tutorial-audio-container mb-2">
                  {!playAudio && (
                    <div className="flex-row-center">
                      <span className="info-view-text">Click to play</span>
                      <div className="audio-icon-container" onClick={() => handleAudioPlay(1)}>
                        <div className="audio-img"></div>
                      </div>
                    </div>
                  )}
                  {playAudio && (
                    <div className="tutorial-audio-contain audio-container" onClick={() => pauseAudioPlay(1)}>
                      <ul className="tutorial-audio-div audio-div">
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                      </ul>
                      <div className="audio-icon-container" onClick={() => handleAudioPlay(1)}>
                        <div className="audio-pause-img"></div>
                      </div>
                    </div>
                  )}
                </section>
                <span className="tutorial-desc">
                  {localStorage.getItem("language") == 'en' && (
                    <span>To view all available locations and navigate to any of them, simply click on the 'All Locations' button located in the left-hand corner.</span>
                  )}
                  {localStorage.getItem("language") == 'sl' && (
                    <span>පවතින සියලුම ස්ථාන බැලීමට සහ ඒවායින් ඕනෑම ස්ථානයකට සැරිසැරීමට, වම් කෙළවරේ ඇති 'සියලු ස්ථාන' බොත්තම ක්ලික් කරන්න.</span>
                  )}
                  {localStorage.getItem("language") == 'tm' && (
                    <span>கிடைக்கக்கூடிய எல்லா இடங்களையும் பார்க்கவும், அவற்றில் ஏதேனும் ஒன்றைப் பார்க்கவும், இடது மூலையில் அமைந்துள்ள 'அனைத்து இருப்பிடங்கள்' பொத்தானைக் கிளிக் செய்யவும்.</span>
                  )}
                </span>
              </section>
            </div>
            <div className="ellipse-group">
              <div
                className="ellipse-circle-active"
                onClick={() => { swiperRef.current.slideTo(0); onNextSlideClick(); }}
              ></div>
              <div
                className="ellipse-circle-active"
                onClick={() => { swiperRef.current.slideTo(1); onNextSlideClick(); }}
              ></div>
              <div
                className="ellipse-circle"
                onClick={() => { swiperRef.current.slideTo(2); onNextSlideClick(); }}
              ></div>
              <div
                className="ellipse-circle"
                onClick={() => { swiperRef.current.slideTo(3); onNextSlideClick(); }}
              ></div>
              <div
                className="ellipse-circle-final"
                onClick={() => { swiperRef.current.slideTo(4); onNextSlideClick(); }}
              ></div>
            </div>
            <Button
              className="tutorial-next-btn"
              variant="contained"
              onClick={() => { swiperRef.current.slideNext(); onNextSlideClick(); }}
            >
              {localStorage.getItem("language") == 'en' && (
                <span>Next</span>
              )}
              {localStorage.getItem("language") == 'sl' && (
                <span>ඊළඟ</span>
              )}
              {localStorage.getItem("language") == 'tm' && (
                <span>அடுத்தது</span>
              )}
            </Button>
            <span className="skip-link" onClick={() => { props.onSliderEndClick(); resetSlider(); }}>
              {localStorage.getItem("language") == 'en' && (
                <span>Skip</span>
              )}
              {localStorage.getItem("language") == 'sl' && (
                <span>මඟ හරින්න</span>
              )}
              {localStorage.getItem("language") == 'tm' && (
                <span>தவிர்க்கவும்</span>
              )}
            </span>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex-column-container">
            <div className="tutorial-content-row">
              <img
                className="tutorial-slide-img"
                src={tutorialSlideThree}
                alt=""
              />
              {localStorage.getItem("language") == 'en' && (
                <audio className="hide-audio" ref={audioRefThree} controls onEnded={handleEnd}>
                  <source src={voicesEnglish[2].url} type="audio/mp3" />
                  Your browser does not support the audio element.
                </audio>
              )}
              {localStorage.getItem("language") == 'sl' && (
                <audio className="hide-audio" ref={audioRefThree} controls onEnded={handleEnd}>
                  <source src={voicesEnglish[2].url} type="audio/mp3" />
                  Your browser does not support the audio element.
                </audio>
              )}
              {localStorage.getItem("language") == 'tm' && (
                <audio className="hide-audio" ref={audioRefThree} controls onEnded={handleEnd}>
                  <source src={voicesTamil[2].url} type="audio/mp3" />
                  Your browser does not support the audio element.
                </audio>
              )}
              <section className="tutorial-content-desc-container">
                <section className="tutorial-audio-container mb-2">
                  {!playAudio && (
                    <div className="flex-row-center">
                      <span className="info-view-text">Click to play</span>
                      <div className="audio-icon-container" onClick={() => handleAudioPlay(2)}>
                        <div className="audio-img"></div>
                      </div>
                    </div>
                  )}
                  {playAudio && (
                    <div className="tutorial-audio-contain audio-container" onClick={pauseAudioPlay}>
                      <ul className="tutorial-audio-div audio-div">
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                      </ul>
                      <div className="audio-icon-container" onClick={() => handleAudioPlay(2)}>
                        <div className="audio-pause-img"></div>
                      </div>
                    </div>
                  )}
                </section>
                <span className="tutorial-desc">
                  {localStorage.getItem("language") == 'en' && (
                    <span>To move to the next or previous location, simply use the left and right control buttons located at the bottom of the screen.</span>
                  )}
                  {localStorage.getItem("language") == 'sl' && (
                    <span>ඊළඟ හෝ පෙර ස්ථානයට යාමට, තිරයේ පහළින් පිහිටා ඇති වම් සහ දකුණු පාලන බොත්තම් භාවිතා කරන්න.</span>
                  )}
                  {localStorage.getItem("language") == 'tm' && (
                    <span>அடுத்த அல்லது முந்தைய இடத்திற்குச் செல்ல, திரையின் அடிப்பகுதியில் அமைந்துள்ள இடது மற்றும் வலது கட்டுப்பாட்டு பொத்தான்களைப் பயன்படுத்தவும்.</span>
                  )}
                </span>
              </section>
            </div>
            <div className="ellipse-group">
              <div
                className="ellipse-circle-active"
                onClick={() => { swiperRef.current.slideTo(0); onNextSlideClick(); }}
              ></div>
              <div
                className="ellipse-circle-active"
                onClick={() => { swiperRef.current.slideTo(1); onNextSlideClick(); }}
              ></div>
              <div
                className="ellipse-circle-active"
                onClick={() => { swiperRef.current.slideTo(2); onNextSlideClick(); }}
              ></div>
              <div
                className="ellipse-circle"
                onClick={() => { swiperRef.current.slideTo(3); onNextSlideClick(); }}
              ></div>
              <div
                className="ellipse-circle-final"
                onClick={() => { swiperRef.current.slideTo(4); onNextSlideClick(); }}
              ></div>
            </div>
            <Button
              className="tutorial-next-btn"
              variant="contained"
              onClick={() => { swiperRef.current.slideNext(); onNextSlideClick(); }}
            >
              {localStorage.getItem("language") == 'en' && (
                <span>Next</span>
              )}
              {localStorage.getItem("language") == 'sl' && (
                <span>ඊළඟ</span>
              )}
              {localStorage.getItem("language") == 'tm' && (
                <span>அடுத்தது</span>
              )}
            </Button>
            <span className="skip-link" onClick={() => { props.onSliderEndClick(); resetSlider(); }}>
              {localStorage.getItem("language") == 'en' && (
                <span>Skip</span>
              )}
              {localStorage.getItem("language") == 'sl' && (
                <span>මඟ හරින්න</span>
              )}
              {localStorage.getItem("language") == 'tm' && (
                <span>தவிர்க்கவும்</span>
              )}
            </span>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex-column-container">
            <div className="tutorial-content-row">
              <img
                className="tutorial-slide-img"
                src={tutorialSlideFour}
                alt=""
              />
              {localStorage.getItem("language") == 'en' && (
                <audio className="hide-audio" ref={audioRefFour} controls onEnded={handleEnd}>
                  <source src={voicesEnglish[3].url} type="audio/mp3" />
                  Your browser does not support the audio element.
                </audio>
              )}
              {localStorage.getItem("language") == 'sl' && (
                <audio className="hide-audio" ref={audioRefFour} controls onEnded={handleEnd}>
                  <source src={voicesEnglish[3].url} type="audio/mp3" />
                  Your browser does not support the audio element.
                </audio>
              )}
              {localStorage.getItem("language") == 'tm' && (
                <audio className="hide-audio" ref={audioRefFour} controls onEnded={handleEnd}>
                  <source src={voicesTamil[3].url} type="audio/mp3" />
                  Your browser does not support the audio element.
                </audio>
              )}
              <section className="tutorial-content-desc-container">
                <section className="tutorial-audio-container mb-2">
                  {!playAudio && (
                    <div className="flex-row-center">
                      <span className="info-view-text">Click to play</span>
                      <div className="audio-icon-container" onClick={() => handleAudioPlay(3)}>
                        <div className="audio-img"></div>
                      </div>
                    </div>
                  )}
                  {playAudio && (
                    <div className="tutorial-audio-contain audio-container" onClick={pauseAudioPlay}>
                      <ul className="tutorial-audio-div audio-div">
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                      </ul>
                      <div className="audio-icon-container" onClick={() => handleAudioPlay(3)}>
                        <div className="audio-pause-img"></div>
                      </div>
                    </div>
                  )}
                </section>
                <span className="tutorial-desc">
                  {localStorage.getItem("language") == 'en' && (
                    <span>To explore the 360-degree view of the location, swipe the screen left, right, up and down sides.</span>
                  )}
                  {localStorage.getItem("language") == 'sl' && (
                    <span>ස්ථානයේ අංශක 360 දර්ශනය ගවේෂණය කිරීමට, තිරය වමට, දකුණට, ඉහළට සහ පහළට ස්වයිප් කරන්න.</span>
                  )}
                  {localStorage.getItem("language") == 'tm' && (
                    <span>இருப்பிடத்தின் 360 டிகிரி காட்சியை ஆராய, திரையை இடது, வலது, மேல் மற்றும் கீழ் பக்கமாக ஸ்வைப் செய்யவும்.</span>
                  )}
                </span>
              </section>
            </div>
            <div className="ellipse-group">
              <div
                className="ellipse-circle-active"
                onClick={() => { swiperRef.current.slideTo(0); onNextSlideClick(); }}
              ></div>
              <div
                className="ellipse-circle-active"
                onClick={() => { swiperRef.current.slideTo(1); onNextSlideClick(); }}
              ></div>
              <div
                className="ellipse-circle-active"
                onClick={() => { swiperRef.current.slideTo(2); onNextSlideClick(); }}
              ></div>
              <div
                className="ellipse-circle-active"
                onClick={() => { swiperRef.current.slideTo(3); onNextSlideClick(); }}
              ></div>
              <div
                className="ellipse-circle-final"
                onClick={() => { swiperRef.current.slideTo(4); onNextSlideClick(); }}
              ></div>
            </div>
            <Button
              className="tutorial-next-btn"
              variant="contained"
              onClick={() => { swiperRef.current.slideNext(); onNextSlideClick(); }}
            >
              {localStorage.getItem("language") == 'en' && (
                <span>Next</span>
              )}
              {localStorage.getItem("language") == 'sl' && (
                <span>ඊළඟ</span>
              )}
              {localStorage.getItem("language") == 'tm' && (
                <span>அடுத்தது</span>
              )}
            </Button>
            <span className="skip-link" onClick={() => { props.onSliderEndClick(); resetSlider(); }}>
              {localStorage.getItem("language") == 'en' && (
                <span>Skip</span>
              )}
              {localStorage.getItem("language") == 'sl' && (
                <span>මඟ හරින්න</span>
              )}
              {localStorage.getItem("language") == 'tm' && (
                <span>தவிர்க்கவும்</span>
              )}
            </span>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex-column-container">
            <div className="tutorial-content-row">
              <img
                className="tutorial-slide-img"
                src={tutorialSlideFive}
                alt=""
              />
              {localStorage.getItem("language") == 'en' && (
                <audio className="hide-audio" ref={audioRefFive} controls onEnded={handleEnd}>
                  <source src={voicesEnglish[4].url} type="audio/mp3" />
                  Your browser does not support the audio element.
                </audio>
              )}
              {localStorage.getItem("language") == 'sl' && (
                <audio className="hide-audio" ref={audioRefFive} controls onEnded={handleEnd}>
                  <source src={voicesEnglish[4].url} type="audio/mp3" />
                  Your browser does not support the audio element.
                </audio>
              )}
              {localStorage.getItem("language") == 'tm' && (
                <audio className="hide-audio" ref={audioRefFive} controls onEnded={handleEnd}>
                  <source src={voicesTamil[4].url} type="audio/mp3" />
                  Your browser does not support the audio element.
                </audio>
              )}
              <section className="tutorial-content-desc-container">
                <section className="tutorial-audio-container mb-2">
                  {!playAudio && (
                    <div className="flex-row-center">
                      <span className="info-view-text">Click to play</span>
                      <div className="audio-icon-container" onClick={() => handleAudioPlay(4)}>
                        <div className="audio-img"></div>
                      </div>
                    </div>
                  )}
                  {playAudio && (
                    <div className="tutorial-audio-contain audio-container" onClick={pauseAudioPlay}>
                      <ul className="tutorial-audio-div audio-div">
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                      </ul>
                      <div className="audio-icon-container" onClick={() => handleAudioPlay(4)}>
                        <div className="audio-pause-img"></div>
                      </div>
                    </div>
                  )}
                </section>
                <span className="tutorial-desc">
                  {localStorage.getItem("language") == 'en' && (
                    <span>To navigate between different spots within the same location, simply click on the circles located at the bottom of the screen.</span>
                  )}
                  {localStorage.getItem("language") == 'sl' && (
                    <span>එකම ස්ථානය තුළ විවිධ ස්ථාන අතර සැරිසැරීමට, තිරයේ පතුලේ ඇති කව මත ක්ලික් කරන්න.</span>
                  )}
                  {localStorage.getItem("language") == 'tm' && (
                    <span>ஒரே இடத்தில் வெவ்வேறு இடங்களுக்கு இடையே செல்ல, திரையின் அடிப்பகுதியில் உள்ள வட்டங்களில் கிளிக் செய்யவும்.</span>
                  )}
                </span>
              </section>
            </div>
            <div className="ellipse-group">
              <div
                className="ellipse-circle-active"
                onClick={() => { swiperRef.current.slideTo(0); onNextSlideClick(); }}
              ></div>
              <div
                className="ellipse-circle-active"
                onClick={() => { swiperRef.current.slideTo(1); onNextSlideClick(); }}
              ></div>
              <div
                className="ellipse-circle-active"
                onClick={() => { swiperRef.current.slideTo(2); onNextSlideClick(); }}
              ></div>
              <div
                className="ellipse-circle-active"
                onClick={() => { swiperRef.current.slideTo(3); onNextSlideClick(); }}
              ></div>
              <div
                className="ellipse-circle-active-final"
                onClick={() => { swiperRef.current.slideTo(4); onNextSlideClick(); }}
              ></div>
            </div>
            <Button
              className="tutorial-next-btn"
              variant="contained" onClick={() => { props.onSliderEndClick(); resetSlider(); }}
            >
              {localStorage.getItem("language") == 'en' && (
                <span >Done</span>
              )}
              {localStorage.getItem("language") == 'sl' && (
                <span>ඉවරයි</span>
              )}
              {localStorage.getItem("language") == 'tm' && (
                <span>முடிந்தது</span>
              )}
            </Button>
          </div>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default TutorialSlider;
