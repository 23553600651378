import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const AllLocations = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [copperGateSeen, setCopperGateSeen] = React.useState(false);
  const [mppEntranceSeen, setMppEntranceSeen] = React.useState(false);
  const [publicEntranceSeen, setPublicEntranceSeen] = React.useState(false);
  const [exhibitsAreaSeen, setExhibitsAreaSeen] = React.useState(false);
  const [silverDoorSeen, setSilverDoorSeen] = React.useState(false);
  const [chambersSeen, setChambersSeen] = React.useState(false);
  const [portraitCollectionSeen, setPortraitCollectionSeen] = React.useState(false);
  const [portraitCollectionSecondSeen, setPortraitCollectionSecondSeen] = React.useState(false);
  const [committeeRoomsSeen, setCommitteeRoomsSeen] = React.useState(false);
  const [parliamentLibrarySeen, setParliamentLibrarySeen] = React.useState(false);
  const [showLocations, setShowLocations] = React.useState(false);

  const locationsArrayEnglish = [
    {
      id: 1,
      location: "Copper Door",
      image: 'https://res.cloudinary.com/ditejyeth/image/upload/v1702904730/copper_gate_comp_ipkyki.png',
      link: "/copper-gate",
      seen: copperGateSeen,
    },
    {
      id: 2,
      location: "MPP Entrance",
      image: 'https://res.cloudinary.com/ditejyeth/image/upload/v1704703525/mpp_entrance_u95ipd.jpg',
      link: "/minister-entrance",
      seen: mppEntranceSeen,
    },
    {
      id: 3,
      location: "Public Entrance",
      image: 'https://res.cloudinary.com/ditejyeth/image/upload/v1702904729/public_comp_rnows3.png',
      link: "/public-entrance",
      seen: publicEntranceSeen,
    },
    {
      id: 4,
      location: "Silver Door",
      image: 'https://res.cloudinary.com/ditejyeth/image/upload/v1702904730/silver_gate_comp_tbxijw.png',
      link: "/silver-door",
      seen: silverDoorSeen,
    },
    {
      id: 5,
      location: "Chambers",
      image: 'https://res.cloudinary.com/ditejyeth/image/upload/v1702904730/chamber_comp_zfukxu.png',
      link: "/chamber",
      seen: chambersSeen,
    },
    {
      id: 6,
      location: "Portrait Gallery (First Floor)",
      image: 'https://res.cloudinary.com/ditejyeth/image/upload/v1704703653/Screenshot_2024-01-08_141640_t8vch7.jpg',
      link: "/portrait-collection",
      seen: portraitCollectionSeen,
    },
    {
      id: 7,
      location: "Portrait Gallery (Second Floor)",
      image: 'https://res.cloudinary.com/ditejyeth/image/upload/v1702905072/Screenshot_2023-12-18_184054_becufs.png',
      link: "/portrait-collection-second-floor",
      seen: portraitCollectionSecondSeen,
    },
    {
      id: 8,
      location: "Committee Rooms",
      image: 'https://res.cloudinary.com/ditejyeth/image/upload/v1702904968/Screenshot_2023-12-18_183906_wwmn7l.png',
      link: "/committee-rooms",
      seen: committeeRoomsSeen,
    },
    {
      id: 9,
      location: "Exhibits Area",
      image: 'https://res.cloudinary.com/ditejyeth/image/upload/v1702904730/exhibit_comp_xhf6rz.png',
      link: "/exhibits-area",
      seen: exhibitsAreaSeen,
    },
    {
      id: 10,
      location: "Parliament Library",
      image: 'https://res.cloudinary.com/ditejyeth/image/upload/v1704703785/Screenshot_2024-01-08_141900_swrosj.jpg',
      link: "/parliament-library",
      seen: parliamentLibrarySeen,
    },

  ];

  const locationsArraySinhala = [
    {
      id: 1,
      location: "තඹ දොරටුව",
      image: 'https://res.cloudinary.com/ditejyeth/image/upload/v1702904730/copper_gate_comp_ipkyki.png',
      link: "/copper-gate",
      seen: copperGateSeen,
    },
    {
      id: 2,
      location: "මන්ත්‍රී පිවිසුම",
      image: 'https://res.cloudinary.com/ditejyeth/image/upload/v1704703525/mpp_entrance_u95ipd.jpg',
      link: "/minister-entrance",
      seen: mppEntranceSeen,
    },
    {
      id: 3,
      location: "කාර්ය මණ්ඩල පිවිසුම",
      image: 'https://res.cloudinary.com/ditejyeth/image/upload/v1702904729/public_comp_rnows3.png',
      link: "/public-entrance",
      seen: publicEntranceSeen,
    },
    {
      id: 4,
      location: "රිදී දොර",
      image: 'https://res.cloudinary.com/ditejyeth/image/upload/v1702904730/silver_gate_comp_tbxijw.png',
      link: "/silver-door",
      seen: silverDoorSeen,
    },
    {
      id: 5,
      location: "සභා ගර්භ‌ය",
      image: 'https://res.cloudinary.com/ditejyeth/image/upload/v1702904730/chamber_comp_zfukxu.png',
      link: "/chamber",
      seen: chambersSeen,
    },
    {
      id: 6,
      location: "ඡායරූප ගැලරිය (පළමු මහල)",
      image: 'https://res.cloudinary.com/ditejyeth/image/upload/v1704703653/Screenshot_2024-01-08_141640_t8vch7.jpg',
      link: "/portrait-collection",
      seen: portraitCollectionSeen,
    },
    {
      id: 7,
      location: "ඡායරූප ගැලරිය (දෙවන මහල)",
      image: 'https://res.cloudinary.com/ditejyeth/image/upload/v1702905072/Screenshot_2023-12-18_184054_becufs.png',
      link: "/portrait-collection-second-floor",
      seen: portraitCollectionSecondSeen,
    },
    {
      id: 8,
      location: "කාරක සභා කාමර",
      image: 'https://res.cloudinary.com/ditejyeth/image/upload/v1702904968/Screenshot_2023-12-18_183906_wwmn7l.png',
      link: "/committee-rooms",
      seen: committeeRoomsSeen,
    },
    {
      id: 9,
      location: "සමරු භාණ්ඩ එකතුව",
      image: 'https://res.cloudinary.com/ditejyeth/image/upload/v1702904730/exhibit_comp_xhf6rz.png',
      link: "/exhibits-area",
      seen: exhibitsAreaSeen,
    },
    {
      id: 10,
      location: "පාර්ලිමේන්තු පුස්තකාලය",
      image: 'https://res.cloudinary.com/ditejyeth/image/upload/v1704703785/Screenshot_2024-01-08_141900_swrosj.jpg',
      link: "/parliament-library",
      seen: parliamentLibrarySeen,
    },
  ];

  const locationsArrayTamil = [
    {
      id: 1,
      location: "மெப்புக் கதவ",
      image: 'https://res.cloudinary.com/ditejyeth/image/upload/v1702904730/copper_gate_comp_ipkyki.png',
      link: "/copper-gate",
      seen: copperGateSeen,
    },
    {
      id: 2,
      location: "MPP நுழைவு",
      image: 'https://res.cloudinary.com/ditejyeth/image/upload/v1704703525/mpp_entrance_u95ipd.jpg',
      link: "/minister-entrance",
      seen: mppEntranceSeen,
    },
    {
      id: 3,
      location: "பணியாளர் நுழைவாயில்",
      image: 'https://res.cloudinary.com/ditejyeth/image/upload/v1702904729/public_comp_rnows3.png',
      link: "/public-entrance",
      seen: publicEntranceSeen,
    },
    {
      id: 4,
      location: "மவள்ளிக் கதவு",
      image: 'https://res.cloudinary.com/ditejyeth/image/upload/v1702904730/silver_gate_comp_tbxijw.png',
      link: "/silver-door",
      seen: silverDoorSeen,
    },
    {
      id: 5,
      location: "சபாபீடம",
      image: 'https://res.cloudinary.com/ditejyeth/image/upload/v1702904730/chamber_comp_zfukxu.png',
      link: "/chamber",
      seen: chambersSeen,
    },
    {
      id: 6,
      location: "புககப்பட மதாகுப்ப (முதல் தளம்)",
      image: 'https://res.cloudinary.com/ditejyeth/image/upload/v1704703653/Screenshot_2024-01-08_141640_t8vch7.jpg',
      link: "/portrait-collection",
      seen: portraitCollectionSeen,
    },
    {
      id: 7,
      location: "புககப்பட மதாகுப்பு (இரண்டாம் தளம்)",
      image: 'https://res.cloudinary.com/ditejyeth/image/upload/v1702905072/Screenshot_2023-12-18_184054_becufs.png',
      link: "/portrait-collection-second-floor",
      seen: portraitCollectionSecondSeen,
    },
    {
      id: 8,
      location: "குழு அறைகள்",
      image: 'https://res.cloudinary.com/ditejyeth/image/upload/v1702904968/Screenshot_2023-12-18_183906_wwmn7l.png',
      link: "/committee-rooms",
      seen: committeeRoomsSeen,
    },
    {
      id: 9,
      location: "நினைவுப் பொருட்கள் சேகரிப்பு",
      image: 'https://res.cloudinary.com/ditejyeth/image/upload/v1702904730/exhibit_comp_xhf6rz.png',
      link: "/exhibits-area",
      seen: exhibitsAreaSeen,
    },
    {
      id: 10,
      location: "பாராளுென்ற நூைகம்",
      image: 'https://res.cloudinary.com/ditejyeth/image/upload/v1704703785/Screenshot_2024-01-08_141900_swrosj.jpg',
      link: "/parliament-library",
      seen: parliamentLibrarySeen,
    }
  ];

  useEffect(() => {
    const seenCopperGate = localStorage.getItem("copperGate");
    const mppEntrance = localStorage.getItem("mppEntrance");
    const seenPublicEntrance = localStorage.getItem("publicEntrance");
    const seenExhibitsArea = localStorage.getItem("exhibitsArea");
    const seenSilverDoor = localStorage.getItem("silverDoor");
    const seenChambers = localStorage.getItem("chambers");
    const seenPortraitCollection = localStorage.getItem("portraitCollection");
    const seenPortraitCollectionSecond = localStorage.getItem("portraitCollectionSecond");
    const seenCommitteeRooms = localStorage.getItem("committeeRooms");
    const seenParliamentLibrary = localStorage.getItem("parliamentLibrary");

    if (seenCopperGate === "1") {
      setCopperGateSeen(true);
    }
    if (mppEntrance === "1") {
      setMppEntranceSeen(true);
    }
    if (seenPublicEntrance === "1") {
      setPublicEntranceSeen(true);
    }
    if (seenExhibitsArea === "1") {
      setExhibitsAreaSeen(true);
    }
    if (seenSilverDoor === "1") {
      setSilverDoorSeen(true);
    }
    if (seenChambers === "1") {
      setChambersSeen(true);
    }
    if (seenPortraitCollection === "1") {
      setPortraitCollectionSeen(true);
    }
    if (seenPortraitCollectionSecond === "1") {
      setPortraitCollectionSecondSeen(true);
    }
    if (seenCommitteeRooms === "1") {
      setCommitteeRoomsSeen(true);
    }
    if (seenParliamentLibrary === "1") {
      setParliamentLibrarySeen(true);
    }
    setShowLocations(true);
  }, []);

  const navigateToLocation = (link) => {
    if (link === location.pathname) {
      window.location.reload();
    } else {
      navigate(link);
    }
  };

  return (
    <section>
      <div className="dialog-header-row">
        <span className="dialog-header">
          {localStorage.getItem("language") == 'en' && (
            <span>All Locations</span>
          )}
          {localStorage.getItem("language") == 'sl' && (
            <span>සියලුම ස්ථාන</span>
          )}
          {localStorage.getItem("language") == 'tm' && (
            <span>அனைத்து இடங்களும்</span>
          )}
        </span>
      </div>
      {localStorage.getItem("language") == 'en' && (
        <section>
          {showLocations && (<div className="location-grid">
            {locationsArrayEnglish.map((el) => (
              <div
                key={el.id}
                className="location-card"
                onClick={() => navigateToLocation(el.link)}
              >
                <div className="location-details">
                  <span className="location-detail-name">{el.location}</span>
                  {el.seen && <CheckCircleIcon className="location-tick" />}
                </div>
                <img className="location-dialog-img" src={el.image} alt="" />
              </div>
            ))}
          </div>)}
        </section>
      )}
      {localStorage.getItem("language") == 'sl' && (
        <section>
          {showLocations && (<div className="location-grid">
            {locationsArraySinhala.map((el) => (
              <div
                key={el.id}
                className="location-card"
                onClick={() => navigateToLocation(el.link)}
              >
                <div className="location-details">
                  <span className="location-detail-name">{el.location}</span>
                  {el.seen && <CheckCircleIcon className="location-tick" />}
                </div>
                <img className="location-dialog-img" src={el.image} alt="" />
              </div>
            ))}
          </div>)}
        </section>
      )}
      {localStorage.getItem("language") == 'tm' && (
        <section>
          {showLocations && (<div className="location-grid">
            {locationsArrayTamil.map((el) => (
              <div
                key={el.id}
                className="location-card"
                onClick={() => navigateToLocation(el.link)}
              >
                <div className="location-details">
                  <span className="location-detail-name">{el.location}</span>
                  {el.seen && <CheckCircleIcon className="location-tick" />}
                </div>
                <img className="location-dialog-img" src={el.image} alt="" />
              </div>
            ))}
          </div>)}
        </section>
      )}


    </section>
  );
};

export default AllLocations;
