import React, { useEffect } from "react";
import axios from "axios";

const Invisible = (props) => {

  useEffect(() => {
    getColors();
  }, []);

  const getColors = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API + "Colors/getColors");
      document.documentElement.style.setProperty(
        "--primary-color",
        response.data[0].primaryColor
      );
      document.documentElement.style.setProperty(
        "--secondary-color",
        response.data[0].secondaryColor
      );
    } catch (error) {
      console.error(error);
      console.log("There was an error!", error);
    }
  };

  return (
    <section>
    </section>
  );
};

export default Invisible;
