import React from "react";

const Loading = (props) => {

  return (
    <div className="loading-container">
      {/* <div className="loader">
        <div className="loaderBar"></div>
      </div>
      <span className="loading-text">LOADING</span> */}
    </div>
  );
};

export default Loading;
