import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";

import axios from "axios";

const Video = () => {
  const navigate = useNavigate();

  const navigateToLanding = () => navigate("/copper-gate");

  const [playVideo, setPlayVideo] = React.useState(false);

  useEffect(() => {
    getColors();
  }, []);

  const videoReady = () => {
    setPlayVideo(true);
  };

  const getColors = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API + "Colors/getColors");
      document.documentElement.style.setProperty(
        "--primary-color",
        response.data[0].primaryColor
      );
      document.documentElement.style.setProperty(
        "--secondary-color",
        response.data[0].secondaryColor
      );
    } catch (error) {
      console.error(error);
      console.log("There was an error!", error);
    }
  };

  return (
    <div className="video-page-container mob-center-align">
      {!playVideo && (<div className="loading-black-bg"></div>)}
      <div className="video-bg">
        <ReactPlayer
          url="https://res.cloudinary.com/ditejyeth/video/upload/v1694444640/intro_video-low_iybgx8.mp4"
          className="video-container-video"
          loop={true}
          playing={playVideo}
          muted={true}
          controls={false}
          playsinline={true}
          onReady={() => videoReady()}
        />
        <div id="startTourBtnContainer" className="start-btn-container hide-start-btn-container">
          <Button
            className="start-tour-btn"
            variant="contained"
            onClick={navigateToLanding}
          >
            START TOUR
          </Button>
        </div>

      </div>
    </div>
  );
};

export default Video;
