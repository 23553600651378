import React, { useState, useEffect, createRef } from "react";
import { Pannellum } from "pannellum-react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MenuIcon from "@mui/icons-material/Menu";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CancelIcon from "@mui/icons-material/Cancel";
import Fullscreen from "fullscreen-react";
import Slide from "@mui/material/Slide";
import { useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import projectorIcon from "../../images/icons/projector.svg";
import AllLocations from "../../components/AllLocations";
import FooterIconBar from "../../components/FooterIconBar";
import TutorialSlider from "../../components/TutorialSlider";
import Loading from "../../components/Loading";

import axios from "axios";
import Invisible from "../../components/Invisible";
import Skeleton from '@mui/material/Skeleton';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Chambers = () => {

  const panImage = React.createRef();
  window.addEventListener("wheel", event => {

    if (window.innerWidth < 481) {
      if (imageIndex == 2) {
        if (panImage.current != null) {
          let hfovDe = panImage.current.getViewer().getHfov();
          if (hfovDe > 75) {
            var xOne = document.getElementsByClassName('row-two-p-hidden');
            for (var i = 0; i < xOne.length; i++) {
              xOne[i].classList.remove('row-two-p-visible');
            }
            var xTwo = document.getElementsByClassName('row-three-p-hidden');
            for (var i = 0; i < xTwo.length; i++) {
              xTwo[i].classList.remove('row-three-p-visible');
            }
            var xThree = document.getElementsByClassName('row-four-p-hidden');
            for (var i = 0; i < xThree.length; i++) {
              xThree[i].classList.remove('row-four-p-visible');
            }
            var xFour = document.getElementsByClassName('row-five-p-hidden');
            for (var i = 0; i < xFour.length; i++) {
              xFour[i].classList.remove('row-five-p-visible');
            }
          } else if (hfovDe > 60 && hfovDe < 75) {
            var xOne = document.getElementsByClassName('row-two-p-hidden');
            for (var i = 0; i < xOne.length; i++) {
              xOne[i].classList.add('row-two-p-visible');
            }
            var xTwo = document.getElementsByClassName('row-three-p-hidden');
            for (var i = 0; i < xTwo.length; i++) {
              xTwo[i].classList.add('row-three-p-visible');
            }
            var xThree = document.getElementsByClassName('row-four-p-hidden');
            for (var i = 0; i < xThree.length; i++) {
              xThree[i].classList.remove('row-four-p-visible');
            }
            var xFour = document.getElementsByClassName('row-five-p-hidden');
            for (var i = 0; i < xFour.length; i++) {
              xFour[i].classList.remove('row-five-p-visible');
            }
          } else if (hfovDe > 48 && hfovDe < 60) {
            var xOne = document.getElementsByClassName('row-four-p-hidden');
            for (var i = 0; i < xOne.length; i++) {
              xOne[i].classList.add('row-four-p-visible');
            }
            var xTwo = document.getElementsByClassName('row-five-p-hidden');
            for (var i = 0; i < xTwo.length; i++) {
              xTwo[i].classList.add('row-five-p-visible');
            }
          }
          console.log(hfovDe, 'hfovDe')
        }
      }
    } else {
      if (imageIndex == 2) {
        if (panImage.current != null) {
          let hfovDe = panImage.current.getViewer().getHfov();
          if (hfovDe > 101) {
            var xOne = document.getElementsByClassName('row-two-p-hidden');
            for (var i = 0; i < xOne.length; i++) {
              xOne[i].classList.remove('row-two-p-visible');
            }
            var xTwo = document.getElementsByClassName('row-three-p-hidden');
            for (var i = 0; i < xTwo.length; i++) {
              xTwo[i].classList.remove('row-three-p-visible');
            }
            var xThree = document.getElementsByClassName('row-four-p-hidden');
            for (var i = 0; i < xThree.length; i++) {
              xThree[i].classList.remove('row-four-p-visible');
            }
            var xFour = document.getElementsByClassName('row-five-p-hidden');
            for (var i = 0; i < xFour.length; i++) {
              xFour[i].classList.remove('row-five-p-visible');
            }
          } else if (hfovDe > 80 && hfovDe < 100) {
            var xOne = document.getElementsByClassName('row-two-p-hidden');
            for (var i = 0; i < xOne.length; i++) {
              xOne[i].classList.add('row-two-p-visible');
            }
            var xTwo = document.getElementsByClassName('row-three-p-hidden');
            for (var i = 0; i < xTwo.length; i++) {
              xTwo[i].classList.add('row-three-p-visible');
            }
            var xThree = document.getElementsByClassName('row-four-p-hidden');
            for (var i = 0; i < xThree.length; i++) {
              xThree[i].classList.remove('row-four-p-visible');
            }
            var xFour = document.getElementsByClassName('row-five-p-hidden');
            for (var i = 0; i < xFour.length; i++) {
              xFour[i].classList.remove('row-five-p-visible');
            }
          } else if (hfovDe > 40 && hfovDe < 79) {
            var xOne = document.getElementsByClassName('row-four-p-hidden');
            for (var i = 0; i < xOne.length; i++) {
              xOne[i].classList.add('row-four-p-visible');
            }
            var xTwo = document.getElementsByClassName('row-five-p-hidden');
            for (var i = 0; i < xTwo.length; i++) {
              xTwo[i].classList.add('row-five-p-visible');
            }
          }
          console.log(hfovDe, 'hfovDe')
        }
      }
    }
    // console.log(event.offsetX, event.offsetY)
  });


  const [isLoading, setIsLoading] = useState(false);
  const [isShowFooter, setIsShowFooter] = useState(false);

  const [locationDetails, setLocationDetails] = React.useState([]);
  const [locationName, setLocationName] = React.useState("");

  const [imageOneSrc, setImageOneSrc] = React.useState("");
  const [imageTwoSrc, setImageTwoSrc] = React.useState("");
  const [imageThreeSrc, setImageThreeSrc] = React.useState("");

  const [hotspotText, setHostpotDescription] = React.useState("");
  const [hotspotArray, setHotspotArray] = useState([]);
  const [hotspotImage, setHotspotImage] = useState("");
  const [fullImageOpen, setFullImageOpen] = React.useState(false);
  const [audio, setAudio] = useState(new Audio(null));
  const [playAudio, setPlayAudio] = React.useState(false);
  const [hotspotOpen, setHotspotOpen] = React.useState(false);
  const [showAudio, setShowAudio] = React.useState(false);
  const [showIframe, setShowIframe] = React.useState(false);
  const [iframeUrl, setIframeUrl] = useState("");
  const [showImageLoading, setShowImageLoading] = React.useState(false);
  const [hotspotTitle, setHostpotTitle] = React.useState("");
  const [presentationOpen, setPresentationOpen] = React.useState(false);
  const [showSnackBar, setShowSnackBar] = React.useState(false);
  const [variable, setVariable] = useState(null);

  const getLocationDetails = async () => {
    setIsShowFooter(false);
    setIsLoading(true);
    try {
      const response = await axios.get(process.env.REACT_APP_API + 'Locations/locationDetails/getDetailsByLocation/chambers');
      setLocationDetails(response.data);
      switch (localStorage.getItem("language")) {
        case 'en':
          setLocationName(response.data[0].nameInEnglish);
          break;
        case 'sl':
          setLocationName(response.data[0].nameInSinhala);
          break;
        case 'tm':
          setLocationName(response.data[0].nameInTamil);
          break;
        default:
          setLocationName(response.data[0].nameInEnglish);
          break;
      }
      setImageOneSrc(response.data[0].locationImage);
      setImageTwoSrc(response.data[1].locationImage);
      setImageThreeSrc(response.data[2].locationImage);
      setImageIndex(1);
      if (response.data[0].hotspotArray) {
        setHotspotArray(JSON.parse(response.data[0].hotspotArray));
        if (JSON.parse(response.data[0].hotspotArray)[0]) {
          if (JSON.parse(response.data[0].hotspotArray)[0].disable) {
            var hotspotsOne = document.getElementsByClassName('loc-hotspot');
            for (var i = 0; i < hotspotsOne.length; i++) {
              hotspotsOne[i].style.visibility = 'hidden';
              hotspotsOne[i].style.display = 'none';
            }
          } else {
            let hotspotString = '';
            switch (localStorage.getItem("language")) {
              case 'en':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[0].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeft", '-52px'
                );
                break;
              case 'sl':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[0].hotspotTitleSinhala + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeft", '-40px'
                );
                break;
              case 'tm':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[0].hotspotTitleTamil + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeft", '-72px'
                );
                break;
              default:
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[0].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeft", '-52px'
                );
                break;
            }
            document.documentElement.style.setProperty(
              "--hotspotText", hotspotString
            );
          }
        }
        if (JSON.parse(response.data[0].hotspotArray)[1]) {
          if (JSON.parse(response.data[0].hotspotArray)[1].disable) {
            var hotspotsTwo = document.getElementsByClassName('loc-hotspot-two');
            for (var i = 0; i < hotspotsTwo.length; i++) {
              hotspotsTwo[i].style.visibility = 'hidden';
              hotspotsTwo[i].style.display = 'none';
            }
          } else {
            let hotspotString = '';
            switch (localStorage.getItem("language")) {
              case 'en':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[1].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftTwo", '-58px'
                );
                break;
              case 'sl':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[1].hotspotTitleSinhala + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftTwo", '-28px'
                );
                break;
              case 'tm':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[1].hotspotTitleTamil + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftTwo", '-40px'
                );
                break;
              default:
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[1].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftTwo", '-58px'
                );
                break;
            }
            document.documentElement.style.setProperty(
              "--hotspotTextTwo", hotspotString
            );
          }
        }
        if (JSON.parse(response.data[0].hotspotArray)[2]) {
          if (JSON.parse(response.data[0].hotspotArray)[2].disable) {
            var hotspotsThree = document.getElementsByClassName('loc-hotspot-three');
            for (var i = 0; i < hotspotsThree.length; i++) {
              hotspotsThree[i].style.visibility = 'hidden';
              hotspotsThree[i].style.display = 'none';
            }
          } else {
            let hotspotString = '';
            switch (localStorage.getItem("language")) {
              case 'en':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[2].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftThree", '-80px'
                );
                break;
              case 'sl':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[2].hotspotTitleSinhala + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftThree", '-114px'
                );
                break;
              case 'tm':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[2].hotspotTitleTamil + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftThree", '-160px'
                );
                break;
              default:
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[2].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftThree", '-80px'
                );
                break;
            }
            document.documentElement.style.setProperty(
              "--hotspotTextThree", hotspotString
            );
          }
        }
        if (JSON.parse(response.data[0].hotspotArray)[3]) {
          if (JSON.parse(response.data[0].hotspotArray)[3].disable) {
            var hotspotsFour = document.getElementsByClassName('loc-hotspot-four');
            for (var i = 0; i < hotspotsFour.length; i++) {
              hotspotsFour[i].style.visibility = 'hidden';
              hotspotsFour[i].style.display = 'none';
            }
          } else {
            let hotspotString = '';
            switch (localStorage.getItem("language")) {
              case 'en':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[3].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftFour", '-126px'
                );
                break;
              case 'sl':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[3].hotspotTitleSinhala + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftFour", '-64px'
                );
                break;
              case 'tm':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[3].hotspotTitleTamil + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftFour", '-160px'
                );
                break;
              default:
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[3].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftFour", '-126px'
                );
                break;
            }
            document.documentElement.style.setProperty(
              "--hotspotTextFour", hotspotString
            );
          }
        }
        if (JSON.parse(response.data[0].hotspotArray)[4]) {
          if (JSON.parse(response.data[0].hotspotArray)[4].disable) {
            var hotspotsFive = document.getElementsByClassName('loc-hotspot-five');
            for (var i = 0; i < hotspotsFive.length; i++) {
              hotspotsFive[i].style.visibility = 'hidden';
              hotspotsFive[i].style.display = 'none';
            }
          } else {
            let hotspotString = '';
            switch (localStorage.getItem("language")) {
              case 'en':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[4].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftFive", '-100px'
                );
                break;
              case 'sl':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[4].hotspotTitleSinhala + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftFive", '-62px'
                );
                break;
              case 'tm':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[4].hotspotTitleTamil + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftFive", '-160px'
                );
                break;
              default:
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[4].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftFive", '-100px'
                );
                break;
            }
            document.documentElement.style.setProperty(
              "--hotspotTextFive", hotspotString
            );
          }
        }
        if (JSON.parse(response.data[0].hotspotArray)[5]) {
          if (JSON.parse(response.data[0].hotspotArray)[5].disable) {
            var hotspotsSix = document.getElementsByClassName('loc-hotspot-six');
            for (var i = 0; i < hotspotsSix.length; i++) {
              hotspotsSix[i].style.visibility = 'hidden';
              hotspotsSix[i].style.display = 'none';
            }
          } else {
            let hotspotString = '';
            switch (localStorage.getItem("language")) {
              case 'en':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[5].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftSix", '-132px'
                );
                break;
              case 'sl':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[5].hotspotTitleSinhala + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftSix", '-88px'
                );
                break;
              case 'tm':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[5].hotspotTitleTamil + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftSix", '-174px'
                );
                break;
              default:
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[5].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftSix", '-132px'
                );
                break;
            }
            document.documentElement.style.setProperty(
              "--hotspotTextSix", hotspotString
            );
          }
        }
        if (JSON.parse(response.data[0].hotspotArray)[6]) {
          if (JSON.parse(response.data[0].hotspotArray)[6].disable) {
            var hotspotsSeven = document.getElementsByClassName('loc-hotspot-seven');
            for (var i = 0; i < hotspotsSeven.length; i++) {
              hotspotsSeven[i].style.visibility = 'hidden';
              hotspotsSeven[i].style.display = 'none';
            }
          } else {
            let hotspotString = '';
            switch (localStorage.getItem("language")) {
              case 'en':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[6].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftSeven", '-110px'
                );
                break;
              case 'sl':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[6].hotspotTitleSinhala + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftSeven", '-62px'
                );
                break;
              case 'tm':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[6].hotspotTitleTamil + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftSeven", '-110px'
                );
                break;
              default:
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[6].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftSeven", '-110px'
                );
                break;
            }
            document.documentElement.style.setProperty(
              "--hotspotTextSeven", hotspotString
            );
          }
        }
        if (JSON.parse(response.data[0].hotspotArray)[7]) {
          if (JSON.parse(response.data[0].hotspotArray)[7].disable) {
            var hotspotsEight = document.getElementsByClassName('loc-hotspot-eight');
            for (var i = 0; i < hotspotsEight.length; i++) {
              hotspotsEight[i].style.visibility = 'hidden';
              hotspotsEight[i].style.display = 'none';
            }
          } else {
            let hotspotString = '';
            switch (localStorage.getItem("language")) {
              case 'en':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[7].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftEight", '-90px'
                );
                break;
              case 'sl':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[7].hotspotTitleSinhala + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftEight", '-64px'
                );
                break;
              case 'tm':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[7].hotspotTitleTamil + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftEight", '-90px'
                );
                break;
              default:
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[7].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftEight", '-90px'
                );
                break;
            }
            document.documentElement.style.setProperty(
              "--hotspotTextEight", hotspotString
            );
          }
        }
        if (JSON.parse(response.data[0].hotspotArray)[8]) {
          if (JSON.parse(response.data[0].hotspotArray)[8].disable) {
            var hotspotsNine = document.getElementsByClassName('loc-hotspot-nine');
            for (var i = 0; i < hotspotsNine.length; i++) {
              hotspotsNine[i].style.visibility = 'hidden';
              hotspotsNine[i].style.display = 'none';
            }
          } else {
            let hotspotString = '';
            switch (localStorage.getItem("language")) {
              case 'en':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[8].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftNine", '-56px'
                );
                break;
              case 'sl':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[8].hotspotTitleSinhala + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftNine", '-36px'
                );
                break;
              case 'tm':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[8].hotspotTitleTamil + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftNine", '-60px'
                );
                break;
              default:
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[8].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftNine", '-56px'
                );
                break;
            }
            document.documentElement.style.setProperty(
              "--hotspotTextNine", hotspotString
            );
          }
        }
        if (JSON.parse(response.data[0].hotspotArray)[9]) {
          if (JSON.parse(response.data[0].hotspotArray)[9].disable) {
            var hotspotsTen = document.getElementsByClassName('loc-hotspot-ten');
            for (var i = 0; i < hotspotsTen.length; i++) {
              hotspotsTen[i].style.visibility = 'hidden';
              hotspotsTen[i].style.display = 'none';
            }
          } else {
            let hotspotString = '';
            switch (localStorage.getItem("language")) {
              case 'en':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[9].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftTen", '-50px'
                );
                break;
              case 'sl':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[9].hotspotTitleSinhala + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftTen", '-42px'
                );
                break;
              case 'tm':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[9].hotspotTitleTamil + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftTen", '-54px'
                );
                break;
              default:
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[9].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftTen", '-50px'
                );
                break;
            }
            document.documentElement.style.setProperty(
              "--hotspotTextTen", hotspotString
            );
          }
        }
        if (JSON.parse(response.data[0].hotspotArray)[10]) {
          if (JSON.parse(response.data[0].hotspotArray)[10].disable) {
            var hotspotsEleven = document.getElementsByClassName('loc-hotspot-eleven');
            for (var i = 0; i < hotspotsEleven.length; i++) {
              hotspotsEleven[i].style.visibility = 'hidden';
              hotspotsEleven[i].style.display = 'none';
            }
          } else {
            let hotspotString = '';
            switch (localStorage.getItem("language")) {
              case 'en':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[10].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftEleven", '-200px'
                );
                break;
              case 'sl':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[10].hotspotTitleSinhala + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftEleven", '-160px'
                );
                break;
              case 'tm':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[10].hotspotTitleTamil + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftEleven", '-240px'
                );
                break;
              default:
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[10].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftEleven", '-200px'
                );
                break;
            }
            document.documentElement.style.setProperty(
              "--hotspotTextEleven", hotspotString
            );
          }
        }
        if (JSON.parse(response.data[0].hotspotArray)[11]) {
          if (JSON.parse(response.data[0].hotspotArray)[11].disable) {
            var hotspotsTwelve = document.getElementsByClassName('loc-hotspot-twelve');
            for (var i = 0; i < hotspotsTwelve.length; i++) {
              hotspotsTwelve[i].style.visibility = 'hidden';
              hotspotsTwelve[i].style.display = 'none';
            }
          } else {
            let hotspotString = '';
            switch (localStorage.getItem("language")) {
              case 'en':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[11].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftTwelve", '-90px'
                );
                break;
              case 'sl':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[11].hotspotTitleSinhala + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftTwelve", '-64px'
                );
                break;
              case 'tm':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[11].hotspotTitleTamil + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftTwelve", '-124px'
                );
                break;
              default:
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[11].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftTwelve", '-90px'
                );
                break;
            }
            document.documentElement.style.setProperty(
              "--hotspotTextTwelve", hotspotString
            );
          }
        }
        if (JSON.parse(response.data[0].hotspotArray)[12]) {
          if (JSON.parse(response.data[0].hotspotArray)[12].disable) {
            var hotspotsThirteen = document.getElementsByClassName('loc-hotspot-thirteen');
            for (var i = 0; i < hotspotsThirteen.length; i++) {
              hotspotsThirteen[i].style.visibility = 'hidden';
              hotspotsThirteen[i].style.display = 'none';
            }
          } else {
            let hotspotString = '';
            switch (localStorage.getItem("language")) {
              case 'en':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[12].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftThirteen", '-182px'
                );
                break;
              case 'sl':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[12].hotspotTitleSinhala + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftThirteen", '-200px'
                );
                break;
              case 'tm':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[12].hotspotTitleTamil + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftThirteen", '-290px'
                );
                break;
              default:
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[12].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftThirteen", '-182px'
                );
                break;
            }
            document.documentElement.style.setProperty(
              "--hotspotTextThirteen", hotspotString
            );
          }
        }
        if (JSON.parse(response.data[0].hotspotArray)[13]) {
          if (JSON.parse(response.data[0].hotspotArray)[13].disable) {
            var hotspotsFourteen = document.getElementsByClassName('loc-hotspot-fourteen');
            for (var i = 0; i < hotspotsFourteen.length; i++) {
              hotspotsFourteen[i].style.visibility = 'hidden';
              hotspotsFourteen[i].style.display = 'none';
            }
          } else {
            let hotspotString = '';
            switch (localStorage.getItem("language")) {
              case 'en':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[13].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftFourteen", '-190px'
                );
                break;
              case 'sl':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[13].hotspotTitleSinhala + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftFourteen", '-100px'
                );
                break;
              case 'tm':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[13].hotspotTitleTamil + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftFourteen", '-128px'
                );
                break;
              default:
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[13].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftFourteen", '-190px'
                );
                break;
            }
            document.documentElement.style.setProperty(
              "--hotspotTextFourteen", hotspotString
            );
          }
        }
        if (JSON.parse(response.data[0].hotspotArray)[14]) {
          if (JSON.parse(response.data[0].hotspotArray)[14].disable) {
            var hotspotsFifteen = document.getElementsByClassName('loc-hotspot-fifteen');
            for (var i = 0; i < hotspotsFifteen.length; i++) {
              hotspotsFifteen[i].style.visibility = 'hidden';
              hotspotsFifteen[i].style.display = 'none';
            }
          } else {
            let hotspotString = '';
            switch (localStorage.getItem("language")) {
              case 'en':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[14].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftFifteen", '-90px'
                );
                break;
              case 'sl':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[14].hotspotTitleSinhala + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftFifteen", '-100px'
                );
                break;
              case 'tm':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[14].hotspotTitleTamil + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftFifteen", '-120px'
                );
                break;
              default:
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[14].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftFifteen", '-90px'
                );
                break;
            }
            document.documentElement.style.setProperty(
              "--hotspotTextFifteen", hotspotString
            );
          }
        }
        if (JSON.parse(response.data[0].hotspotArray)[15]) {
          if (JSON.parse(response.data[0].hotspotArray)[15].disable) {
            var hotspotsSixteen = document.getElementsByClassName('loc-hotspot-sixteen');
            for (var i = 0; i < hotspotsSixteen.length; i++) {
              hotspotsSixteen[i].style.visibility = 'hidden';
              hotspotsSixteen[i].style.display = 'none';
            }
          } else {
            let hotspotString = '';
            switch (localStorage.getItem("language")) {
              case 'en':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[15].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftSixteen", '-108px'
                );
                break;
              case 'sl':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[15].hotspotTitleSinhala + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftSixteen", '-64px'
                );
                break;
              case 'tm':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[15].hotspotTitleTamil + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftSixteen", '-74px'
                );
                break;
              default:
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[15].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftSixteen", '-108px'
                );
                break;
            }
            document.documentElement.style.setProperty(
              "--hotspotTextSixteen", hotspotString
            );
          }
        }
        if (JSON.parse(response.data[0].hotspotArray)[16]) {
          if (JSON.parse(response.data[0].hotspotArray)[16].disable) {
            var hotspotsSeventeen = document.getElementsByClassName('loc-hotspot-seventeen');
            for (var i = 0; i < hotspotsSeventeen.length; i++) {
              hotspotsSeventeen[i].style.visibility = 'hidden';
              hotspotsSeventeen[i].style.display = 'none';
            }
          } else {
            let hotspotString = '';
            switch (localStorage.getItem("language")) {
              case 'en':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[16].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftSeventeen", '-126px'
                );
                break;
              case 'sl':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[16].hotspotTitleSinhala + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftSeventeen", '-104px'
                );
                break;
              case 'tm':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[16].hotspotTitleTamil + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftSeventeen", '-130px'
                );
                break;
              default:
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[16].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftSeventeen", '-126px'
                );
                break;
            }
            document.documentElement.style.setProperty(
              "--hotspotTextSeventeen", hotspotString
            );
          }
        }
        if (JSON.parse(response.data[0].hotspotArray)[17]) {
          if (JSON.parse(response.data[0].hotspotArray)[17].disable) {
            var hotspotsEighteen = document.getElementsByClassName('loc-hotspot-eighteen');
            for (var i = 0; i < hotspotsEighteen.length; i++) {
              hotspotsEighteen[i].style.visibility = 'hidden';
              hotspotsEighteen[i].style.display = 'none';
            }
          } else {
            let hotspotString = '';
            switch (localStorage.getItem("language")) {
              case 'en':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[17].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftEighteen", '-140px'
                );
                break;
              case 'sl':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[17].hotspotTitleSinhala + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftEighteen", '-110px'
                );
                break;
              case 'tm':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[17].hotspotTitleTamil + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftEighteen", '-162px'
                );
                break;
              default:
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[17].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftEighteen", '-140px'
                );
                break;
            }
            document.documentElement.style.setProperty(
              "--hotspotTextEighteen", hotspotString
            );
          }
        }
        if (JSON.parse(response.data[0].hotspotArray)[18]) {
          if (JSON.parse(response.data[0].hotspotArray)[18].disable) {
            var hotspotsNineteen = document.getElementsByClassName('loc-hotspot-nineteen');
            for (var i = 0; i < hotspotsNineteen.length; i++) {
              hotspotsNineteen[i].style.visibility = 'hidden';
              hotspotsNineteen[i].style.display = 'none';
            }
          } else {
            let hotspotString = '';
            switch (localStorage.getItem("language")) {
              case 'en':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[18].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftNineteen", '-150px'
                );
                break;
              case 'sl':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[18].hotspotTitleSinhala + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftNineteen", '-106px'
                );
                break;
              case 'tm':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[18].hotspotTitleTamil + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftNineteen", '-186px'
                );
                break;
              default:
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[18].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftNineteen", '-150px'
                );
                break;
            }
            document.documentElement.style.setProperty(
              "--hotspotTextNineteen", hotspotString
            );
          }
        }
        if (JSON.parse(response.data[0].hotspotArray)[19]) {
          if (JSON.parse(response.data[0].hotspotArray)[19].disable) {
            var hotspotsTwenty = document.getElementsByClassName('loc-hotspot-twenty');
            for (var i = 0; i < hotspotsTwenty.length; i++) {
              hotspotsTwenty[i].style.visibility = 'hidden';
              hotspotsTwenty[i].style.display = 'none';
            }
          } else {
            let hotspotString = '';
            switch (localStorage.getItem("language")) {
              case 'en':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[19].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftTwenty", '-40px'
                );
                break;
              case 'sl':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[19].hotspotTitleSinhala + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftTwenty", '-34px'
                );
                break;
              case 'tm':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[19].hotspotTitleTamil + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftTwenty", '-56px'
                );
                break;
              default:
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[19].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftTwenty", '-40px'
                );
                break;
            }
            document.documentElement.style.setProperty(
              "--hotspotTextTwenty", hotspotString
            );
          }
        }
        if (JSON.parse(response.data[0].hotspotArray)[20]) {
          if (JSON.parse(response.data[0].hotspotArray)[20].disable) {
            var hotspotsTwentyOne = document.getElementsByClassName('loc-hotspot-twentyone');
            for (var i = 0; i < hotspotsTwentyOne.length; i++) {
              hotspotsTwentyOne[i].style.visibility = 'hidden';
              hotspotsTwentyOne[i].style.display = 'none';
            }
          } else {
            let hotspotString = '';
            switch (localStorage.getItem("language")) {
              case 'en':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[20].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftTwentyOne", '-120px'
                );
                break;
              case 'sl':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[20].hotspotTitleSinhala + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftTwentyOne", '-120px'
                );
                break;
              case 'tm':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[20].hotspotTitleTamil + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftTwentyOne", '-164px'
                );
                break;
              default:
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[20].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftTwentyOne", '-120px'
                );
                break;
            }
            document.documentElement.style.setProperty(
              "--hotspotTextTwentyOne", hotspotString
            );
          }
        }
        if (JSON.parse(response.data[0].hotspotArray)[21]) {
          if (JSON.parse(response.data[0].hotspotArray)[21].disable) {
            var hotspotsTwentyTwo = document.getElementsByClassName('loc-hotspot-twentytwo');
            for (var i = 0; i < hotspotsTwentyTwo.length; i++) {
              hotspotsTwentyTwo[i].style.visibility = 'hidden';
              hotspotsTwentyTwo[i].style.display = 'none';
            }
          } else {
            let hotspotString = '';
            switch (localStorage.getItem("language")) {
              case 'en':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[21].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftTwentyTwo", '-150px'
                );
                break;
              case 'sl':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[21].hotspotTitleSinhala + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftTwentyTwo", '-106px'
                );
                break;
              case 'tm':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[21].hotspotTitleTamil + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftTwentyTwo", '-180px'
                );
                break;
              default:
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[21].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftTwentyTwo", '-150px'
                );
                break;
            }
            document.documentElement.style.setProperty(
              "--hotspotTextTwentyTwo", hotspotString
            );
          }
        }
        if (JSON.parse(response.data[0].hotspotArray)[22]) {
          if (JSON.parse(response.data[0].hotspotArray)[22].disable) {
            var hotspotsTwentyThree = document.getElementsByClassName('loc-hotspot-twentythree');
            for (var i = 0; i < hotspotsTwentyThree.length; i++) {
              hotspotsTwentyThree[i].style.visibility = 'hidden';
              hotspotsTwentyThree[i].style.display = 'none';
            }
          } else {
            let hotspotString = '';
            switch (localStorage.getItem("language")) {
              case 'en':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[22].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftTwentyThree", '-160px'
                );
                break;
              case 'sl':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[22].hotspotTitleSinhala + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftTwentyThree", '-132px'
                );
                break;
              case 'tm':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[22].hotspotTitleTamil + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftTwentyThree", '-200px'
                );
                break;
              default:
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[22].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftTwentyThree", '-160px'
                );
                break;
            }
            document.documentElement.style.setProperty(
              "--hotspotTextTwentyThree", hotspotString
            );
          }
        }
        if (JSON.parse(response.data[0].hotspotArray)[23]) {
          if (JSON.parse(response.data[0].hotspotArray)[23].disable) {
            var hotspotsTwentyFour = document.getElementsByClassName('loc-hotspot-twentyfour');
            for (var i = 0; i < hotspotsTwentyFour.length; i++) {
              hotspotsTwentyFour[i].style.visibility = 'hidden';
              hotspotsTwentyFour[i].style.display = 'none';
            }
          } else {
            let hotspotString = '';
            switch (localStorage.getItem("language")) {
              case 'en':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[23].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftTwentyFour", '-140px'
                );
                break;
              case 'sl':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[23].hotspotTitleSinhala + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftTwentyFour", '-130px'
                );
                break;
              case 'tm':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[23].hotspotTitleTamil + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftTwentyFour", '-180px'
                );
                break;
              default:
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[23].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftTwentyFour", '-140px'
                );
                break;
            }
            document.documentElement.style.setProperty(
              "--hotspotTextTwentyFour", hotspotString
            );
          }
        }
        if (JSON.parse(response.data[0].hotspotArray)[24]) {
          if (JSON.parse(response.data[0].hotspotArray)[24].disable) {
            var hotspotsTwentyFive = document.getElementsByClassName('loc-hotspot-twentyfive');
            for (var i = 0; i < hotspotsTwentyFive.length; i++) {
              hotspotsTwentyFive[i].style.visibility = 'hidden';
              hotspotsTwentyFive[i].style.display = 'none';
            }
          } else {
            let hotspotString = '';
            console.log(JSON.parse(response.data[0].hotspotArray)[24].hotspotTitleEnglish)
            switch (localStorage.getItem("language")) {
              case 'en':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[24].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftTwentyFive", '-90px'
                );
                break;
              case 'sl':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[24].hotspotTitleSinhala + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftTwentyFive", '-144px'
                );
                break;
              case 'tm':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[24].hotspotTitleTamil + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftTwentyFive", '-184px'
                );
                break;
              default:
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[24].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftTwentyFive", '-90px'
                );
                break;
            }
            document.documentElement.style.setProperty(
              "--hotspotTextTwentyFive", hotspotString
            );
          }
        }
        if (JSON.parse(response.data[0].hotspotArray)[25]) {
          if (JSON.parse(response.data[0].hotspotArray)[25].disable) {
            var hotspotsTwentySix = document.getElementsByClassName('loc-hotspot-twentysix');
            for (var i = 0; i < hotspotsTwentySix.length; i++) {
              hotspotsTwentySix[i].style.visibility = 'hidden';
              hotspotsTwentySix[i].style.display = 'none';
            }
          } else {
            let hotspotString = '';
            switch (localStorage.getItem("language")) {
              case 'en':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[25].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftTwentySix", '-134px'
                );
                break;
              case 'sl':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[25].hotspotTitleSinhala + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftTwentySix", '-108px'
                );
                break;
              case 'tm':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[25].hotspotTitleTamil + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftTwentySix", '-164px'
                );
                break;
              default:
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[25].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftTwentySix", '-134px'
                );
                break;
            }
            document.documentElement.style.setProperty(
              "--hotspotTextTwentySix", hotspotString
            );
          }
        }
      } else {
        setHotspotArray([]);
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      console.log("There was an error!", error);
    }
  };

  const onImageLoad = () => {
    setTimeout(() => {
      setIsShowFooter(true);
    }, 1000);
  }

  const [open, setOpen] = React.useState(false);
  const [imageIndex, setImageIndex] = React.useState(1);
  const [hfov, setHfov] = React.useState(100);
  const [tutorialOpen, setTutorialOpen] = React.useState(false);
  const navigate = useNavigate();
  const [seeMore, setSeeMore] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const seeMoreClick = () => {
    setSeeMore(true);
  };

  const seeLessClick = () => {
    setSeeMore(false);
  };

  useEffect(() => {
    getLocationDetails();
    localStorage.setItem("chambers", "1");
    setHotspotOpen(false);
    setFullImageOpen(false);
    if (window.innerWidth < 481) {
      setHfov(75);
    } else {
      setHfov(100);
    }
  }, []);

  const changeImage = (id) => {
    setHotspotOpen(false);
    setShowIframe(false);
    setIframeUrl("");
    setFullImageOpen(false);
    setShowImageLoading(false);
    switch (id) {
      case 1:
        switch (localStorage.getItem("language")) {
          case 'en':
            setLocationName(locationDetails[0].nameInEnglish);
            break;
          case 'sl':
            setLocationName(locationDetails[0].nameInSinhala);
            break;
          case 'tm':
            setLocationName(locationDetails[0].nameInTamil);
            break;
          default:
            setLocationName(locationDetails[0].nameInEnglish);
            break;
        }
        setImageIndex(1);
        if (locationDetails[0].hotspotArray) {
          setHotspotArray(JSON.parse(locationDetails[0].hotspotArray));
          if (JSON.parse(locationDetails[0].hotspotArray)[0]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[0].disable) {
              var hotspotsOne = document.getElementsByClassName('loc-hotspot');
              for (var i = 0; i < hotspotsOne.length; i++) {
                hotspotsOne[i].style.visibility = 'hidden';
                hotspotsOne[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[0].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeft", '-52px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[0].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeft", '-40px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[0].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeft", '-72px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[0].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeft", '-52px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotText", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[1]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[1].disable) {
              var hotspotsTwo = document.getElementsByClassName('loc-hotspot-two');
              for (var i = 0; i < hotspotsTwo.length; i++) {
                hotspotsTwo[i].style.visibility = 'hidden';
                hotspotsTwo[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[1].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwo", '-58px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[1].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwo", '-28px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[1].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwo", '-40px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[1].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwo", '-58px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextTwo", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[2]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[2].disable) {
              var hotspotsThree = document.getElementsByClassName('loc-hotspot-three');
              for (var i = 0; i < hotspotsThree.length; i++) {
                hotspotsThree[i].style.visibility = 'hidden';
                hotspotsThree[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[2].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftThree", '-80px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[2].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftThree", '-114px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[2].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftThree", '-160px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[2].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftThree", '-80px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextThree", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[3]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[3].disable) {
              var hotspotsFour = document.getElementsByClassName('loc-hotspot-four');
              for (var i = 0; i < hotspotsFour.length; i++) {
                hotspotsFour[i].style.visibility = 'hidden';
                hotspotsFour[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[3].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFour", '-126px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[3].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFour", '-64px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[3].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFour", '-160px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[3].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFour", '-126px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextFour", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[4]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[4].disable) {
              var hotspotsFive = document.getElementsByClassName('loc-hotspot-five');
              for (var i = 0; i < hotspotsFive.length; i++) {
                hotspotsFive[i].style.visibility = 'hidden';
                hotspotsFive[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[4].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFive", '-100px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[4].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFive", '-62px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[4].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFive", '-160px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[4].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFive", '-100px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextFive", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[5]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[5].disable) {
              var hotspotsSix = document.getElementsByClassName('loc-hotspot-six');
              for (var i = 0; i < hotspotsSix.length; i++) {
                hotspotsSix[i].style.visibility = 'hidden';
                hotspotsSix[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[5].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftSix", '-132px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[5].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftSix", '-88px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[5].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftSix", '-174px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[5].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftSix", '-132px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextSix", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[6]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[6].disable) {
              var hotspotsSeven = document.getElementsByClassName('loc-hotspot-seven');
              for (var i = 0; i < hotspotsSeven.length; i++) {
                hotspotsSeven[i].style.visibility = 'hidden';
                hotspotsSeven[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[6].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftSeven", '-110px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[6].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftSeven", '-62px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[6].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftSeven", '-110px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[6].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftSeven", '-110px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextSeven", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[7]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[7].disable) {
              var hotspotsEight = document.getElementsByClassName('loc-hotspot-eight');
              for (var i = 0; i < hotspotsEight.length; i++) {
                hotspotsEight[i].style.visibility = 'hidden';
                hotspotsEight[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[7].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftEight", '-90px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[7].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftEight", '-64px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[7].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftEight", '-90px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[7].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftEight", '-90px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextEight", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[8]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[8].disable) {
              var hotspotsNine = document.getElementsByClassName('loc-hotspot-nine');
              for (var i = 0; i < hotspotsNine.length; i++) {
                hotspotsNine[i].style.visibility = 'hidden';
                hotspotsNine[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[8].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftNine", '-56px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[8].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftNine", '-36px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[8].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftNine", '-60px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[8].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftNine", '-56px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextNine", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[9]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[9].disable) {
              var hotspotsTen = document.getElementsByClassName('loc-hotspot-ten');
              for (var i = 0; i < hotspotsTen.length; i++) {
                hotspotsTen[i].style.visibility = 'hidden';
                hotspotsTen[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[9].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTen", '-50px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[9].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTen", '-42px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[9].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTen", '-54px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[9].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTen", '-50px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextTen", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[10]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[10].disable) {
              var hotspotsEleven = document.getElementsByClassName('loc-hotspot-eleven');
              for (var i = 0; i < hotspotsEleven.length; i++) {
                hotspotsEleven[i].style.visibility = 'hidden';
                hotspotsEleven[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[10].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftEleven", '-200px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[10].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftEleven", '-160px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[10].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftEleven", '-240px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[10].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftEleven", '-200px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextEleven", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[11]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[11].disable) {
              var hotspotsTwelve = document.getElementsByClassName('loc-hotspot-twelve');
              for (var i = 0; i < hotspotsTwelve.length; i++) {
                hotspotsTwelve[i].style.visibility = 'hidden';
                hotspotsTwelve[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[11].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwelve", '-90px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[11].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwelve", '-64px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[11].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwelve", '-124px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[11].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwelve", '-90px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextTwelve", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[12]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[12].disable) {
              var hotspotsThirteen = document.getElementsByClassName('loc-hotspot-thirteen');
              for (var i = 0; i < hotspotsThirteen.length; i++) {
                hotspotsThirteen[i].style.visibility = 'hidden';
                hotspotsThirteen[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[12].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftThirteen", '-182px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[12].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftThirteen", '-200px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[12].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftThirteen", '-290px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[12].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftThirteen", '-182px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextThirteen", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[13]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[13].disable) {
              var hotspotsFourteen = document.getElementsByClassName('loc-hotspot-fourteen');
              for (var i = 0; i < hotspotsFourteen.length; i++) {
                hotspotsFourteen[i].style.visibility = 'hidden';
                hotspotsFourteen[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[13].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFourteen", '-190px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[13].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFourteen", '-100px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[13].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFourteen", '-128px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[13].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFourteen", '-190px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextFourteen", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[14]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[14].disable) {
              var hotspotsFifteen = document.getElementsByClassName('loc-hotspot-fifteen');
              for (var i = 0; i < hotspotsFifteen.length; i++) {
                hotspotsFifteen[i].style.visibility = 'hidden';
                hotspotsFifteen[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[14].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFifteen", '-90px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[14].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFifteen", '-100px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[14].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFifteen", '-120px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[14].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFifteen", '-90px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextFifteen", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[15]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[15].disable) {
              var hotspotsSixteen = document.getElementsByClassName('loc-hotspot-sixteen');
              for (var i = 0; i < hotspotsSixteen.length; i++) {
                hotspotsSixteen[i].style.visibility = 'hidden';
                hotspotsSixteen[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[15].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftSixteen", '-108px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[15].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftSixteen", '-64px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[15].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftSixteen", '-74px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[15].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftSixteen", '-108px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextSixteen", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[16]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[16].disable) {
              var hotspotsSeventeen = document.getElementsByClassName('loc-hotspot-seventeen');
              for (var i = 0; i < hotspotsSeventeen.length; i++) {
                hotspotsSeventeen[i].style.visibility = 'hidden';
                hotspotsSeventeen[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[16].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftSeventeen", '-126px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[16].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftSeventeen", '-104px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[16].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftSeventeen", '-130px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[16].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftSeventeen", '-126px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextSeventeen", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[17]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[17].disable) {
              var hotspotsEighteen = document.getElementsByClassName('loc-hotspot-eighteen');
              for (var i = 0; i < hotspotsEighteen.length; i++) {
                hotspotsEighteen[i].style.visibility = 'hidden';
                hotspotsEighteen[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[17].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftEighteen", '-140px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[17].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftEighteen", '-110px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[17].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftEighteen", '-162px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[17].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftEighteen", '-140px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextEighteen", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[18]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[18].disable) {
              var hotspotsNineteen = document.getElementsByClassName('loc-hotspot-nineteen');
              for (var i = 0; i < hotspotsNineteen.length; i++) {
                hotspotsNineteen[i].style.visibility = 'hidden';
                hotspotsNineteen[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[18].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftNineteen", '-150px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[18].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftNineteen", '-106px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[18].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftNineteen", '-186px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[18].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftNineteen", '-150px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextNineteen", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[19]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[19].disable) {
              var hotspotsTwenty = document.getElementsByClassName('loc-hotspot-twenty');
              for (var i = 0; i < hotspotsTwenty.length; i++) {
                hotspotsTwenty[i].style.visibility = 'hidden';
                hotspotsTwenty[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[19].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwenty", '-40px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[19].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwenty", '-34px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[19].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwenty", '-56px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[19].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwenty", '-40px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextTwenty", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[20]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[20].disable) {
              var hotspotsTwentyOne = document.getElementsByClassName('loc-hotspot-twentyone');
              for (var i = 0; i < hotspotsTwentyOne.length; i++) {
                hotspotsTwentyOne[i].style.visibility = 'hidden';
                hotspotsTwentyOne[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[20].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwentyOne", '-120px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[20].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwentyOne", '-120px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[20].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwentyOne", '-164px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[20].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwentyOne", '-120px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextTwentyOne", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[21]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[21].disable) {
              var hotspotsTwentyTwo = document.getElementsByClassName('loc-hotspot-twentytwo');
              for (var i = 0; i < hotspotsTwentyTwo.length; i++) {
                hotspotsTwentyTwo[i].style.visibility = 'hidden';
                hotspotsTwentyTwo[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[21].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwentyTwo", '-150px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[21].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwentyTwo", '-106px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[21].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwentyTwo", '-180px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[21].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwentyTwo", '-150px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextTwentyTwo", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[22]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[22].disable) {
              var hotspotsTwentyThree = document.getElementsByClassName('loc-hotspot-twentythree');
              for (var i = 0; i < hotspotsTwentyThree.length; i++) {
                hotspotsTwentyThree[i].style.visibility = 'hidden';
                hotspotsTwentyThree[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[22].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwentyThree", '-160px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[22].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwentyThree", '-132px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[22].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwentyThree", '-200px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[22].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwentyThree", '-160px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextTwentyThree", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[23]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[23].disable) {
              var hotspotsTwentyFour = document.getElementsByClassName('loc-hotspot-twentyfour');
              for (var i = 0; i < hotspotsTwentyFour.length; i++) {
                hotspotsTwentyFour[i].style.visibility = 'hidden';
                hotspotsTwentyFour[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[23].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwentyFour", '-140px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[23].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwentyFour", '-130px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[23].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwentyFour", '-180px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[23].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwentyFour", '-140px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextTwentyFour", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[24]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[24].disable) {
              var hotspotsTwentyFive = document.getElementsByClassName('loc-hotspot-twentyfive');
              for (var i = 0; i < hotspotsTwentyFive.length; i++) {
                hotspotsTwentyFive[i].style.visibility = 'hidden';
                hotspotsTwentyFive[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              console.log(JSON.parse(locationDetails[0].hotspotArray)[24].hotspotTitleEnglish)
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[24].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwentyFive", '-90px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[24].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwentyFive", '-144px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[24].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwentyFive", '-184px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[24].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwentyFive", '-90px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextTwentyFive", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[25]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[25].disable) {
              var hotspotsTwentySix = document.getElementsByClassName('loc-hotspot-twentysix');
              for (var i = 0; i < hotspotsTwentySix.length; i++) {
                hotspotsTwentySix[i].style.visibility = 'hidden';
                hotspotsTwentySix[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[25].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwentySix", '-134px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[25].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwentySix", '-108px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[25].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwentySix", '-164px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[25].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwentySix", '-134px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextTwentySix", hotspotString
              );
            }
          }
        } else {
          setHotspotArray([]);
        }
        break;
      case 2:
        switch (localStorage.getItem("language")) {
          case 'en':
            setLocationName(locationDetails[1].nameInEnglish);
            break;
          case 'sl':
            setLocationName(locationDetails[1].nameInSinhala);
            break;
          case 'tm':
            setLocationName(locationDetails[1].nameInTamil);
            break;
          default:
            setLocationName(locationDetails[1].nameInEnglish);
            break;
        }
        setImageIndex(2);
        if (locationDetails[1].hotspotArray) {
          setHotspotArray(JSON.parse(locationDetails[1].hotspotArray));
          if (JSON.parse(locationDetails[1].hotspotArray)[0]) {
            if (JSON.parse(locationDetails[1].hotspotArray)[0].disable) {
              var hotspotsOne = document.getElementsByClassName('loc-hotspot');
              for (var i = 0; i < hotspotsOne.length; i++) {
                hotspotsOne[i].style.visibility = 'hidden';
                hotspotsOne[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[0].hotspotTitleEnglish + `'`;

                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[0].hotspotTitleSinhala + `'`;

                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[0].hotspotTitleTamil + `'`;

                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[0].hotspotTitleEnglish + `'`;
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotText", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[1].hotspotArray)[1]) {
            if (JSON.parse(locationDetails[1].hotspotArray)[1].disable) {
              var hotspotsTwo = document.getElementsByClassName('loc-hotspot-two');
              for (var i = 0; i < hotspotsTwo.length; i++) {
                hotspotsTwo[i].style.visibility = 'hidden';
                hotspotsTwo[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[1].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwo", '-74px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[1].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwo", '-80px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[1].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwo", '-112px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[1].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwo", '-70px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextTwo", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[1].hotspotArray)[2]) {
            if (JSON.parse(locationDetails[1].hotspotArray)[2].disable) {
              var hotspotsThree = document.getElementsByClassName('loc-hotspot-three');
              for (var i = 0; i < hotspotsThree.length; i++) {
                hotspotsThree[i].style.visibility = 'hidden';
                hotspotsThree[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[2].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftThree", '-70px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[2].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftThree", '-70px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[2].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftThree", '-114px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[2].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftThree", '-70px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextThree", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[1].hotspotArray)[3]) {
            if (JSON.parse(locationDetails[1].hotspotArray)[3].disable) {
              var hotspotsFour = document.getElementsByClassName('loc-hotspot-four');
              for (var i = 0; i < hotspotsFour.length; i++) {
                hotspotsFour[i].style.visibility = 'hidden';
                hotspotsFour[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[3].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFour", '-72px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[3].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFour", '-78px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[3].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFour", '-116px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[3].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFour", '-72px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextFour", hotspotString
              );
            }
          }

        } else {
          setHotspotArray([]);
        }
        break;
      case 3:
        switch (localStorage.getItem("language")) {
          case 'en':
            setLocationName(locationDetails[2].nameInEnglish);
            break;
          case 'sl':
            setLocationName(locationDetails[2].nameInSinhala);
            break;
          case 'tm':
            setLocationName(locationDetails[2].nameInTamil);
            break;
          default:
            setLocationName(locationDetails[2].nameInEnglish);
            break;
        }
        setImageIndex(3);
        if (locationDetails[2].hotspotArray) {
          setHotspotArray(JSON.parse(locationDetails[2].hotspotArray));
          if (JSON.parse(locationDetails[2].hotspotArray)[0]) {
            if (JSON.parse(locationDetails[2].hotspotArray)[0].disable) {
              var hotspotsOne = document.getElementsByClassName('loc-hotspot');
              for (var i = 0; i < hotspotsOne.length; i++) {
                hotspotsOne[i].style.visibility = 'hidden';
                hotspotsOne[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[0].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeft", '-52px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[0].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeft", '-52px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[0].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeft", '-90px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[0].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeft", '-52px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotText", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[2].hotspotArray)[1]) {
            if (JSON.parse(locationDetails[2].hotspotArray)[1].disable) {
              var hotspotsTwo = document.getElementsByClassName('loc-hotspot-two');
              for (var i = 0; i < hotspotsTwo.length; i++) {
                hotspotsTwo[i].style.visibility = 'hidden';
                hotspotsTwo[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[1].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwo", '-82px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[1].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwo", '-220px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[1].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwo", '-380px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[1].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwo", '-82px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextTwo", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[2].hotspotArray)[2]) {
            if (JSON.parse(locationDetails[2].hotspotArray)[2].disable) {
              var hotspotsThree = document.getElementsByClassName('loc-hotspot-three');
              for (var i = 0; i < hotspotsThree.length; i++) {
                hotspotsThree[i].style.visibility = 'hidden';
                hotspotsThree[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[2].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftThree", '-98px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[2].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftThree", '-112px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[2].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftThree", '-214px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[2].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftThree", '-98px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextThree", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[2].hotspotArray)[3]) {
            if (JSON.parse(locationDetails[2].hotspotArray)[3].disable) {
              var hotspotsFour = document.getElementsByClassName('loc-hotspot-four');
              for (var i = 0; i < hotspotsFour.length; i++) {
                hotspotsFour[i].style.visibility = 'hidden';
                hotspotsFour[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[3].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFour", '-70px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[3].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFour", '-84px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[3].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFour", '-192px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[3].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFour", '-70px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextFour", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[2].hotspotArray)[4]) {
            if (JSON.parse(locationDetails[2].hotspotArray)[4].disable) {
              var hotspotsFive = document.getElementsByClassName('loc-hotspot-five');
              for (var i = 0; i < hotspotsFive.length; i++) {
                hotspotsFive[i].style.visibility = 'hidden';
                hotspotsFive[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[4].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFive", '-36px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[4].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFive", '-58px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[4].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFive", '-82px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[4].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFive", '-36px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextFive", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[2].hotspotArray)[5]) {
            if (JSON.parse(locationDetails[2].hotspotArray)[5].disable) {
              var hotspotsSix = document.getElementsByClassName('loc-hotspot-six');
              for (var i = 0; i < hotspotsSix.length; i++) {
                hotspotsSix[i].style.visibility = 'hidden';
                hotspotsSix[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[5].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftSix", '-106px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[5].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftSix", '-56px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[5].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftSix", '-140px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[5].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftSix", '-106px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextSix", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[2].hotspotArray)[6]) {
            if (JSON.parse(locationDetails[2].hotspotArray)[6].disable) {
              var hotspotsSeven = document.getElementsByClassName('loc-hotspot-seven');
              for (var i = 0; i < hotspotsSeven.length; i++) {
                hotspotsSeven[i].style.visibility = 'hidden';
                hotspotsSeven[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[6].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftSeven", '-134px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[6].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftSeven", '-118px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[6].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftSeven", '-182px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[6].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftSeven", '-134px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextSeven", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[2].hotspotArray)[7]) {
            if (JSON.parse(locationDetails[2].hotspotArray)[7].disable) {
              var hotspotsEight = document.getElementsByClassName('loc-hotspot-eight');
              for (var i = 0; i < hotspotsEight.length; i++) {
                hotspotsEight[i].style.visibility = 'hidden';
                hotspotsEight[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[7].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftEight", '-136px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[7].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftEight", '-108px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[7].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftEight", '-158px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[7].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftEight", '-136px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextEight", hotspotString
              );
            }
          }
        } else {
          setHotspotArray([]);
        }
        break;
      default:
        break;
    }
  };

  const goPrevious = () => {
    setHotspotOpen(false);
    setShowIframe(false);
    setIframeUrl("");
    setFullImageOpen(false);
    setShowImageLoading(false);
    switch (imageIndex) {
      case 1:
        navigate("/silver-door");
        break;
      case 2:
        switch (localStorage.getItem("language")) {
          case 'en':
            setLocationName(locationDetails[0].nameInEnglish);
            break;
          case 'sl':
            setLocationName(locationDetails[0].nameInSinhala);
            break;
          case 'tm':
            setLocationName(locationDetails[0].nameInTamil);
            break;
          default:
            setLocationName(locationDetails[0].nameInEnglish);
            break;
        }
        setImageIndex(1);
        if (locationDetails[0].hotspotArray) {
          setHotspotArray(JSON.parse(locationDetails[0].hotspotArray));
          if (JSON.parse(locationDetails[0].hotspotArray)[0]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[0].disable) {
              var hotspotsOne = document.getElementsByClassName('loc-hotspot');
              for (var i = 0; i < hotspotsOne.length; i++) {
                hotspotsOne[i].style.visibility = 'hidden';
                hotspotsOne[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[0].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeft", '-52px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[0].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeft", '-40px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[0].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeft", '-72px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[0].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeft", '-52px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotText", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[1]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[1].disable) {
              var hotspotsTwo = document.getElementsByClassName('loc-hotspot-two');
              for (var i = 0; i < hotspotsTwo.length; i++) {
                hotspotsTwo[i].style.visibility = 'hidden';
                hotspotsTwo[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[1].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwo", '-58px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[1].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwo", '-28px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[1].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwo", '-40px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[1].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwo", '-58px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextTwo", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[2]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[2].disable) {
              var hotspotsThree = document.getElementsByClassName('loc-hotspot-three');
              for (var i = 0; i < hotspotsThree.length; i++) {
                hotspotsThree[i].style.visibility = 'hidden';
                hotspotsThree[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[2].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftThree", '-80px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[2].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftThree", '-114px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[2].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftThree", '-160px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[2].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftThree", '-80px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextThree", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[3]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[3].disable) {
              var hotspotsFour = document.getElementsByClassName('loc-hotspot-four');
              for (var i = 0; i < hotspotsFour.length; i++) {
                hotspotsFour[i].style.visibility = 'hidden';
                hotspotsFour[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[3].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFour", '-126px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[3].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFour", '-64px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[3].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFour", '-160px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[3].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFour", '-126px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextFour", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[4]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[4].disable) {
              var hotspotsFive = document.getElementsByClassName('loc-hotspot-five');
              for (var i = 0; i < hotspotsFive.length; i++) {
                hotspotsFive[i].style.visibility = 'hidden';
                hotspotsFive[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[4].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFive", '-100px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[4].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFive", '-62px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[4].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFive", '-160px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[4].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFive", '-100px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextFive", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[5]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[5].disable) {
              var hotspotsSix = document.getElementsByClassName('loc-hotspot-six');
              for (var i = 0; i < hotspotsSix.length; i++) {
                hotspotsSix[i].style.visibility = 'hidden';
                hotspotsSix[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[5].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftSix", '-132px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[5].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftSix", '-88px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[5].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftSix", '-174px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[5].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftSix", '-132px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextSix", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[6]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[6].disable) {
              var hotspotsSeven = document.getElementsByClassName('loc-hotspot-seven');
              for (var i = 0; i < hotspotsSeven.length; i++) {
                hotspotsSeven[i].style.visibility = 'hidden';
                hotspotsSeven[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[6].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftSeven", '-110px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[6].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftSeven", '-62px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[6].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftSeven", '-110px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[6].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftSeven", '-110px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextSeven", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[7]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[7].disable) {
              var hotspotsEight = document.getElementsByClassName('loc-hotspot-eight');
              for (var i = 0; i < hotspotsEight.length; i++) {
                hotspotsEight[i].style.visibility = 'hidden';
                hotspotsEight[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[7].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftEight", '-90px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[7].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftEight", '-64px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[7].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftEight", '-90px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[7].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftEight", '-90px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextEight", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[8]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[8].disable) {
              var hotspotsNine = document.getElementsByClassName('loc-hotspot-nine');
              for (var i = 0; i < hotspotsNine.length; i++) {
                hotspotsNine[i].style.visibility = 'hidden';
                hotspotsNine[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[8].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftNine", '-56px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[8].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftNine", '-36px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[8].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftNine", '-60px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[8].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftNine", '-56px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextNine", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[9]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[9].disable) {
              var hotspotsTen = document.getElementsByClassName('loc-hotspot-ten');
              for (var i = 0; i < hotspotsTen.length; i++) {
                hotspotsTen[i].style.visibility = 'hidden';
                hotspotsTen[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[9].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTen", '-50px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[9].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTen", '-42px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[9].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTen", '-54px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[9].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTen", '-50px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextTen", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[10]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[10].disable) {
              var hotspotsEleven = document.getElementsByClassName('loc-hotspot-eleven');
              for (var i = 0; i < hotspotsEleven.length; i++) {
                hotspotsEleven[i].style.visibility = 'hidden';
                hotspotsEleven[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[10].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftEleven", '-200px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[10].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftEleven", '-160px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[10].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftEleven", '-240px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[10].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftEleven", '-200px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextEleven", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[11]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[11].disable) {
              var hotspotsTwelve = document.getElementsByClassName('loc-hotspot-twelve');
              for (var i = 0; i < hotspotsTwelve.length; i++) {
                hotspotsTwelve[i].style.visibility = 'hidden';
                hotspotsTwelve[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[11].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwelve", '-90px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[11].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwelve", '-64px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[11].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwelve", '-124px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[11].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwelve", '-90px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextTwelve", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[12]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[12].disable) {
              var hotspotsThirteen = document.getElementsByClassName('loc-hotspot-thirteen');
              for (var i = 0; i < hotspotsThirteen.length; i++) {
                hotspotsThirteen[i].style.visibility = 'hidden';
                hotspotsThirteen[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[12].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftThirteen", '-182px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[12].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftThirteen", '-200px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[12].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftThirteen", '-290px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[12].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftThirteen", '-182px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextThirteen", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[13]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[13].disable) {
              var hotspotsFourteen = document.getElementsByClassName('loc-hotspot-fourteen');
              for (var i = 0; i < hotspotsFourteen.length; i++) {
                hotspotsFourteen[i].style.visibility = 'hidden';
                hotspotsFourteen[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[13].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFourteen", '-190px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[13].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFourteen", '-100px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[13].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFourteen", '-128px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[13].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFourteen", '-190px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextFourteen", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[14]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[14].disable) {
              var hotspotsFifteen = document.getElementsByClassName('loc-hotspot-fifteen');
              for (var i = 0; i < hotspotsFifteen.length; i++) {
                hotspotsFifteen[i].style.visibility = 'hidden';
                hotspotsFifteen[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[14].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFifteen", '-90px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[14].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFifteen", '-100px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[14].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFifteen", '-120px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[14].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFifteen", '-90px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextFifteen", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[15]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[15].disable) {
              var hotspotsSixteen = document.getElementsByClassName('loc-hotspot-sixteen');
              for (var i = 0; i < hotspotsSixteen.length; i++) {
                hotspotsSixteen[i].style.visibility = 'hidden';
                hotspotsSixteen[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[15].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftSixteen", '-108px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[15].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftSixteen", '-64px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[15].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftSixteen", '-74px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[15].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftSixteen", '-108px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextSixteen", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[16]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[16].disable) {
              var hotspotsSeventeen = document.getElementsByClassName('loc-hotspot-seventeen');
              for (var i = 0; i < hotspotsSeventeen.length; i++) {
                hotspotsSeventeen[i].style.visibility = 'hidden';
                hotspotsSeventeen[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[16].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftSeventeen", '-126px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[16].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftSeventeen", '-104px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[16].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftSeventeen", '-130px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[16].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftSeventeen", '-126px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextSeventeen", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[17]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[17].disable) {
              var hotspotsEighteen = document.getElementsByClassName('loc-hotspot-eighteen');
              for (var i = 0; i < hotspotsEighteen.length; i++) {
                hotspotsEighteen[i].style.visibility = 'hidden';
                hotspotsEighteen[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[17].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftEighteen", '-140px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[17].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftEighteen", '-110px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[17].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftEighteen", '-162px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[17].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftEighteen", '-140px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextEighteen", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[18]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[18].disable) {
              var hotspotsNineteen = document.getElementsByClassName('loc-hotspot-nineteen');
              for (var i = 0; i < hotspotsNineteen.length; i++) {
                hotspotsNineteen[i].style.visibility = 'hidden';
                hotspotsNineteen[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[18].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftNineteen", '-150px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[18].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftNineteen", '-106px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[18].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftNineteen", '-186px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[18].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftNineteen", '-150px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextNineteen", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[19]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[19].disable) {
              var hotspotsTwenty = document.getElementsByClassName('loc-hotspot-twenty');
              for (var i = 0; i < hotspotsTwenty.length; i++) {
                hotspotsTwenty[i].style.visibility = 'hidden';
                hotspotsTwenty[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[19].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwenty", '-40px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[19].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwenty", '-34px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[19].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwenty", '-56px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[19].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwenty", '-40px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextTwenty", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[20]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[20].disable) {
              var hotspotsTwentyOne = document.getElementsByClassName('loc-hotspot-twentyone');
              for (var i = 0; i < hotspotsTwentyOne.length; i++) {
                hotspotsTwentyOne[i].style.visibility = 'hidden';
                hotspotsTwentyOne[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[20].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwentyOne", '-120px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[20].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwentyOne", '-120px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[20].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwentyOne", '-164px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[20].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwentyOne", '-120px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextTwentyOne", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[21]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[21].disable) {
              var hotspotsTwentyTwo = document.getElementsByClassName('loc-hotspot-twentytwo');
              for (var i = 0; i < hotspotsTwentyTwo.length; i++) {
                hotspotsTwentyTwo[i].style.visibility = 'hidden';
                hotspotsTwentyTwo[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[21].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwentyTwo", '-150px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[21].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwentyTwo", '-106px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[21].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwentyTwo", '-180px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[21].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwentyTwo", '-150px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextTwentyTwo", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[22]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[22].disable) {
              var hotspotsTwentyThree = document.getElementsByClassName('loc-hotspot-twentythree');
              for (var i = 0; i < hotspotsTwentyThree.length; i++) {
                hotspotsTwentyThree[i].style.visibility = 'hidden';
                hotspotsTwentyThree[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[22].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwentyThree", '-160px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[22].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwentyThree", '-132px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[22].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwentyThree", '-200px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[22].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwentyThree", '-160px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextTwentyThree", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[23]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[23].disable) {
              var hotspotsTwentyFour = document.getElementsByClassName('loc-hotspot-twentyfour');
              for (var i = 0; i < hotspotsTwentyFour.length; i++) {
                hotspotsTwentyFour[i].style.visibility = 'hidden';
                hotspotsTwentyFour[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[23].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwentyFour", '-140px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[23].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwentyFour", '-130px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[23].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwentyFour", '-180px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[23].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwentyFour", '-140px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextTwentyFour", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[24]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[24].disable) {
              var hotspotsTwentyFive = document.getElementsByClassName('loc-hotspot-twentyfive');
              for (var i = 0; i < hotspotsTwentyFive.length; i++) {
                hotspotsTwentyFive[i].style.visibility = 'hidden';
                hotspotsTwentyFive[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              console.log(JSON.parse(locationDetails[0].hotspotArray)[24].hotspotTitleEnglish)
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[24].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwentyFive", '-90px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[24].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwentyFive", '-144px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[24].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwentyFive", '-184px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[24].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwentyFive", '-90px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextTwentyFive", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[25]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[25].disable) {
              var hotspotsTwentySix = document.getElementsByClassName('loc-hotspot-twentysix');
              for (var i = 0; i < hotspotsTwentySix.length; i++) {
                hotspotsTwentySix[i].style.visibility = 'hidden';
                hotspotsTwentySix[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[25].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwentySix", '-134px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[25].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwentySix", '-108px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[25].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwentySix", '-164px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[25].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwentySix", '-134px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextTwentySix", hotspotString
              );
            }
          }
        } else {
          setHotspotArray([]);
        }
        break;
      case 3:
        switch (localStorage.getItem("language")) {
          case 'en':
            setLocationName(locationDetails[1].nameInEnglish);
            break;
          case 'sl':
            setLocationName(locationDetails[1].nameInSinhala);
            break;
          case 'tm':
            setLocationName(locationDetails[1].nameInTamil);
            break;
          default:
            setLocationName(locationDetails[1].nameInEnglish);
            break;
        }
        setImageIndex(2);
        if (locationDetails[1].hotspotArray) {
          setHotspotArray(JSON.parse(locationDetails[1].hotspotArray));
          if (JSON.parse(locationDetails[1].hotspotArray)[0]) {
            if (JSON.parse(locationDetails[1].hotspotArray)[0].disable) {
              var hotspotsOne = document.getElementsByClassName('loc-hotspot');
              for (var i = 0; i < hotspotsOne.length; i++) {
                hotspotsOne[i].style.visibility = 'hidden';
                hotspotsOne[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[0].hotspotTitleEnglish + `'`;

                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[0].hotspotTitleSinhala + `'`;

                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[0].hotspotTitleTamil + `'`;

                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[0].hotspotTitleEnglish + `'`;
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotText", hotspotString
              );
            }
          }
        } else {
          setHotspotArray([]);
        }
        break;
      default:
        break;
    }
  };

  const goNext = () => {
    setHotspotOpen(false);
    setShowIframe(false);
    setIframeUrl("");
    setFullImageOpen(false);
    setShowImageLoading(false);
    switch (imageIndex) {
      case 1:
        switch (localStorage.getItem("language")) {
          case 'en':
            setLocationName(locationDetails[1].nameInEnglish);
            break;
          case 'sl':
            setLocationName(locationDetails[1].nameInSinhala);
            break;
          case 'tm':
            setLocationName(locationDetails[1].nameInTamil);
            break;
          default:
            setLocationName(locationDetails[1].nameInEnglish);
            break;
        }
        setImageIndex(2);
        if (locationDetails[1].hotspotArray) {
          setHotspotArray(JSON.parse(locationDetails[1].hotspotArray));
          if (JSON.parse(locationDetails[1].hotspotArray)[0]) {
            if (JSON.parse(locationDetails[1].hotspotArray)[0].disable) {
              var hotspotsOne = document.getElementsByClassName('loc-hotspot');
              for (var i = 0; i < hotspotsOne.length; i++) {
                hotspotsOne[i].style.visibility = 'hidden';
                hotspotsOne[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[0].hotspotTitleEnglish + `'`;

                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[0].hotspotTitleSinhala + `'`;

                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[0].hotspotTitleTamil + `'`;

                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[0].hotspotTitleEnglish + `'`;
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotText", hotspotString
              );
            }
          }
        } else {
          setHotspotArray([]);
        }
        break;
      case 2:
        switch (localStorage.getItem("language")) {
          case 'en':
            setLocationName(locationDetails[2].nameInEnglish);
            break;
          case 'sl':
            setLocationName(locationDetails[2].nameInSinhala);
            break;
          case 'tm':
            setLocationName(locationDetails[2].nameInTamil);
            break;
          default:
            setLocationName(locationDetails[2].nameInEnglish);
            break;
        }
        setImageIndex(3);
        if (locationDetails[2].hotspotArray) {
          setHotspotArray(JSON.parse(locationDetails[2].hotspotArray));
          if (JSON.parse(locationDetails[2].hotspotArray)[0]) {
            if (JSON.parse(locationDetails[2].hotspotArray)[0].disable) {
              var hotspotsOne = document.getElementsByClassName('loc-hotspot');
              for (var i = 0; i < hotspotsOne.length; i++) {
                hotspotsOne[i].style.visibility = 'hidden';
                hotspotsOne[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[0].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeft", '-52px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[0].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeft", '-52px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[0].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeft", '-90px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[0].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeft", '-52px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotText", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[2].hotspotArray)[1]) {
            if (JSON.parse(locationDetails[2].hotspotArray)[1].disable) {
              var hotspotsTwo = document.getElementsByClassName('loc-hotspot-two');
              for (var i = 0; i < hotspotsTwo.length; i++) {
                hotspotsTwo[i].style.visibility = 'hidden';
                hotspotsTwo[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[1].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwo", '-82px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[1].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwo", '-220px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[1].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwo", '-380px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[1].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwo", '-82px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextTwo", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[2].hotspotArray)[2]) {
            if (JSON.parse(locationDetails[2].hotspotArray)[2].disable) {
              var hotspotsThree = document.getElementsByClassName('loc-hotspot-three');
              for (var i = 0; i < hotspotsThree.length; i++) {
                hotspotsThree[i].style.visibility = 'hidden';
                hotspotsThree[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[2].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftThree", '-98px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[2].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftThree", '-112px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[2].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftThree", '-214px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[2].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftThree", '-98px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextThree", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[2].hotspotArray)[3]) {
            if (JSON.parse(locationDetails[2].hotspotArray)[3].disable) {
              var hotspotsFour = document.getElementsByClassName('loc-hotspot-four');
              for (var i = 0; i < hotspotsFour.length; i++) {
                hotspotsFour[i].style.visibility = 'hidden';
                hotspotsFour[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[3].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFour", '-70px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[3].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFour", '-84px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[3].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFour", '-192px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[3].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFour", '-70px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextFour", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[2].hotspotArray)[4]) {
            if (JSON.parse(locationDetails[2].hotspotArray)[4].disable) {
              var hotspotsFive = document.getElementsByClassName('loc-hotspot-five');
              for (var i = 0; i < hotspotsFive.length; i++) {
                hotspotsFive[i].style.visibility = 'hidden';
                hotspotsFive[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[4].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFive", '-36px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[4].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFive", '-58px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[4].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFive", '-82px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[4].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFive", '-36px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextFive", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[2].hotspotArray)[5]) {
            if (JSON.parse(locationDetails[2].hotspotArray)[5].disable) {
              var hotspotsSix = document.getElementsByClassName('loc-hotspot-six');
              for (var i = 0; i < hotspotsSix.length; i++) {
                hotspotsSix[i].style.visibility = 'hidden';
                hotspotsSix[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[5].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftSix", '-106px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[5].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftSix", '-56px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[5].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftSix", '-140px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[5].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftSix", '-106px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextSix", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[2].hotspotArray)[6]) {
            if (JSON.parse(locationDetails[2].hotspotArray)[6].disable) {
              var hotspotsSeven = document.getElementsByClassName('loc-hotspot-seven');
              for (var i = 0; i < hotspotsSeven.length; i++) {
                hotspotsSeven[i].style.visibility = 'hidden';
                hotspotsSeven[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[6].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftSeven", '-134px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[6].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftSeven", '-118px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[6].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftSeven", '-182px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[6].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftSeven", '-134px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextSeven", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[2].hotspotArray)[7]) {
            if (JSON.parse(locationDetails[2].hotspotArray)[7].disable) {
              var hotspotsEight = document.getElementsByClassName('loc-hotspot-eight');
              for (var i = 0; i < hotspotsEight.length; i++) {
                hotspotsEight[i].style.visibility = 'hidden';
                hotspotsEight[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[7].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftEight", '-136px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[7].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftEight", '-108px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[7].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftEight", '-158px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[7].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftEight", '-136px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextEight", hotspotString
              );
            }
          }
        } else {
          setHotspotArray([]);
        }
        break;
      case 3:
        navigate("/portrait-collection");
        break;
      default:
        break;
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleHotspotOneOneClickOpen = () => {
    setShowImageLoading(true);
    setShowIframe(false);
    setIframeUrl("");
    setShowIframe(false);
    setIframeUrl("");
    setHotspotOpen(false);
    setFullImageOpen(false);
    setHotspotImage('');
    setHostpotDescription('');
    setHostpotTitle('');
    if (!JSON.parse(locationDetails[0].hotspotArray)[0].disableHotspotIframe) {
      console.log(JSON.parse(locationDetails[0].hotspotArray)[0].hotspotIframe)
      if (JSON.parse(locationDetails[0].hotspotArray)[0].hotspotIframe) {
        setShowIframe(true);
        setIframeUrl(JSON.parse(locationDetails[0].hotspotArray)[0].hotspotIframe);
      }
    } else {
      setShowIframe(false);
      setIframeUrl("");
    }
    var audioUrl = null;
    switch (localStorage.getItem("language")) {
      case 'en':
        if (JSON.parse(locationDetails[0].hotspotArray)[0].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[0].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[0].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[0].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[0].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[0].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[0].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[0].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[0].hotspotDescriptionEnglish);
        }
        break;
      case 'sl':
        if (JSON.parse(locationDetails[0].hotspotArray)[0].hotspotVoiceSinhala) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[0].hotspotVoiceSinhala;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[0].hotspotVoiceSinhala));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[0].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[0].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[0].hotspotTitleSinhala) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[0].hotspotTitleSinhala);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[0].hotspotDescriptionSinhala) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[0].hotspotDescriptionSinhala);
        }
        break;
      case 'tm':
        if (JSON.parse(locationDetails[0].hotspotArray)[0].hotspotVoiceTamil) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[0].hotspotVoiceTamil;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[0].hotspotVoiceTamil));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[0].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[0].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[0].hotspotTitleTamil) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[0].hotspotTitleTamil);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[0].hotspotDescriptionTamil) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[0].hotspotDescriptionTamil);
        }
        break;
      default:
        if (JSON.parse(locationDetails[0].hotspotArray)[0].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[0].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[0].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[0].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[0].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[0].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[0].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[0].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[0].hotspotDescriptionEnglish);
        }
        break;
    }
    setHotspotOpen(true);
  };

  const handleHotspotOneTwoClickOpen = () => {
    setShowImageLoading(true);
    setShowIframe(false);
    setIframeUrl("");
    setShowIframe(false);
    setIframeUrl("");
    setHotspotOpen(false);
    setFullImageOpen(false);
    setHotspotImage('');
    setHostpotDescription('');
    setHostpotTitle('');
    if (!JSON.parse(locationDetails[0].hotspotArray)[1].disableHotspotIframe) {
      console.log(JSON.parse(locationDetails[0].hotspotArray)[1].hotspotIframe)
      if (JSON.parse(locationDetails[0].hotspotArray)[1].hotspotIframe) {
        setShowIframe(true);
        setIframeUrl(JSON.parse(locationDetails[0].hotspotArray)[1].hotspotIframe);
      }
    } else {
      setShowIframe(false);
      setIframeUrl("");
    }
    var audioUrl = null;
    switch (localStorage.getItem("language")) {
      case 'en':
        if (JSON.parse(locationDetails[0].hotspotArray)[1].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[1].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[1].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[1].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[1].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[1].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[1].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[1].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[1].hotspotDescriptionEnglish);
        }
        break;
      case 'sl':
        if (JSON.parse(locationDetails[0].hotspotArray)[1].hotspotVoiceSinhala) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[1].hotspotVoiceSinhala;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[1].hotspotVoiceSinhala));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[1].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[1].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[1].hotspotTitleSinhala) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[1].hotspotTitleSinhala);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[1].hotspotDescriptionSinhala) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[1].hotspotDescriptionSinhala);
        }
        break;
      case 'tm':
        if (JSON.parse(locationDetails[0].hotspotArray)[1].hotspotVoiceTamil) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[1].hotspotVoiceTamil;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[1].hotspotVoiceTamil));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[1].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[1].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[1].hotspotTitleTamil) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[1].hotspotTitleTamil);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[1].hotspotDescriptionTamil) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[1].hotspotDescriptionTamil);
        }
        break;
      default:
        if (JSON.parse(locationDetails[0].hotspotArray)[1].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[1].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[1].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[1].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[1].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[1].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[1].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[1].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[1].hotspotDescriptionEnglish);
        }
        break;
    }
    setHotspotOpen(true);
  };

  const handleHotspotOneThreeClickOpen = () => {
    setShowImageLoading(true);
    setShowIframe(false);
    setIframeUrl("");
    setShowIframe(false);
    setIframeUrl("");
    setHotspotOpen(false);
    setFullImageOpen(false);
    setHotspotImage('');
    setHostpotDescription('');
    setHostpotTitle('');
    if (!JSON.parse(locationDetails[0].hotspotArray)[2].disableHotspotIframe) {
      console.log(JSON.parse(locationDetails[0].hotspotArray)[2].hotspotIframe)
      if (JSON.parse(locationDetails[0].hotspotArray)[2].hotspotIframe) {
        setShowIframe(true);
        setIframeUrl(JSON.parse(locationDetails[0].hotspotArray)[2].hotspotIframe);
      }
    } else {
      setShowIframe(false);
      setIframeUrl("");
    }
    var audioUrl = null;
    switch (localStorage.getItem("language")) {
      case 'en':
        if (JSON.parse(locationDetails[0].hotspotArray)[2].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[2].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[2].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[2].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[2].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[2].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[2].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[2].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[2].hotspotDescriptionEnglish);
        }
        break;
      case 'sl':
        if (JSON.parse(locationDetails[0].hotspotArray)[2].hotspotVoiceSinhala) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[2].hotspotVoiceSinhala;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[2].hotspotVoiceSinhala));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[2].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[2].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[2].hotspotTitleSinhala) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[2].hotspotTitleSinhala);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[2].hotspotDescriptionSinhala) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[2].hotspotDescriptionSinhala);
        }
        break;
      case 'tm':
        if (JSON.parse(locationDetails[0].hotspotArray)[2].hotspotVoiceTamil) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[2].hotspotVoiceTamil;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[2].hotspotVoiceTamil));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[2].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[2].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[2].hotspotTitleTamil) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[2].hotspotTitleTamil);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[2].hotspotDescriptionTamil) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[2].hotspotDescriptionTamil);
        }
        break;
      default:
        if (JSON.parse(locationDetails[0].hotspotArray)[2].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[2].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[2].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[2].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[2].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[2].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[2].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[2].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[2].hotspotDescriptionEnglish);
        }
        break;
    }
    setHotspotOpen(true);
  };

  const handleHotspotOneFourClickOpen = () => {
    setShowImageLoading(true);
    setShowIframe(false);
    setIframeUrl("");
    setShowIframe(false);
    setIframeUrl("");
    setHotspotOpen(false);
    setFullImageOpen(false);
    setHotspotImage('');
    setHostpotDescription('');
    setHostpotTitle('');
    if (!JSON.parse(locationDetails[0].hotspotArray)[3].disableHotspotIframe) {
      console.log(JSON.parse(locationDetails[0].hotspotArray)[3].hotspotIframe)
      if (JSON.parse(locationDetails[0].hotspotArray)[3].hotspotIframe) {
        setShowIframe(true);
        setIframeUrl(JSON.parse(locationDetails[0].hotspotArray)[3].hotspotIframe);
      }
    } else {
      setShowIframe(false);
      setIframeUrl("");
    }
    var audioUrl = null;
    switch (localStorage.getItem("language")) {
      case 'en':
        if (JSON.parse(locationDetails[0].hotspotArray)[3].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[3].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[3].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[3].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[3].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[3].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[3].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[3].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[3].hotspotDescriptionEnglish);
        }
        break;
      case 'sl':
        if (JSON.parse(locationDetails[0].hotspotArray)[3].hotspotVoiceSinhala) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[3].hotspotVoiceSinhala;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[3].hotspotVoiceSinhala));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[3].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[3].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[3].hotspotTitleSinhala) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[3].hotspotTitleSinhala);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[3].hotspotDescriptionSinhala) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[3].hotspotDescriptionSinhala);
        }
        break;
      case 'tm':
        if (JSON.parse(locationDetails[0].hotspotArray)[3].hotspotVoiceTamil) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[3].hotspotVoiceTamil;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[3].hotspotVoiceTamil));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[3].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[3].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[3].hotspotTitleTamil) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[3].hotspotTitleTamil);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[3].hotspotDescriptionTamil) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[3].hotspotDescriptionTamil);
        }
        break;
      default:
        if (JSON.parse(locationDetails[0].hotspotArray)[3].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[3].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[3].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[3].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[3].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[3].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[3].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[3].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[3].hotspotDescriptionEnglish);
        }
        break;
    }
    setHotspotOpen(true);
  };

  const handleHotspotOneFiveClickOpen = () => {
    setShowImageLoading(true);
    setShowIframe(false);
    setIframeUrl("");
    setShowIframe(false);
    setIframeUrl("");
    setHotspotOpen(false);
    setFullImageOpen(false);
    setHotspotImage('');
    setHostpotDescription('');
    setHostpotTitle('');
    if (!JSON.parse(locationDetails[0].hotspotArray)[4].disableHotspotIframe) {
      console.log(JSON.parse(locationDetails[0].hotspotArray)[4].hotspotIframe)
      if (JSON.parse(locationDetails[0].hotspotArray)[4].hotspotIframe) {
        setShowIframe(true);
        setIframeUrl(JSON.parse(locationDetails[0].hotspotArray)[4].hotspotIframe);
      }
    } else {
      setShowIframe(false);
      setIframeUrl("");
    }
    var audioUrl = null;
    switch (localStorage.getItem("language")) {
      case 'en':
        if (JSON.parse(locationDetails[0].hotspotArray)[4].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[4].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[4].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[4].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[4].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[4].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[4].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[4].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[4].hotspotDescriptionEnglish);
        }
        break;
      case 'sl':
        if (JSON.parse(locationDetails[0].hotspotArray)[4].hotspotVoiceSinhala) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[4].hotspotVoiceSinhala;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[4].hotspotVoiceSinhala));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[4].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[4].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[4].hotspotTitleSinhala) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[4].hotspotTitleSinhala);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[4].hotspotDescriptionSinhala) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[4].hotspotDescriptionSinhala);
        }
        break;
      case 'tm':
        if (JSON.parse(locationDetails[0].hotspotArray)[4].hotspotVoiceTamil) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[4].hotspotVoiceTamil;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[4].hotspotVoiceTamil));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[4].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[4].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[4].hotspotTitleTamil) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[4].hotspotTitleTamil);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[4].hotspotDescriptionTamil) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[4].hotspotDescriptionTamil);
        }
        break;
      default:
        if (JSON.parse(locationDetails[0].hotspotArray)[4].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[4].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[4].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[4].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[4].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[4].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[4].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[4].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[4].hotspotDescriptionEnglish);
        }
        break;
    }
    setHotspotOpen(true);
  };

  const handleHotspotOneSixClickOpen = () => {
    setShowImageLoading(true);
    setShowIframe(false);
    setIframeUrl("");
    setShowIframe(false);
    setIframeUrl("");
    setHotspotOpen(false);
    setFullImageOpen(false);
    setHotspotImage('');
    setHostpotDescription('');
    setHostpotTitle('');
    if (!JSON.parse(locationDetails[0].hotspotArray)[5].disableHotspotIframe) {
      console.log(JSON.parse(locationDetails[0].hotspotArray)[5].hotspotIframe)
      if (JSON.parse(locationDetails[0].hotspotArray)[5].hotspotIframe) {
        setShowIframe(true);
        setIframeUrl(JSON.parse(locationDetails[0].hotspotArray)[5].hotspotIframe);
      }
    } else {
      setShowIframe(false);
      setIframeUrl("");
    }
    var audioUrl = null;
    switch (localStorage.getItem("language")) {
      case 'en':
        if (JSON.parse(locationDetails[0].hotspotArray)[5].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[5].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[5].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[5].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[5].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[5].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[5].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[5].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[5].hotspotDescriptionEnglish);
        }
        break;
      case 'sl':
        if (JSON.parse(locationDetails[0].hotspotArray)[5].hotspotVoiceSinhala) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[5].hotspotVoiceSinhala;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[5].hotspotVoiceSinhala));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[5].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[5].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[5].hotspotTitleSinhala) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[5].hotspotTitleSinhala);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[5].hotspotDescriptionSinhala) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[5].hotspotDescriptionSinhala);
        }
        break;
      case 'tm':
        if (JSON.parse(locationDetails[0].hotspotArray)[5].hotspotVoiceTamil) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[5].hotspotVoiceTamil;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[5].hotspotVoiceTamil));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[5].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[5].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[5].hotspotTitleTamil) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[5].hotspotTitleTamil);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[5].hotspotDescriptionTamil) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[5].hotspotDescriptionTamil);
        }
        break;
      default:
        if (JSON.parse(locationDetails[0].hotspotArray)[5].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[5].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[5].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[5].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[5].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[5].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[5].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[5].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[5].hotspotDescriptionEnglish);
        }
        break;
    }
    setHotspotOpen(true);
  };

  const handleHotspotOneSevenClickOpen = () => {
    setShowImageLoading(true);
    setShowIframe(false);
    setIframeUrl("");
    setShowIframe(false);
    setIframeUrl("");
    setHotspotOpen(false);
    setFullImageOpen(false);
    setHotspotImage('');
    setHostpotDescription('');
    setHostpotTitle('');
    if (!JSON.parse(locationDetails[0].hotspotArray)[6].disableHotspotIframe) {
      console.log(JSON.parse(locationDetails[0].hotspotArray)[6].hotspotIframe)
      if (JSON.parse(locationDetails[0].hotspotArray)[6].hotspotIframe) {
        setShowIframe(true);
        setIframeUrl(JSON.parse(locationDetails[0].hotspotArray)[6].hotspotIframe);
      }
    } else {
      setShowIframe(false);
      setIframeUrl("");
    }
    var audioUrl = null;
    switch (localStorage.getItem("language")) {
      case 'en':
        if (JSON.parse(locationDetails[0].hotspotArray)[6].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[6].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[6].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[6].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[6].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[6].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[6].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[6].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[6].hotspotDescriptionEnglish);
        }
        break;
      case 'sl':
        if (JSON.parse(locationDetails[0].hotspotArray)[6].hotspotVoiceSinhala) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[6].hotspotVoiceSinhala;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[6].hotspotVoiceSinhala));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[6].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[6].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[6].hotspotTitleSinhala) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[6].hotspotTitleSinhala);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[6].hotspotDescriptionSinhala) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[6].hotspotDescriptionSinhala);
        }
        break;
      case 'tm':
        if (JSON.parse(locationDetails[0].hotspotArray)[6].hotspotVoiceTamil) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[6].hotspotVoiceTamil;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[6].hotspotVoiceTamil));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[6].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[6].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[6].hotspotTitleTamil) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[6].hotspotTitleTamil);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[6].hotspotDescriptionTamil) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[6].hotspotDescriptionTamil);
        }
        break;
      default:
        if (JSON.parse(locationDetails[0].hotspotArray)[6].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[6].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[6].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[6].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[6].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[6].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[6].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[6].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[6].hotspotDescriptionEnglish);
        }
        break;
    }
    setHotspotOpen(true);
  };

  const handleHotspotOneEightClickOpen = () => {
    setShowImageLoading(true);
    setShowIframe(false);
    setIframeUrl("");
    setShowIframe(false);
    setIframeUrl("");
    setHotspotOpen(false);
    setFullImageOpen(false);
    setHotspotImage('');
    setHostpotDescription('');
    setHostpotTitle('');
    if (!JSON.parse(locationDetails[0].hotspotArray)[7].disableHotspotIframe) {
      console.log(JSON.parse(locationDetails[0].hotspotArray)[7].hotspotIframe)
      if (JSON.parse(locationDetails[0].hotspotArray)[7].hotspotIframe) {
        setShowIframe(true);
        setIframeUrl(JSON.parse(locationDetails[0].hotspotArray)[7].hotspotIframe);
      }
    } else {
      setShowIframe(false);
      setIframeUrl("");
    }
    var audioUrl = null;
    switch (localStorage.getItem("language")) {
      case 'en':
        if (JSON.parse(locationDetails[0].hotspotArray)[7].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[7].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[7].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[7].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[7].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[7].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[7].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[7].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[7].hotspotDescriptionEnglish);
        }
        break;
      case 'sl':
        if (JSON.parse(locationDetails[0].hotspotArray)[7].hotspotVoiceSinhala) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[7].hotspotVoiceSinhala;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[7].hotspotVoiceSinhala));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[7].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[7].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[7].hotspotTitleSinhala) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[7].hotspotTitleSinhala);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[7].hotspotDescriptionSinhala) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[7].hotspotDescriptionSinhala);
        }
        break;
      case 'tm':
        if (JSON.parse(locationDetails[0].hotspotArray)[7].hotspotVoiceTamil) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[7].hotspotVoiceTamil;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[7].hotspotVoiceTamil));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[7].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[7].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[7].hotspotTitleTamil) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[7].hotspotTitleTamil);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[7].hotspotDescriptionTamil) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[7].hotspotDescriptionTamil);
        }
        break;
      default:
        if (JSON.parse(locationDetails[0].hotspotArray)[7].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[7].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[7].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[7].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[7].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[7].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[7].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[7].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[7].hotspotDescriptionEnglish);
        }
        break;
    }
    setHotspotOpen(true);
  };

  const handleHotspotOneNineClickOpen = () => {
    setShowImageLoading(true);
    setShowIframe(false);
    setIframeUrl("");
    setShowIframe(false);
    setIframeUrl("");
    setHotspotOpen(false);
    setFullImageOpen(false);
    setHotspotImage('');
    setHostpotDescription('');
    setHostpotTitle('');
    if (!JSON.parse(locationDetails[0].hotspotArray)[8].disableHotspotIframe) {
      console.log(JSON.parse(locationDetails[0].hotspotArray)[8].hotspotIframe)
      if (JSON.parse(locationDetails[0].hotspotArray)[8].hotspotIframe) {
        setShowIframe(true);
        setIframeUrl(JSON.parse(locationDetails[0].hotspotArray)[8].hotspotIframe);
      }
    } else {
      setShowIframe(false);
      setIframeUrl("");
    }
    var audioUrl = null;
    switch (localStorage.getItem("language")) {
      case 'en':
        if (JSON.parse(locationDetails[0].hotspotArray)[8].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[8].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[8].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[8].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[8].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[8].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[8].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[8].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[8].hotspotDescriptionEnglish);
        }
        break;
      case 'sl':
        if (JSON.parse(locationDetails[0].hotspotArray)[8].hotspotVoiceSinhala) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[8].hotspotVoiceSinhala;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[8].hotspotVoiceSinhala));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[8].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[8].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[8].hotspotTitleSinhala) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[8].hotspotTitleSinhala);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[8].hotspotDescriptionSinhala) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[8].hotspotDescriptionSinhala);
        }
        break;
      case 'tm':
        if (JSON.parse(locationDetails[0].hotspotArray)[8].hotspotVoiceTamil) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[8].hotspotVoiceTamil;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[8].hotspotVoiceTamil));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[8].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[8].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[8].hotspotTitleTamil) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[8].hotspotTitleTamil);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[8].hotspotDescriptionTamil) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[8].hotspotDescriptionTamil);
        }
        break;
      default:
        if (JSON.parse(locationDetails[0].hotspotArray)[8].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[8].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[8].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[8].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[8].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[8].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[8].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[8].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[8].hotspotDescriptionEnglish);
        }
        break;
    }
    setHotspotOpen(true);
  };

  const handleHotspotOneTenClickOpen = () => {
    setShowImageLoading(true);
    setShowIframe(false);
    setIframeUrl("");
    setShowIframe(false);
    setIframeUrl("");
    setHotspotOpen(false);
    setFullImageOpen(false);
    setHotspotImage('');
    setHostpotDescription('');
    setHostpotTitle('');
    if (!JSON.parse(locationDetails[0].hotspotArray)[9].disableHotspotIframe) {
      console.log(JSON.parse(locationDetails[0].hotspotArray)[9].hotspotIframe)
      if (JSON.parse(locationDetails[0].hotspotArray)[9].hotspotIframe) {
        setShowIframe(true);
        setIframeUrl(JSON.parse(locationDetails[0].hotspotArray)[9].hotspotIframe);
      }
    } else {
      setShowIframe(false);
      setIframeUrl("");
    }
    var audioUrl = null;
    switch (localStorage.getItem("language")) {
      case 'en':
        if (JSON.parse(locationDetails[0].hotspotArray)[9].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[9].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[9].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[9].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[9].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[9].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[9].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[9].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[9].hotspotDescriptionEnglish);
        }
        break;
      case 'sl':
        if (JSON.parse(locationDetails[0].hotspotArray)[9].hotspotVoiceSinhala) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[9].hotspotVoiceSinhala;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[9].hotspotVoiceSinhala));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[9].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[9].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[9].hotspotTitleSinhala) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[9].hotspotTitleSinhala);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[9].hotspotDescriptionSinhala) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[9].hotspotDescriptionSinhala);
        }
        break;
      case 'tm':
        if (JSON.parse(locationDetails[0].hotspotArray)[9].hotspotVoiceTamil) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[9].hotspotVoiceTamil;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[9].hotspotVoiceTamil));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[9].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[9].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[9].hotspotTitleTamil) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[9].hotspotTitleTamil);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[9].hotspotDescriptionTamil) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[9].hotspotDescriptionTamil);
        }
        break;
      default:
        if (JSON.parse(locationDetails[0].hotspotArray)[9].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[9].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[9].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[9].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[9].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[9].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[9].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[9].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[9].hotspotDescriptionEnglish);
        }
        break;
    }
    setHotspotOpen(true);
  };

  const handleHotspotOneElevenClickOpen = () => {
    setShowImageLoading(true);
    setShowIframe(false);
    setIframeUrl("");
    setShowIframe(false);
    setIframeUrl("");
    setHotspotOpen(false);
    setFullImageOpen(false);
    setHotspotImage('');
    setHostpotDescription('');
    setHostpotTitle('');
    if (!JSON.parse(locationDetails[0].hotspotArray)[10].disableHotspotIframe) {
      console.log(JSON.parse(locationDetails[0].hotspotArray)[10].hotspotIframe)
      if (JSON.parse(locationDetails[0].hotspotArray)[10].hotspotIframe) {
        setShowIframe(true);
        setIframeUrl(JSON.parse(locationDetails[0].hotspotArray)[10].hotspotIframe);
      }
    } else {
      setShowIframe(false);
      setIframeUrl("");
    }
    var audioUrl = null;
    switch (localStorage.getItem("language")) {
      case 'en':
        if (JSON.parse(locationDetails[0].hotspotArray)[10].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[10].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[10].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[10].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[10].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[10].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[10].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[10].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[10].hotspotDescriptionEnglish);
        }
        break;
      case 'sl':
        if (JSON.parse(locationDetails[0].hotspotArray)[10].hotspotVoiceSinhala) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[10].hotspotVoiceSinhala;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[10].hotspotVoiceSinhala));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[10].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[10].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[10].hotspotTitleSinhala) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[10].hotspotTitleSinhala);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[10].hotspotDescriptionSinhala) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[10].hotspotDescriptionSinhala);
        }
        break;
      case 'tm':
        if (JSON.parse(locationDetails[0].hotspotArray)[10].hotspotVoiceTamil) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[10].hotspotVoiceTamil;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[10].hotspotVoiceTamil));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[10].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[10].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[10].hotspotTitleTamil) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[10].hotspotTitleTamil);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[10].hotspotDescriptionTamil) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[10].hotspotDescriptionTamil);
        }
        break;
      default:
        if (JSON.parse(locationDetails[0].hotspotArray)[10].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[10].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[10].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[10].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[10].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[10].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[10].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[10].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[10].hotspotDescriptionEnglish);
        }
        break;
    }
    setHotspotOpen(true);
  };

  const handleHotspotOneTwelveClickOpen = () => {
    setShowImageLoading(true);
    setShowIframe(false);
    setIframeUrl("");
    setShowIframe(false);
    setIframeUrl("");
    setHotspotOpen(false);
    setFullImageOpen(false);
    setHotspotImage('');
    setHostpotDescription('');
    setHostpotTitle('');
    if (!JSON.parse(locationDetails[0].hotspotArray)[11].disableHotspotIframe) {
      console.log(JSON.parse(locationDetails[0].hotspotArray)[11].hotspotIframe)
      if (JSON.parse(locationDetails[0].hotspotArray)[11].hotspotIframe) {
        setShowIframe(true);
        setIframeUrl(JSON.parse(locationDetails[0].hotspotArray)[11].hotspotIframe);
      }
    } else {
      setShowIframe(false);
      setIframeUrl("");
    }
    var audioUrl = null;
    switch (localStorage.getItem("language")) {
      case 'en':
        if (JSON.parse(locationDetails[0].hotspotArray)[11].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[11].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[11].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[11].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[11].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[11].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[11].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[11].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[11].hotspotDescriptionEnglish);
        }
        break;
      case 'sl':
        if (JSON.parse(locationDetails[0].hotspotArray)[11].hotspotVoiceSinhala) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[11].hotspotVoiceSinhala;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[11].hotspotVoiceSinhala));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[11].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[11].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[11].hotspotTitleSinhala) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[11].hotspotTitleSinhala);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[11].hotspotDescriptionSinhala) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[11].hotspotDescriptionSinhala);
        }
        break;
      case 'tm':
        if (JSON.parse(locationDetails[0].hotspotArray)[11].hotspotVoiceTamil) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[11].hotspotVoiceTamil;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[11].hotspotVoiceTamil));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[11].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[11].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[11].hotspotTitleTamil) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[11].hotspotTitleTamil);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[11].hotspotDescriptionTamil) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[11].hotspotDescriptionTamil);
        }
        break;
      default:
        if (JSON.parse(locationDetails[0].hotspotArray)[11].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[11].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[11].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[11].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[11].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[11].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[11].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[11].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[11].hotspotDescriptionEnglish);
        }
        break;
    }
    setHotspotOpen(true);
  };

  const handleHotspotOneThirteenClickOpen = () => {
    setShowImageLoading(true);
    setShowIframe(false);
    setIframeUrl("");
    setShowIframe(false);
    setIframeUrl("");
    setHotspotOpen(false);
    setFullImageOpen(false);
    setHotspotImage('');
    setHostpotDescription('');
    setHostpotTitle('');
    if (!JSON.parse(locationDetails[0].hotspotArray)[12].disableHotspotIframe) {
      console.log(JSON.parse(locationDetails[0].hotspotArray)[12].hotspotIframe)
      if (JSON.parse(locationDetails[0].hotspotArray)[12].hotspotIframe) {
        setShowIframe(true);
        setIframeUrl(JSON.parse(locationDetails[0].hotspotArray)[12].hotspotIframe);
      }
    } else {
      setShowIframe(false);
      setIframeUrl("");
    }
    var audioUrl = null;
    switch (localStorage.getItem("language")) {
      case 'en':
        if (JSON.parse(locationDetails[0].hotspotArray)[12].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[12].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[12].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[12].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[12].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[12].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[12].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[12].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[12].hotspotDescriptionEnglish);
        }
        break;
      case 'sl':
        if (JSON.parse(locationDetails[0].hotspotArray)[12].hotspotVoiceSinhala) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[12].hotspotVoiceSinhala;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[12].hotspotVoiceSinhala));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[12].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[12].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[12].hotspotTitleSinhala) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[12].hotspotTitleSinhala);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[12].hotspotDescriptionSinhala) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[12].hotspotDescriptionSinhala);
        }
        break;
      case 'tm':
        if (JSON.parse(locationDetails[0].hotspotArray)[12].hotspotVoiceTamil) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[12].hotspotVoiceTamil;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[12].hotspotVoiceTamil));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[12].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[12].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[12].hotspotTitleTamil) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[12].hotspotTitleTamil);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[12].hotspotDescriptionTamil) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[12].hotspotDescriptionTamil);
        }
        break;
      default:
        if (JSON.parse(locationDetails[0].hotspotArray)[12].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[12].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[12].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[12].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[12].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[12].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[12].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[12].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[12].hotspotDescriptionEnglish);
        }
        break;
    }
    setHotspotOpen(true);
  };

  const handleHotspotOneFourteenClickOpen = () => {
    setShowImageLoading(true);
    setShowIframe(false);
    setIframeUrl("");
    setShowIframe(false);
    setIframeUrl("");
    setHotspotOpen(false);
    setFullImageOpen(false);
    setHotspotImage('');
    setHostpotDescription('');
    setHostpotTitle('');
    if (!JSON.parse(locationDetails[0].hotspotArray)[13].disableHotspotIframe) {
      console.log(JSON.parse(locationDetails[0].hotspotArray)[13].hotspotIframe)
      if (JSON.parse(locationDetails[0].hotspotArray)[13].hotspotIframe) {
        setShowIframe(true);
        setIframeUrl(JSON.parse(locationDetails[0].hotspotArray)[13].hotspotIframe);
      }
    } else {
      setShowIframe(false);
      setIframeUrl("");
    }
    var audioUrl = null;
    switch (localStorage.getItem("language")) {
      case 'en':
        if (JSON.parse(locationDetails[0].hotspotArray)[13].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[13].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[13].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[13].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[13].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[13].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[13].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[13].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[13].hotspotDescriptionEnglish);
        }
        break;
      case 'sl':
        if (JSON.parse(locationDetails[0].hotspotArray)[13].hotspotVoiceSinhala) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[13].hotspotVoiceSinhala;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[13].hotspotVoiceSinhala));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[13].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[13].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[13].hotspotTitleSinhala) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[13].hotspotTitleSinhala);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[13].hotspotDescriptionSinhala) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[13].hotspotDescriptionSinhala);
        }
        break;
      case 'tm':
        if (JSON.parse(locationDetails[0].hotspotArray)[13].hotspotVoiceTamil) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[13].hotspotVoiceTamil;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[13].hotspotVoiceTamil));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[13].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[13].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[13].hotspotTitleTamil) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[13].hotspotTitleTamil);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[13].hotspotDescriptionTamil) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[13].hotspotDescriptionTamil);
        }
        break;
      default:
        if (JSON.parse(locationDetails[0].hotspotArray)[13].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[13].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[13].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[13].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[13].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[13].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[13].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[13].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[13].hotspotDescriptionEnglish);
        }
        break;
    }
    setHotspotOpen(true);
  };

  const handleHotspotOneFifteenClickOpen = () => {
    setShowImageLoading(true);
    setShowIframe(false);
    setIframeUrl("");
    setHotspotOpen(false);
    setFullImageOpen(false);
    setHotspotImage('');
    setHostpotDescription('');
    setHostpotTitle('');
    if (!JSON.parse(locationDetails[0].hotspotArray)[14].disableHotspotIframe) {
      console.log(JSON.parse(locationDetails[0].hotspotArray)[14].hotspotIframe)
      if (JSON.parse(locationDetails[0].hotspotArray)[14].hotspotIframe) {
        setShowIframe(true);
        setIframeUrl(JSON.parse(locationDetails[0].hotspotArray)[14].hotspotIframe);
      }
    } else {
      setShowIframe(false);
      setIframeUrl("");
    }
    var audioUrl = null;
    switch (localStorage.getItem("language")) {
      case 'en':
        if (JSON.parse(locationDetails[0].hotspotArray)[14].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[14].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[14].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[14].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[14].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[14].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[14].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[14].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[14].hotspotDescriptionEnglish);
        }
        break;
      case 'sl':
        if (JSON.parse(locationDetails[0].hotspotArray)[14].hotspotVoiceSinhala) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[14].hotspotVoiceSinhala;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[14].hotspotVoiceSinhala));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[14].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[14].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[14].hotspotTitleSinhala) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[14].hotspotTitleSinhala);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[14].hotspotDescriptionSinhala) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[14].hotspotDescriptionSinhala);
        }
        break;
      case 'tm':
        if (JSON.parse(locationDetails[0].hotspotArray)[14].hotspotVoiceTamil) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[14].hotspotVoiceTamil;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[14].hotspotVoiceTamil));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[14].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[14].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[14].hotspotTitleTamil) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[14].hotspotTitleTamil);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[14].hotspotDescriptionTamil) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[14].hotspotDescriptionTamil);
        }
        break;
      default:
        if (JSON.parse(locationDetails[0].hotspotArray)[14].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[14].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[14].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[14].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[14].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[14].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[14].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[14].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[14].hotspotDescriptionEnglish);
        }
        break;
    }
    setHotspotOpen(true);
  };

  const handleHotspotOneSixteenClickOpen = () => {
    setShowImageLoading(true);
    setShowIframe(false);
    setIframeUrl("");
    setHotspotOpen(false);
    setFullImageOpen(false);
    setHotspotImage('');
    setHostpotDescription('');
    setHostpotTitle('');
    if (!JSON.parse(locationDetails[0].hotspotArray)[15].disableHotspotIframe) {
      console.log(JSON.parse(locationDetails[0].hotspotArray)[15].hotspotIframe)
      if (JSON.parse(locationDetails[0].hotspotArray)[15].hotspotIframe) {
        setShowIframe(true);
        setIframeUrl(JSON.parse(locationDetails[0].hotspotArray)[15].hotspotIframe);
      }
    } else {
      setShowIframe(false);
      setIframeUrl("");
    }
    var audioUrl = null;
    switch (localStorage.getItem("language")) {
      case 'en':
        if (JSON.parse(locationDetails[0].hotspotArray)[15].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[15].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[15].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[15].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[15].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[15].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[15].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[15].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[15].hotspotDescriptionEnglish);
        }
        break;
      case 'sl':
        if (JSON.parse(locationDetails[0].hotspotArray)[15].hotspotVoiceSinhala) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[15].hotspotVoiceSinhala;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[15].hotspotVoiceSinhala));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[15].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[15].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[15].hotspotTitleSinhala) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[15].hotspotTitleSinhala);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[15].hotspotDescriptionSinhala) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[15].hotspotDescriptionSinhala);
        }
        break;
      case 'tm':
        if (JSON.parse(locationDetails[0].hotspotArray)[15].hotspotVoiceTamil) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[15].hotspotVoiceTamil;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[15].hotspotVoiceTamil));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[15].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[15].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[15].hotspotTitleTamil) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[15].hotspotTitleTamil);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[15].hotspotDescriptionTamil) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[15].hotspotDescriptionTamil);
        }
        break;
      default:
        if (JSON.parse(locationDetails[0].hotspotArray)[15].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[15].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[15].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[15].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[15].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[15].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[15].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[15].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[15].hotspotDescriptionEnglish);
        }
        break;
    }
    setHotspotOpen(true);
  };

  const handleHotspotOneSeventeenClickOpen = () => {
    setShowImageLoading(true);
    setShowIframe(false);
    setIframeUrl("");
    setHotspotOpen(false);
    setFullImageOpen(false);
    setHotspotImage('');
    setHostpotDescription('');
    setHostpotTitle('');
    if (!JSON.parse(locationDetails[0].hotspotArray)[16].disableHotspotIframe) {
      console.log(JSON.parse(locationDetails[0].hotspotArray)[16].hotspotIframe)
      if (JSON.parse(locationDetails[0].hotspotArray)[16].hotspotIframe) {
        setShowIframe(true);
        setIframeUrl(JSON.parse(locationDetails[0].hotspotArray)[16].hotspotIframe);
      }
    } else {
      setShowIframe(false);
      setIframeUrl("");
    }
    var audioUrl = null;
    switch (localStorage.getItem("language")) {
      case 'en':
        if (JSON.parse(locationDetails[0].hotspotArray)[16].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[16].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[16].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[16].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[16].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[16].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[16].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[16].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[16].hotspotDescriptionEnglish);
        }
        break;
      case 'sl':
        if (JSON.parse(locationDetails[0].hotspotArray)[16].hotspotVoiceSinhala) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[16].hotspotVoiceSinhala;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[16].hotspotVoiceSinhala));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[16].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[16].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[16].hotspotTitleSinhala) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[16].hotspotTitleSinhala);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[16].hotspotDescriptionSinhala) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[16].hotspotDescriptionSinhala);
        }
        break;
      case 'tm':
        if (JSON.parse(locationDetails[0].hotspotArray)[16].hotspotVoiceTamil) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[16].hotspotVoiceTamil;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[16].hotspotVoiceTamil));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[16].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[16].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[16].hotspotTitleTamil) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[16].hotspotTitleTamil);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[16].hotspotDescriptionTamil) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[16].hotspotDescriptionTamil);
        }
        break;
      default:
        if (JSON.parse(locationDetails[0].hotspotArray)[16].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[16].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[16].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[16].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[16].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[16].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[16].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[16].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[16].hotspotDescriptionEnglish);
        }
        break;
    }
    setHotspotOpen(true);
  };

  const handleHotspotOneEighteenClickOpen = () => {
    setShowImageLoading(true);
    setShowIframe(false);
    setIframeUrl("");
    setHotspotOpen(false);
    setFullImageOpen(false);
    setHotspotImage('');
    setHostpotDescription('');
    setHostpotTitle('');
    if (!JSON.parse(locationDetails[0].hotspotArray)[17].disableHotspotIframe) {
      console.log(JSON.parse(locationDetails[0].hotspotArray)[17].hotspotIframe)
      if (JSON.parse(locationDetails[0].hotspotArray)[17].hotspotIframe) {
        setShowIframe(true);
        setIframeUrl(JSON.parse(locationDetails[0].hotspotArray)[17].hotspotIframe);
      }
    } else {
      setShowIframe(false);
      setIframeUrl("");
    }
    var audioUrl = null;
    switch (localStorage.getItem("language")) {
      case 'en':
        if (JSON.parse(locationDetails[0].hotspotArray)[17].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[17].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[17].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[17].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[17].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[17].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[17].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[17].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[17].hotspotDescriptionEnglish);
        }
        break;
      case 'sl':
        if (JSON.parse(locationDetails[0].hotspotArray)[17].hotspotVoiceSinhala) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[17].hotspotVoiceSinhala;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[17].hotspotVoiceSinhala));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[17].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[17].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[17].hotspotTitleSinhala) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[17].hotspotTitleSinhala);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[17].hotspotDescriptionSinhala) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[17].hotspotDescriptionSinhala);
        }
        break;
      case 'tm':
        if (JSON.parse(locationDetails[0].hotspotArray)[17].hotspotVoiceTamil) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[17].hotspotVoiceTamil;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[17].hotspotVoiceTamil));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[17].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[17].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[17].hotspotTitleTamil) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[17].hotspotTitleTamil);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[17].hotspotDescriptionTamil) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[17].hotspotDescriptionTamil);
        }
        break;
      default:
        if (JSON.parse(locationDetails[0].hotspotArray)[17].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[17].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[17].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[17].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[17].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[17].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[17].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[17].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[17].hotspotDescriptionEnglish);
        }
        break;
    }
    setHotspotOpen(true);
  };

  const handleHotspotOneNineteenClickOpen = () => {
    setShowImageLoading(true);
    setShowIframe(false);
    setIframeUrl("");
    setHotspotOpen(false);
    setFullImageOpen(false);
    setHotspotImage('');
    setHostpotDescription('');
    setHostpotTitle('');
    if (!JSON.parse(locationDetails[0].hotspotArray)[18].disableHotspotIframe) {
      console.log(JSON.parse(locationDetails[0].hotspotArray)[18].hotspotIframe)
      if (JSON.parse(locationDetails[0].hotspotArray)[18].hotspotIframe) {
        setShowIframe(true);
        setIframeUrl(JSON.parse(locationDetails[0].hotspotArray)[18].hotspotIframe);
      }
    } else {
      setShowIframe(false);
      setIframeUrl("");
    }
    var audioUrl = null;
    switch (localStorage.getItem("language")) {
      case 'en':
        if (JSON.parse(locationDetails[0].hotspotArray)[18].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[18].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[18].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[18].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[18].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[18].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[18].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[18].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[18].hotspotDescriptionEnglish);
        }
        break;
      case 'sl':
        if (JSON.parse(locationDetails[0].hotspotArray)[18].hotspotVoiceSinhala) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[18].hotspotVoiceSinhala;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[18].hotspotVoiceSinhala));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[18].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[18].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[18].hotspotTitleSinhala) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[18].hotspotTitleSinhala);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[18].hotspotDescriptionSinhala) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[18].hotspotDescriptionSinhala);
        }
        break;
      case 'tm':
        if (JSON.parse(locationDetails[0].hotspotArray)[18].hotspotVoiceTamil) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[18].hotspotVoiceTamil;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[18].hotspotVoiceTamil));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[18].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[18].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[18].hotspotTitleTamil) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[18].hotspotTitleTamil);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[18].hotspotDescriptionTamil) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[18].hotspotDescriptionTamil);
        }
        break;
      default:
        if (JSON.parse(locationDetails[0].hotspotArray)[18].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[18].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[18].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[18].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[18].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[18].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[18].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[18].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[18].hotspotDescriptionEnglish);
        }
        break;
    }
    setHotspotOpen(true);
  };

  const handleHotspotOneTwentyClickOpen = () => {
    setShowImageLoading(true);
    setShowIframe(false);
    setIframeUrl("");
    setHotspotOpen(false);
    setFullImageOpen(false);
    setHotspotImage('');
    setHostpotDescription('');
    setHostpotTitle('');
    if (!JSON.parse(locationDetails[0].hotspotArray)[19].disableHotspotIframe) {
      console.log(JSON.parse(locationDetails[0].hotspotArray)[19].hotspotIframe)
      if (JSON.parse(locationDetails[0].hotspotArray)[19].hotspotIframe) {
        setShowIframe(true);
        setIframeUrl(JSON.parse(locationDetails[0].hotspotArray)[19].hotspotIframe);
      }
    } else {
      setShowIframe(false);
      setIframeUrl("");
    }
    var audioUrl = null;
    switch (localStorage.getItem("language")) {
      case 'en':
        if (JSON.parse(locationDetails[0].hotspotArray)[19].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[19].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[19].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[19].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[19].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[19].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[19].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[19].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[19].hotspotDescriptionEnglish);
        }
        break;
      case 'sl':
        if (JSON.parse(locationDetails[0].hotspotArray)[19].hotspotVoiceSinhala) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[19].hotspotVoiceSinhala;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[19].hotspotVoiceSinhala));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[19].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[19].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[19].hotspotTitleSinhala) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[19].hotspotTitleSinhala);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[19].hotspotDescriptionSinhala) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[19].hotspotDescriptionSinhala);
        }
        break;
      case 'tm':
        if (JSON.parse(locationDetails[0].hotspotArray)[19].hotspotVoiceTamil) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[19].hotspotVoiceTamil;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[19].hotspotVoiceTamil));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[19].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[19].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[19].hotspotTitleTamil) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[19].hotspotTitleTamil);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[19].hotspotDescriptionTamil) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[19].hotspotDescriptionTamil);
        }
        break;
      default:
        if (JSON.parse(locationDetails[0].hotspotArray)[19].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[19].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[19].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[19].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[19].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[19].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[19].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[19].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[19].hotspotDescriptionEnglish);
        }
        break;
    }
    setHotspotOpen(true);
  };

  const handleHotspotOneTwentyOneClickOpen = () => {
    setShowImageLoading(true);
    setShowIframe(false);
    setIframeUrl("");
    setHotspotOpen(false);
    setFullImageOpen(false);
    setHotspotImage('');
    setHostpotDescription('');
    setHostpotTitle('');
    if (!JSON.parse(locationDetails[0].hotspotArray)[20].disableHotspotIframe) {
      console.log(JSON.parse(locationDetails[0].hotspotArray)[20].hotspotIframe)
      if (JSON.parse(locationDetails[0].hotspotArray)[20].hotspotIframe) {
        setShowIframe(true);
        setIframeUrl(JSON.parse(locationDetails[0].hotspotArray)[20].hotspotIframe);
      }
    } else {
      setShowIframe(false);
      setIframeUrl("");
    }
    var audioUrl = null;
    switch (localStorage.getItem("language")) {
      case 'en':
        if (JSON.parse(locationDetails[0].hotspotArray)[20].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[20].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[20].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[20].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[20].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[20].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[20].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[20].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[20].hotspotDescriptionEnglish);
        }
        break;
      case 'sl':
        if (JSON.parse(locationDetails[0].hotspotArray)[20].hotspotVoiceSinhala) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[20].hotspotVoiceSinhala;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[20].hotspotVoiceSinhala));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[20].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[20].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[20].hotspotTitleSinhala) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[20].hotspotTitleSinhala);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[20].hotspotDescriptionSinhala) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[20].hotspotDescriptionSinhala);
        }
        break;
      case 'tm':
        if (JSON.parse(locationDetails[0].hotspotArray)[20].hotspotVoiceTamil) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[20].hotspotVoiceTamil;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[20].hotspotVoiceTamil));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[20].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[20].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[20].hotspotTitleTamil) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[20].hotspotTitleTamil);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[20].hotspotDescriptionTamil) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[20].hotspotDescriptionTamil);
        }
        break;
      default:
        if (JSON.parse(locationDetails[0].hotspotArray)[20].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[20].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[20].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[20].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[20].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[20].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[20].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[20].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[20].hotspotDescriptionEnglish);
        }
        break;
    }
    setHotspotOpen(true);
  };

  const handleHotspotOneTwentyTwoClickOpen = () => {
    setShowImageLoading(true);
    setShowIframe(false);
    setIframeUrl("");
    setHotspotOpen(false);
    setFullImageOpen(false);
    setHotspotImage('');
    setHostpotDescription('');
    setHostpotTitle('');
    if (!JSON.parse(locationDetails[0].hotspotArray)[21].disableHotspotIframe) {
      console.log(JSON.parse(locationDetails[0].hotspotArray)[21].hotspotIframe)
      if (JSON.parse(locationDetails[0].hotspotArray)[21].hotspotIframe) {
        setShowIframe(true);
        setIframeUrl(JSON.parse(locationDetails[0].hotspotArray)[21].hotspotIframe);
      }
    } else {
      setShowIframe(false);
      setIframeUrl("");
    }
    var audioUrl = null;
    switch (localStorage.getItem("language")) {
      case 'en':
        if (JSON.parse(locationDetails[0].hotspotArray)[21].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[21].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[21].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[21].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[21].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[21].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[21].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[21].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[21].hotspotDescriptionEnglish);
        }
        break;
      case 'sl':
        if (JSON.parse(locationDetails[0].hotspotArray)[21].hotspotVoiceSinhala) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[21].hotspotVoiceSinhala;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[21].hotspotVoiceSinhala));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[21].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[21].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[21].hotspotTitleSinhala) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[21].hotspotTitleSinhala);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[21].hotspotDescriptionSinhala) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[21].hotspotDescriptionSinhala);
        }
        break;
      case 'tm':
        if (JSON.parse(locationDetails[0].hotspotArray)[21].hotspotVoiceTamil) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[21].hotspotVoiceTamil;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[21].hotspotVoiceTamil));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[21].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[21].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[21].hotspotTitleTamil) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[21].hotspotTitleTamil);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[21].hotspotDescriptionTamil) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[21].hotspotDescriptionTamil);
        }
        break;
      default:
        if (JSON.parse(locationDetails[0].hotspotArray)[21].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[21].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[21].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[21].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[21].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[21].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[21].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[21].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[21].hotspotDescriptionEnglish);
        }
        break;
    }
    setHotspotOpen(true);
  };

  const handleHotspotOneTwentyThreeClickOpen = () => {
    setShowImageLoading(true);
    setShowIframe(false);
    setIframeUrl("");
    setHotspotOpen(false);
    setFullImageOpen(false);
    setHotspotImage('');
    setHostpotDescription('');
    setHostpotTitle('');
    if (!JSON.parse(locationDetails[0].hotspotArray)[22].disableHotspotIframe) {
      console.log(JSON.parse(locationDetails[0].hotspotArray)[22].hotspotIframe)
      if (JSON.parse(locationDetails[0].hotspotArray)[22].hotspotIframe) {
        setShowIframe(true);
        setIframeUrl(JSON.parse(locationDetails[0].hotspotArray)[22].hotspotIframe);
      }
    } else {
      setShowIframe(false);
      setIframeUrl("");
    }
    var audioUrl = null;
    switch (localStorage.getItem("language")) {
      case 'en':
        if (JSON.parse(locationDetails[0].hotspotArray)[22].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[22].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[22].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[22].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[22].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[22].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[22].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[22].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[22].hotspotDescriptionEnglish);
        }
        break;
      case 'sl':
        if (JSON.parse(locationDetails[0].hotspotArray)[22].hotspotVoiceSinhala) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[22].hotspotVoiceSinhala;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[22].hotspotVoiceSinhala));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[22].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[22].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[22].hotspotTitleSinhala) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[22].hotspotTitleSinhala);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[22].hotspotDescriptionSinhala) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[22].hotspotDescriptionSinhala);
        }
        break;
      case 'tm':
        if (JSON.parse(locationDetails[0].hotspotArray)[22].hotspotVoiceTamil) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[22].hotspotVoiceTamil;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[22].hotspotVoiceTamil));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[22].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[22].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[22].hotspotTitleTamil) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[22].hotspotTitleTamil);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[22].hotspotDescriptionTamil) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[22].hotspotDescriptionTamil);
        }
        break;
      default:
        if (JSON.parse(locationDetails[0].hotspotArray)[22].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[22].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[22].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[22].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[22].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[22].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[22].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[22].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[22].hotspotDescriptionEnglish);
        }
        break;
    }
    setHotspotOpen(true);
  };

  const handleHotspotOneTwentyFourClickOpen = () => {
    setShowImageLoading(true);
    setShowIframe(false);
    setIframeUrl("");
    setHotspotOpen(false);
    setFullImageOpen(false);
    setHotspotImage('');
    setHostpotDescription('');
    setHostpotTitle('');
    if (!JSON.parse(locationDetails[0].hotspotArray)[23].disableHotspotIframe) {
      console.log(JSON.parse(locationDetails[0].hotspotArray)[23].hotspotIframe)
      if (JSON.parse(locationDetails[0].hotspotArray)[23].hotspotIframe) {
        setShowIframe(true);
        setIframeUrl(JSON.parse(locationDetails[0].hotspotArray)[23].hotspotIframe);
      }
    } else {
      setShowIframe(false);
      setIframeUrl("");
    }
    var audioUrl = null;
    switch (localStorage.getItem("language")) {
      case 'en':
        if (JSON.parse(locationDetails[0].hotspotArray)[23].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[23].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[23].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[23].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[23].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[23].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[23].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[23].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[23].hotspotDescriptionEnglish);
        }
        break;
      case 'sl':
        if (JSON.parse(locationDetails[0].hotspotArray)[23].hotspotVoiceSinhala) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[23].hotspotVoiceSinhala;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[23].hotspotVoiceSinhala));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[23].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[23].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[23].hotspotTitleSinhala) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[23].hotspotTitleSinhala);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[23].hotspotDescriptionSinhala) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[23].hotspotDescriptionSinhala);
        }
        break;
      case 'tm':
        if (JSON.parse(locationDetails[0].hotspotArray)[23].hotspotVoiceTamil) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[23].hotspotVoiceTamil;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[23].hotspotVoiceTamil));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[23].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[23].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[23].hotspotTitleTamil) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[23].hotspotTitleTamil);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[23].hotspotDescriptionTamil) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[23].hotspotDescriptionTamil);
        }
        break;
      default:
        if (JSON.parse(locationDetails[0].hotspotArray)[23].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[23].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[23].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[23].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[23].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[23].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[23].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[23].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[23].hotspotDescriptionEnglish);
        }
        break;
    }
    setHotspotOpen(true);
  };

  const handleHotspotOneTwentyFiveClickOpen = () => {
    setShowImageLoading(true);
    setShowIframe(false);
    setIframeUrl("");
    setHotspotOpen(false);
    setFullImageOpen(false);
    setHotspotImage('');
    setHostpotDescription('');
    setHostpotTitle('');
    if (!JSON.parse(locationDetails[0].hotspotArray)[24].disableHotspotIframe) {
      console.log(JSON.parse(locationDetails[0].hotspotArray)[24].hotspotIframe)
      if (JSON.parse(locationDetails[0].hotspotArray)[24].hotspotIframe) {
        setShowIframe(true);
        setIframeUrl(JSON.parse(locationDetails[0].hotspotArray)[24].hotspotIframe);
      }
    } else {
      setShowIframe(false);
      setIframeUrl("");
    }
    var audioUrl = null;
    switch (localStorage.getItem("language")) {
      case 'en':
        if (JSON.parse(locationDetails[0].hotspotArray)[24].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[24].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[24].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[24].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[24].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[24].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[24].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[24].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[24].hotspotDescriptionEnglish);
        }
        break;
      case 'sl':
        if (JSON.parse(locationDetails[0].hotspotArray)[24].hotspotVoiceSinhala) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[24].hotspotVoiceSinhala;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[24].hotspotVoiceSinhala));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[24].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[24].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[24].hotspotTitleSinhala) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[24].hotspotTitleSinhala);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[24].hotspotDescriptionSinhala) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[24].hotspotDescriptionSinhala);
        }
        break;
      case 'tm':
        if (JSON.parse(locationDetails[0].hotspotArray)[24].hotspotVoiceTamil) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[24].hotspotVoiceTamil;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[24].hotspotVoiceTamil));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[24].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[24].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[24].hotspotTitleTamil) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[24].hotspotTitleTamil);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[24].hotspotDescriptionTamil) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[24].hotspotDescriptionTamil);
        }
        break;
      default:
        if (JSON.parse(locationDetails[0].hotspotArray)[24].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[24].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[24].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[24].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[24].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[24].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[24].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[24].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[24].hotspotDescriptionEnglish);
        }
        break;
    }
    setHotspotOpen(true);
  };

  const handleHotspotOneTwentySixClickOpen = () => {
    setShowImageLoading(true);
    setShowIframe(false);
    setIframeUrl("");
    setHotspotOpen(false);
    setFullImageOpen(false);
    setHotspotImage('');
    setHostpotDescription('');
    setHostpotTitle('');
    if (!JSON.parse(locationDetails[0].hotspotArray)[25].disableHotspotIframe) {
      console.log(JSON.parse(locationDetails[0].hotspotArray)[25].hotspotIframe)
      if (JSON.parse(locationDetails[0].hotspotArray)[25].hotspotIframe) {
        setShowIframe(true);
        setIframeUrl(JSON.parse(locationDetails[0].hotspotArray)[25].hotspotIframe);
      }
    } else {
      setShowIframe(false);
      setIframeUrl("");
    }
    var audioUrl = null;
    switch (localStorage.getItem("language")) {
      case 'en':
        if (JSON.parse(locationDetails[0].hotspotArray)[25].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[25].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[25].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[25].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[25].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[25].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[25].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[25].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[25].hotspotDescriptionEnglish);
        }
        break;
      case 'sl':
        if (JSON.parse(locationDetails[0].hotspotArray)[25].hotspotVoiceSinhala) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[25].hotspotVoiceSinhala;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[25].hotspotVoiceSinhala));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[25].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[25].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[25].hotspotTitleSinhala) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[25].hotspotTitleSinhala);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[25].hotspotDescriptionSinhala) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[25].hotspotDescriptionSinhala);
        }
        break;
      case 'tm':
        if (JSON.parse(locationDetails[0].hotspotArray)[25].hotspotVoiceTamil) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[25].hotspotVoiceTamil;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[25].hotspotVoiceTamil));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[25].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[25].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[25].hotspotTitleTamil) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[25].hotspotTitleTamil);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[25].hotspotDescriptionTamil) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[25].hotspotDescriptionTamil);
        }
        break;
      default:
        if (JSON.parse(locationDetails[0].hotspotArray)[25].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[25].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[25].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[25].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[25].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[25].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[25].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[25].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[25].hotspotDescriptionEnglish);
        }
        break;
    }
    setHotspotOpen(true);
  };

  const handleHotspotTwoTwoClickOpen = () => {
    setShowImageLoading(true);
    setShowIframe(false);
    setIframeUrl("");
    setHotspotOpen(false);
    setFullImageOpen(false);
    setHotspotImage('');
    setHostpotDescription('');
    setHostpotTitle('');
    if (!JSON.parse(locationDetails[1].hotspotArray)[1].disableHotspotIframe) {
      console.log(JSON.parse(locationDetails[1].hotspotArray)[1].hotspotIframe)
      if (JSON.parse(locationDetails[1].hotspotArray)[1].hotspotIframe) {
        setShowIframe(true);
        setIframeUrl(JSON.parse(locationDetails[1].hotspotArray)[1].hotspotIframe);
      }
    } else {
      setShowIframe(false);
      setIframeUrl("");
    }
    var audioUrl = null;
    switch (localStorage.getItem("language")) {
      case 'en':
        if (JSON.parse(locationDetails[1].hotspotArray)[1].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[1].hotspotArray)[1].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[1].hotspotArray)[1].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[1].hotspotArray)[1].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[1].hotspotArray)[1].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[1].hotspotArray)[1].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[1].hotspotArray)[1].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[1].hotspotArray)[1].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[1].hotspotArray)[1].hotspotDescriptionEnglish);
        }
        break;
      case 'sl':
        if (JSON.parse(locationDetails[1].hotspotArray)[1].hotspotVoiceSinhala) {
          audioUrl = JSON.parse(locationDetails[1].hotspotArray)[1].hotspotVoiceSinhala;
          setAudio(new Audio(JSON.parse(locationDetails[1].hotspotArray)[1].hotspotVoiceSinhala));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[1].hotspotArray)[1].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[1].hotspotArray)[1].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[1].hotspotArray)[1].hotspotTitleSinhala) {
          setHostpotTitle(JSON.parse(locationDetails[1].hotspotArray)[1].hotspotTitleSinhala);
        }
        if (JSON.parse(locationDetails[1].hotspotArray)[1].hotspotDescriptionSinhala) {
          setHostpotDescription(JSON.parse(locationDetails[1].hotspotArray)[1].hotspotDescriptionSinhala);
        }
        break;
      case 'tm':
        if (JSON.parse(locationDetails[1].hotspotArray)[1].hotspotVoiceTamil) {
          audioUrl = JSON.parse(locationDetails[1].hotspotArray)[1].hotspotVoiceTamil;
          setAudio(new Audio(JSON.parse(locationDetails[1].hotspotArray)[1].hotspotVoiceTamil));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[1].hotspotArray)[1].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[1].hotspotArray)[1].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[1].hotspotArray)[1].hotspotTitleTamil) {
          setHostpotTitle(JSON.parse(locationDetails[1].hotspotArray)[1].hotspotTitleTamil);
        }
        if (JSON.parse(locationDetails[1].hotspotArray)[1].hotspotDescriptionTamil) {
          setHostpotDescription(JSON.parse(locationDetails[1].hotspotArray)[1].hotspotDescriptionTamil);
        }
        break;
      default:
        if (JSON.parse(locationDetails[1].hotspotArray)[1].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[1].hotspotArray)[1].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[1].hotspotArray)[1].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[1].hotspotArray)[1].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[1].hotspotArray)[1].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[1].hotspotArray)[1].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[1].hotspotArray)[1].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[1].hotspotArray)[1].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[1].hotspotArray)[1].hotspotDescriptionEnglish);
        }
        break;
    }
    setHotspotOpen(true);
  };

  const handleHotspotTwoThreeClickOpen = () => {
    setShowImageLoading(true);
    setShowIframe(false);
    setIframeUrl("");
    setHotspotOpen(false);
    setFullImageOpen(false);
    setHotspotImage('');
    setHostpotDescription('');
    setHostpotTitle('');
    if (!JSON.parse(locationDetails[1].hotspotArray)[2].disableHotspotIframe) {
      console.log(JSON.parse(locationDetails[1].hotspotArray)[2].hotspotIframe)
      if (JSON.parse(locationDetails[1].hotspotArray)[2].hotspotIframe) {
        setShowIframe(true);
        setIframeUrl(JSON.parse(locationDetails[1].hotspotArray)[2].hotspotIframe);
      }
    } else {
      setShowIframe(false);
      setIframeUrl("");
    }
    var audioUrl = null;
    switch (localStorage.getItem("language")) {
      case 'en':
        if (JSON.parse(locationDetails[1].hotspotArray)[2].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[1].hotspotArray)[2].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[1].hotspotArray)[2].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[1].hotspotArray)[2].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[1].hotspotArray)[2].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[1].hotspotArray)[2].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[1].hotspotArray)[2].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[1].hotspotArray)[2].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[1].hotspotArray)[2].hotspotDescriptionEnglish);
        }
        break;
      case 'sl':
        if (JSON.parse(locationDetails[1].hotspotArray)[2].hotspotVoiceSinhala) {
          audioUrl = JSON.parse(locationDetails[1].hotspotArray)[2].hotspotVoiceSinhala;
          setAudio(new Audio(JSON.parse(locationDetails[1].hotspotArray)[2].hotspotVoiceSinhala));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[1].hotspotArray)[2].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[1].hotspotArray)[2].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[1].hotspotArray)[2].hotspotTitleSinhala) {
          setHostpotTitle(JSON.parse(locationDetails[1].hotspotArray)[2].hotspotTitleSinhala);
        }
        if (JSON.parse(locationDetails[1].hotspotArray)[2].hotspotDescriptionSinhala) {
          setHostpotDescription(JSON.parse(locationDetails[1].hotspotArray)[2].hotspotDescriptionSinhala);
        }
        break;
      case 'tm':
        if (JSON.parse(locationDetails[1].hotspotArray)[2].hotspotVoiceTamil) {
          audioUrl = JSON.parse(locationDetails[1].hotspotArray)[2].hotspotVoiceTamil;
          setAudio(new Audio(JSON.parse(locationDetails[1].hotspotArray)[2].hotspotVoiceTamil));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[1].hotspotArray)[2].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[1].hotspotArray)[2].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[1].hotspotArray)[2].hotspotTitleTamil) {
          setHostpotTitle(JSON.parse(locationDetails[1].hotspotArray)[2].hotspotTitleTamil);
        }
        if (JSON.parse(locationDetails[1].hotspotArray)[2].hotspotDescriptionTamil) {
          setHostpotDescription(JSON.parse(locationDetails[1].hotspotArray)[2].hotspotDescriptionTamil);
        }
        break;
      default:
        if (JSON.parse(locationDetails[1].hotspotArray)[2].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[1].hotspotArray)[2].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[1].hotspotArray)[2].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[1].hotspotArray)[2].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[1].hotspotArray)[2].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[1].hotspotArray)[2].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[1].hotspotArray)[2].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[1].hotspotArray)[2].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[1].hotspotArray)[2].hotspotDescriptionEnglish);
        }
        break;
    }
    setHotspotOpen(true);
  };

  const handleHotspotTwoFourClickOpen = () => {
    setShowImageLoading(true);
    setShowIframe(false);
    setIframeUrl("");
    setHotspotOpen(false);
    setFullImageOpen(false);
    setHotspotImage('');
    setHostpotDescription('');
    setHostpotTitle('');
    if (!JSON.parse(locationDetails[1].hotspotArray)[3].disableHotspotIframe) {
      console.log(JSON.parse(locationDetails[1].hotspotArray)[3].hotspotIframe)
      if (JSON.parse(locationDetails[1].hotspotArray)[3].hotspotIframe) {
        setShowIframe(true);
        setIframeUrl(JSON.parse(locationDetails[1].hotspotArray)[3].hotspotIframe);
      }
    } else {
      setShowIframe(false);
      setIframeUrl("");
    }
    var audioUrl = null;
    switch (localStorage.getItem("language")) {
      case 'en':
        if (JSON.parse(locationDetails[1].hotspotArray)[3].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[1].hotspotArray)[3].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[1].hotspotArray)[3].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[1].hotspotArray)[3].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[1].hotspotArray)[3].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[1].hotspotArray)[3].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[1].hotspotArray)[3].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[1].hotspotArray)[3].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[1].hotspotArray)[3].hotspotDescriptionEnglish);
        }
        break;
      case 'sl':
        if (JSON.parse(locationDetails[1].hotspotArray)[3].hotspotVoiceSinhala) {
          audioUrl = JSON.parse(locationDetails[1].hotspotArray)[3].hotspotVoiceSinhala;
          setAudio(new Audio(JSON.parse(locationDetails[1].hotspotArray)[3].hotspotVoiceSinhala));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[1].hotspotArray)[3].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[1].hotspotArray)[3].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[1].hotspotArray)[3].hotspotTitleSinhala) {
          setHostpotTitle(JSON.parse(locationDetails[1].hotspotArray)[3].hotspotTitleSinhala);
        }
        if (JSON.parse(locationDetails[1].hotspotArray)[3].hotspotDescriptionSinhala) {
          setHostpotDescription(JSON.parse(locationDetails[1].hotspotArray)[3].hotspotDescriptionSinhala);
        }
        break;
      case 'tm':
        if (JSON.parse(locationDetails[1].hotspotArray)[3].hotspotVoiceTamil) {
          audioUrl = JSON.parse(locationDetails[1].hotspotArray)[3].hotspotVoiceTamil;
          setAudio(new Audio(JSON.parse(locationDetails[1].hotspotArray)[3].hotspotVoiceTamil));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[1].hotspotArray)[3].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[1].hotspotArray)[3].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[1].hotspotArray)[3].hotspotTitleTamil) {
          setHostpotTitle(JSON.parse(locationDetails[1].hotspotArray)[3].hotspotTitleTamil);
        }
        if (JSON.parse(locationDetails[1].hotspotArray)[3].hotspotDescriptionTamil) {
          setHostpotDescription(JSON.parse(locationDetails[1].hotspotArray)[3].hotspotDescriptionTamil);
        }
        break;
      default:
        if (JSON.parse(locationDetails[1].hotspotArray)[3].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[1].hotspotArray)[3].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[1].hotspotArray)[3].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[1].hotspotArray)[3].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[1].hotspotArray)[3].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[1].hotspotArray)[3].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[1].hotspotArray)[3].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[1].hotspotArray)[3].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[1].hotspotArray)[3].hotspotDescriptionEnglish);
        }
        break;
    }
    setHotspotOpen(true);
  };

  const handleHotspotThreeOneClickOpen = () => {
    setShowImageLoading(true);
    setShowIframe(false);
    setIframeUrl("");
    setHotspotOpen(false);
    setFullImageOpen(false);
    setHotspotImage('');
    setHostpotDescription('');
    setHostpotTitle('');
    if (!JSON.parse(locationDetails[2].hotspotArray)[0].disableHotspotIframe) {
      console.log(JSON.parse(locationDetails[2].hotspotArray)[0].hotspotIframe)
      if (JSON.parse(locationDetails[2].hotspotArray)[0].hotspotIframe) {
        setShowIframe(true);
        setIframeUrl(JSON.parse(locationDetails[2].hotspotArray)[0].hotspotIframe);
      }
    } else {
      setShowIframe(false);
      setIframeUrl("");
    }
    var audioUrl = null;
    switch (localStorage.getItem("language")) {
      case 'en':
        if (JSON.parse(locationDetails[2].hotspotArray)[0].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[2].hotspotArray)[0].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[2].hotspotArray)[0].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[2].hotspotArray)[0].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[2].hotspotArray)[0].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[0].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[2].hotspotArray)[0].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[0].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[2].hotspotArray)[0].hotspotDescriptionEnglish);
        }
        break;
      case 'sl':
        if (JSON.parse(locationDetails[2].hotspotArray)[0].hotspotVoiceSinhala) {
          audioUrl = JSON.parse(locationDetails[2].hotspotArray)[0].hotspotVoiceSinhala;
          setAudio(new Audio(JSON.parse(locationDetails[2].hotspotArray)[0].hotspotVoiceSinhala));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[2].hotspotArray)[0].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[2].hotspotArray)[0].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[0].hotspotTitleSinhala) {
          setHostpotTitle(JSON.parse(locationDetails[2].hotspotArray)[0].hotspotTitleSinhala);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[0].hotspotDescriptionSinhala) {
          setHostpotDescription(JSON.parse(locationDetails[2].hotspotArray)[0].hotspotDescriptionSinhala);
        }
        break;
      case 'tm':
        if (JSON.parse(locationDetails[2].hotspotArray)[0].hotspotVoiceTamil) {
          audioUrl = JSON.parse(locationDetails[2].hotspotArray)[0].hotspotVoiceTamil;
          setAudio(new Audio(JSON.parse(locationDetails[2].hotspotArray)[0].hotspotVoiceTamil));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[2].hotspotArray)[0].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[2].hotspotArray)[0].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[0].hotspotTitleTamil) {
          setHostpotTitle(JSON.parse(locationDetails[2].hotspotArray)[0].hotspotTitleTamil);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[0].hotspotDescriptionTamil) {
          setHostpotDescription(JSON.parse(locationDetails[2].hotspotArray)[0].hotspotDescriptionTamil);
        }
        break;
      default:
        if (JSON.parse(locationDetails[2].hotspotArray)[0].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[2].hotspotArray)[0].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[2].hotspotArray)[0].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[2].hotspotArray)[0].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[2].hotspotArray)[0].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[0].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[2].hotspotArray)[0].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[0].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[2].hotspotArray)[0].hotspotDescriptionEnglish);
        }
        break;
    }
    setHotspotOpen(true);
  };

  const handleHotspotThreeTwoClickOpen = () => {
    setShowImageLoading(true);
    setShowIframe(false);
    setIframeUrl("");
    setHotspotOpen(false);
    setFullImageOpen(false);
    setHotspotImage('');
    setHostpotDescription('');
    setHostpotTitle('');
    if (!JSON.parse(locationDetails[2].hotspotArray)[1].disableHotspotIframe) {
      console.log(JSON.parse(locationDetails[2].hotspotArray)[1].hotspotIframe)
      if (JSON.parse(locationDetails[2].hotspotArray)[1].hotspotIframe) {
        setShowIframe(true);
        setIframeUrl(JSON.parse(locationDetails[2].hotspotArray)[1].hotspotIframe);
      }
    } else {
      setShowIframe(false);
      setIframeUrl("");
    }
    var audioUrl = null;
    switch (localStorage.getItem("language")) {
      case 'en':
        if (JSON.parse(locationDetails[2].hotspotArray)[1].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[2].hotspotArray)[1].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[2].hotspotArray)[1].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[2].hotspotArray)[1].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[2].hotspotArray)[1].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[1].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[2].hotspotArray)[1].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[1].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[2].hotspotArray)[1].hotspotDescriptionEnglish);
        }
        break;
      case 'sl':
        if (JSON.parse(locationDetails[2].hotspotArray)[1].hotspotVoiceSinhala) {
          audioUrl = JSON.parse(locationDetails[2].hotspotArray)[1].hotspotVoiceSinhala;
          setAudio(new Audio(JSON.parse(locationDetails[2].hotspotArray)[1].hotspotVoiceSinhala));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[2].hotspotArray)[1].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[2].hotspotArray)[1].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[1].hotspotTitleSinhala) {
          setHostpotTitle(JSON.parse(locationDetails[2].hotspotArray)[1].hotspotTitleSinhala);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[1].hotspotDescriptionSinhala) {
          setHostpotDescription(JSON.parse(locationDetails[2].hotspotArray)[1].hotspotDescriptionSinhala);
        }
        break;
      case 'tm':
        if (JSON.parse(locationDetails[2].hotspotArray)[1].hotspotVoiceTamil) {
          audioUrl = JSON.parse(locationDetails[2].hotspotArray)[1].hotspotVoiceTamil;
          setAudio(new Audio(JSON.parse(locationDetails[2].hotspotArray)[1].hotspotVoiceTamil));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[2].hotspotArray)[1].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[2].hotspotArray)[1].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[1].hotspotTitleTamil) {
          setHostpotTitle(JSON.parse(locationDetails[2].hotspotArray)[1].hotspotTitleTamil);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[1].hotspotDescriptionTamil) {
          setHostpotDescription(JSON.parse(locationDetails[2].hotspotArray)[1].hotspotDescriptionTamil);
        }
        break;
      default:
        if (JSON.parse(locationDetails[2].hotspotArray)[1].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[2].hotspotArray)[1].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[2].hotspotArray)[1].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[2].hotspotArray)[1].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[2].hotspotArray)[1].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[1].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[2].hotspotArray)[1].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[1].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[2].hotspotArray)[1].hotspotDescriptionEnglish);
        }
        break;
    }
    setHotspotOpen(true);
  };

  const handleHotspotThreeThreeClickOpen = () => {
    setShowImageLoading(true);
    setShowIframe(false);
    setIframeUrl("");
    setHotspotOpen(false);
    setFullImageOpen(false);
    setHotspotImage('');
    setHostpotDescription('');
    setHostpotTitle('');
    if (!JSON.parse(locationDetails[2].hotspotArray)[2].disableHotspotIframe) {
      console.log(JSON.parse(locationDetails[2].hotspotArray)[2].hotspotIframe)
      if (JSON.parse(locationDetails[2].hotspotArray)[2].hotspotIframe) {
        setShowIframe(true);
        setIframeUrl(JSON.parse(locationDetails[2].hotspotArray)[2].hotspotIframe);
      }
    } else {
      setShowIframe(false);
      setIframeUrl("");
    }
    var audioUrl = null;
    switch (localStorage.getItem("language")) {
      case 'en':
        if (JSON.parse(locationDetails[2].hotspotArray)[2].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[2].hotspotArray)[2].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[2].hotspotArray)[2].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[2].hotspotArray)[2].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[2].hotspotArray)[2].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[2].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[2].hotspotArray)[2].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[2].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[2].hotspotArray)[2].hotspotDescriptionEnglish);
        }
        break;
      case 'sl':
        if (JSON.parse(locationDetails[2].hotspotArray)[2].hotspotVoiceSinhala) {
          audioUrl = JSON.parse(locationDetails[2].hotspotArray)[2].hotspotVoiceSinhala;
          setAudio(new Audio(JSON.parse(locationDetails[2].hotspotArray)[2].hotspotVoiceSinhala));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[2].hotspotArray)[2].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[2].hotspotArray)[2].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[2].hotspotTitleSinhala) {
          setHostpotTitle(JSON.parse(locationDetails[2].hotspotArray)[2].hotspotTitleSinhala);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[2].hotspotDescriptionSinhala) {
          setHostpotDescription(JSON.parse(locationDetails[2].hotspotArray)[2].hotspotDescriptionSinhala);
        }
        break;
      case 'tm':
        if (JSON.parse(locationDetails[2].hotspotArray)[2].hotspotVoiceTamil) {
          audioUrl = JSON.parse(locationDetails[2].hotspotArray)[2].hotspotVoiceTamil;
          setAudio(new Audio(JSON.parse(locationDetails[2].hotspotArray)[2].hotspotVoiceTamil));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[2].hotspotArray)[2].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[2].hotspotArray)[2].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[2].hotspotTitleTamil) {
          setHostpotTitle(JSON.parse(locationDetails[2].hotspotArray)[2].hotspotTitleTamil);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[2].hotspotDescriptionTamil) {
          setHostpotDescription(JSON.parse(locationDetails[2].hotspotArray)[2].hotspotDescriptionTamil);
        }
        break;
      default:
        if (JSON.parse(locationDetails[2].hotspotArray)[2].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[2].hotspotArray)[2].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[2].hotspotArray)[2].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[2].hotspotArray)[2].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[2].hotspotArray)[2].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[2].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[2].hotspotArray)[2].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[2].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[2].hotspotArray)[2].hotspotDescriptionEnglish);
        }
        break;
    }
    setHotspotOpen(true);
  };

  const handleHotspotThreeFourClickOpen = () => {
    setShowImageLoading(true);
    setShowIframe(false);
    setIframeUrl("");
    setHotspotOpen(false);
    setFullImageOpen(false);
    setHotspotImage('');
    setHostpotDescription('');
    setHostpotTitle('');
    if (!JSON.parse(locationDetails[2].hotspotArray)[3].disableHotspotIframe) {
      console.log(JSON.parse(locationDetails[2].hotspotArray)[3].hotspotIframe)
      if (JSON.parse(locationDetails[2].hotspotArray)[3].hotspotIframe) {
        setShowIframe(true);
        setIframeUrl(JSON.parse(locationDetails[2].hotspotArray)[3].hotspotIframe);
      }
    } else {
      setShowIframe(false);
      setIframeUrl("");
    }
    var audioUrl = null;
    switch (localStorage.getItem("language")) {
      case 'en':
        if (JSON.parse(locationDetails[2].hotspotArray)[3].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[2].hotspotArray)[3].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[2].hotspotArray)[3].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[2].hotspotArray)[3].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[2].hotspotArray)[3].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[3].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[2].hotspotArray)[3].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[3].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[2].hotspotArray)[3].hotspotDescriptionEnglish);
        }
        break;
      case 'sl':
        if (JSON.parse(locationDetails[2].hotspotArray)[3].hotspotVoiceSinhala) {
          audioUrl = JSON.parse(locationDetails[2].hotspotArray)[3].hotspotVoiceSinhala;
          setAudio(new Audio(JSON.parse(locationDetails[2].hotspotArray)[3].hotspotVoiceSinhala));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[2].hotspotArray)[3].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[2].hotspotArray)[3].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[3].hotspotTitleSinhala) {
          setHostpotTitle(JSON.parse(locationDetails[2].hotspotArray)[3].hotspotTitleSinhala);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[3].hotspotDescriptionSinhala) {
          setHostpotDescription(JSON.parse(locationDetails[2].hotspotArray)[3].hotspotDescriptionSinhala);
        }
        break;
      case 'tm':
        if (JSON.parse(locationDetails[2].hotspotArray)[3].hotspotVoiceTamil) {
          audioUrl = JSON.parse(locationDetails[2].hotspotArray)[3].hotspotVoiceTamil;
          setAudio(new Audio(JSON.parse(locationDetails[2].hotspotArray)[3].hotspotVoiceTamil));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[2].hotspotArray)[3].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[2].hotspotArray)[3].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[3].hotspotTitleTamil) {
          setHostpotTitle(JSON.parse(locationDetails[2].hotspotArray)[3].hotspotTitleTamil);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[3].hotspotDescriptionTamil) {
          setHostpotDescription(JSON.parse(locationDetails[2].hotspotArray)[3].hotspotDescriptionTamil);
        }
        break;
      default:
        if (JSON.parse(locationDetails[2].hotspotArray)[3].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[2].hotspotArray)[3].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[2].hotspotArray)[3].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[2].hotspotArray)[3].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[2].hotspotArray)[3].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[3].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[2].hotspotArray)[3].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[3].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[2].hotspotArray)[3].hotspotDescriptionEnglish);
        }
        break;
    }
    setHotspotOpen(true);
  };

  const handleHotspotThreeFiveClickOpen = () => {
    setShowImageLoading(true);
    setShowIframe(false);
    setIframeUrl("");
    setHotspotOpen(false);
    setFullImageOpen(false);
    setHotspotImage('');
    setHostpotDescription('');
    setHostpotTitle('');
    if (!JSON.parse(locationDetails[2].hotspotArray)[4].disableHotspotIframe) {
      console.log(JSON.parse(locationDetails[2].hotspotArray)[4].hotspotIframe)
      if (JSON.parse(locationDetails[2].hotspotArray)[4].hotspotIframe) {
        setShowIframe(true);
        setIframeUrl(JSON.parse(locationDetails[2].hotspotArray)[4].hotspotIframe);
      }
    } else {
      setShowIframe(false);
      setIframeUrl("");
    }
    var audioUrl = null;
    switch (localStorage.getItem("language")) {
      case 'en':
        if (JSON.parse(locationDetails[2].hotspotArray)[4].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[2].hotspotArray)[4].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[2].hotspotArray)[4].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[2].hotspotArray)[4].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[2].hotspotArray)[4].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[4].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[2].hotspotArray)[4].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[4].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[2].hotspotArray)[4].hotspotDescriptionEnglish);
        }
        break;
      case 'sl':
        if (JSON.parse(locationDetails[2].hotspotArray)[4].hotspotVoiceSinhala) {
          audioUrl = JSON.parse(locationDetails[2].hotspotArray)[4].hotspotVoiceSinhala;
          setAudio(new Audio(JSON.parse(locationDetails[2].hotspotArray)[4].hotspotVoiceSinhala));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[2].hotspotArray)[4].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[2].hotspotArray)[4].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[4].hotspotTitleSinhala) {
          setHostpotTitle(JSON.parse(locationDetails[2].hotspotArray)[4].hotspotTitleSinhala);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[4].hotspotDescriptionSinhala) {
          setHostpotDescription(JSON.parse(locationDetails[2].hotspotArray)[4].hotspotDescriptionSinhala);
        }
        break;
      case 'tm':
        if (JSON.parse(locationDetails[2].hotspotArray)[4].hotspotVoiceTamil) {
          audioUrl = JSON.parse(locationDetails[2].hotspotArray)[4].hotspotVoiceTamil;
          setAudio(new Audio(JSON.parse(locationDetails[2].hotspotArray)[4].hotspotVoiceTamil));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[2].hotspotArray)[4].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[2].hotspotArray)[4].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[4].hotspotTitleTamil) {
          setHostpotTitle(JSON.parse(locationDetails[2].hotspotArray)[4].hotspotTitleTamil);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[4].hotspotDescriptionTamil) {
          setHostpotDescription(JSON.parse(locationDetails[2].hotspotArray)[4].hotspotDescriptionTamil);
        }
        break;
      default:
        if (JSON.parse(locationDetails[2].hotspotArray)[4].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[2].hotspotArray)[4].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[2].hotspotArray)[4].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[2].hotspotArray)[4].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[2].hotspotArray)[4].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[4].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[2].hotspotArray)[4].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[4].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[2].hotspotArray)[4].hotspotDescriptionEnglish);
        }
        break;
    }
    setHotspotOpen(true);
  };

  const handleHotspotThreeSixClickOpen = () => {
    setShowImageLoading(true);
    setShowIframe(false);
    setIframeUrl("");
    setHotspotOpen(false);
    setFullImageOpen(false);
    setHotspotImage('');
    setHostpotDescription('');
    setHostpotTitle('');
    if (!JSON.parse(locationDetails[2].hotspotArray)[5].disableHotspotIframe) {
      console.log(JSON.parse(locationDetails[2].hotspotArray)[5].hotspotIframe)
      if (JSON.parse(locationDetails[2].hotspotArray)[5].hotspotIframe) {
        setShowIframe(true);
        setIframeUrl(JSON.parse(locationDetails[2].hotspotArray)[5].hotspotIframe);
      }
    } else {
      setShowIframe(false);
      setIframeUrl("");
    }
    var audioUrl = null;
    switch (localStorage.getItem("language")) {
      case 'en':
        if (JSON.parse(locationDetails[2].hotspotArray)[5].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[2].hotspotArray)[5].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[2].hotspotArray)[5].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[2].hotspotArray)[5].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[2].hotspotArray)[5].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[5].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[2].hotspotArray)[5].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[5].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[2].hotspotArray)[5].hotspotDescriptionEnglish);
        }
        break;
      case 'sl':
        if (JSON.parse(locationDetails[2].hotspotArray)[5].hotspotVoiceSinhala) {
          audioUrl = JSON.parse(locationDetails[2].hotspotArray)[5].hotspotVoiceSinhala;
          setAudio(new Audio(JSON.parse(locationDetails[2].hotspotArray)[5].hotspotVoiceSinhala));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[2].hotspotArray)[5].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[2].hotspotArray)[5].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[5].hotspotTitleSinhala) {
          setHostpotTitle(JSON.parse(locationDetails[2].hotspotArray)[5].hotspotTitleSinhala);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[5].hotspotDescriptionSinhala) {
          setHostpotDescription(JSON.parse(locationDetails[2].hotspotArray)[5].hotspotDescriptionSinhala);
        }
        break;
      case 'tm':
        if (JSON.parse(locationDetails[2].hotspotArray)[5].hotspotVoiceTamil) {
          audioUrl = JSON.parse(locationDetails[2].hotspotArray)[5].hotspotVoiceTamil;
          setAudio(new Audio(JSON.parse(locationDetails[2].hotspotArray)[5].hotspotVoiceTamil));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[2].hotspotArray)[5].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[2].hotspotArray)[5].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[5].hotspotTitleTamil) {
          setHostpotTitle(JSON.parse(locationDetails[2].hotspotArray)[5].hotspotTitleTamil);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[5].hotspotDescriptionTamil) {
          setHostpotDescription(JSON.parse(locationDetails[2].hotspotArray)[5].hotspotDescriptionTamil);
        }
        break;
      default:
        if (JSON.parse(locationDetails[2].hotspotArray)[5].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[2].hotspotArray)[5].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[2].hotspotArray)[5].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[2].hotspotArray)[5].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[2].hotspotArray)[5].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[5].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[2].hotspotArray)[5].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[5].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[2].hotspotArray)[5].hotspotDescriptionEnglish);
        }
        break;
    }
    setHotspotOpen(true);
  };

  const handleHotspotThreeSevenClickOpen = () => {
    setShowImageLoading(true);
    setShowIframe(false);
    setIframeUrl("");
    setHotspotOpen(false);
    setFullImageOpen(false);
    setHotspotImage('');
    setHostpotDescription('');
    setHostpotTitle('');
    if (!JSON.parse(locationDetails[2].hotspotArray)[6].disableHotspotIframe) {
      console.log(JSON.parse(locationDetails[2].hotspotArray)[6].hotspotIframe)
      if (JSON.parse(locationDetails[2].hotspotArray)[6].hotspotIframe) {
        setShowIframe(true);
        setIframeUrl(JSON.parse(locationDetails[2].hotspotArray)[6].hotspotIframe);
      }
    } else {
      setShowIframe(false);
      setIframeUrl("");
    }
    var audioUrl = null;
    switch (localStorage.getItem("language")) {
      case 'en':
        if (JSON.parse(locationDetails[2].hotspotArray)[6].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[2].hotspotArray)[6].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[2].hotspotArray)[6].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[2].hotspotArray)[6].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[2].hotspotArray)[6].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[6].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[2].hotspotArray)[6].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[6].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[2].hotspotArray)[6].hotspotDescriptionEnglish);
        }
        break;
      case 'sl':
        if (JSON.parse(locationDetails[2].hotspotArray)[6].hotspotVoiceSinhala) {
          audioUrl = JSON.parse(locationDetails[2].hotspotArray)[6].hotspotVoiceSinhala;
          setAudio(new Audio(JSON.parse(locationDetails[2].hotspotArray)[6].hotspotVoiceSinhala));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[2].hotspotArray)[6].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[2].hotspotArray)[6].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[6].hotspotTitleSinhala) {
          setHostpotTitle(JSON.parse(locationDetails[2].hotspotArray)[6].hotspotTitleSinhala);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[6].hotspotDescriptionSinhala) {
          setHostpotDescription(JSON.parse(locationDetails[2].hotspotArray)[6].hotspotDescriptionSinhala);
        }
        break;
      case 'tm':
        if (JSON.parse(locationDetails[2].hotspotArray)[6].hotspotVoiceTamil) {
          audioUrl = JSON.parse(locationDetails[2].hotspotArray)[6].hotspotVoiceTamil;
          setAudio(new Audio(JSON.parse(locationDetails[2].hotspotArray)[6].hotspotVoiceTamil));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[2].hotspotArray)[6].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[2].hotspotArray)[6].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[6].hotspotTitleTamil) {
          setHostpotTitle(JSON.parse(locationDetails[2].hotspotArray)[6].hotspotTitleTamil);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[6].hotspotDescriptionTamil) {
          setHostpotDescription(JSON.parse(locationDetails[2].hotspotArray)[6].hotspotDescriptionTamil);
        }
        break;
      default:
        if (JSON.parse(locationDetails[2].hotspotArray)[6].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[2].hotspotArray)[6].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[2].hotspotArray)[6].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[2].hotspotArray)[6].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[2].hotspotArray)[6].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[6].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[2].hotspotArray)[6].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[6].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[2].hotspotArray)[6].hotspotDescriptionEnglish);
        }
        break;
    }
    setHotspotOpen(true);
  };

  const handleHotspotThreeEightClickOpen = () => {
    setShowImageLoading(true);
    setShowIframe(false);
    setIframeUrl("");
    setHotspotOpen(false);
    setFullImageOpen(false);
    setHotspotImage('');
    setHostpotDescription('');
    setHostpotTitle('');
    if (!JSON.parse(locationDetails[2].hotspotArray)[7].disableHotspotIframe) {
      console.log(JSON.parse(locationDetails[2].hotspotArray)[7].hotspotIframe)
      if (JSON.parse(locationDetails[2].hotspotArray)[7].hotspotIframe) {
        setShowIframe(true);
        setIframeUrl(JSON.parse(locationDetails[2].hotspotArray)[7].hotspotIframe);
      }
    } else {
      setShowIframe(false);
      setIframeUrl("");
    }
    var audioUrl = null;
    switch (localStorage.getItem("language")) {
      case 'en':
        if (JSON.parse(locationDetails[2].hotspotArray)[7].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[2].hotspotArray)[7].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[2].hotspotArray)[7].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[2].hotspotArray)[7].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[2].hotspotArray)[7].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[7].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[2].hotspotArray)[7].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[7].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[2].hotspotArray)[7].hotspotDescriptionEnglish);
        }
        break;
      case 'sl':
        if (JSON.parse(locationDetails[2].hotspotArray)[7].hotspotVoiceSinhala) {
          audioUrl = JSON.parse(locationDetails[2].hotspotArray)[7].hotspotVoiceSinhala;
          setAudio(new Audio(JSON.parse(locationDetails[2].hotspotArray)[7].hotspotVoiceSinhala));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[2].hotspotArray)[7].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[2].hotspotArray)[7].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[7].hotspotTitleSinhala) {
          setHostpotTitle(JSON.parse(locationDetails[2].hotspotArray)[7].hotspotTitleSinhala);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[7].hotspotDescriptionSinhala) {
          setHostpotDescription(JSON.parse(locationDetails[2].hotspotArray)[7].hotspotDescriptionSinhala);
        }
        break;
      case 'tm':
        if (JSON.parse(locationDetails[2].hotspotArray)[7].hotspotVoiceTamil) {
          audioUrl = JSON.parse(locationDetails[2].hotspotArray)[7].hotspotVoiceTamil;
          setAudio(new Audio(JSON.parse(locationDetails[2].hotspotArray)[7].hotspotVoiceTamil));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[2].hotspotArray)[7].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[2].hotspotArray)[7].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[7].hotspotTitleTamil) {
          setHostpotTitle(JSON.parse(locationDetails[2].hotspotArray)[7].hotspotTitleTamil);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[7].hotspotDescriptionTamil) {
          setHostpotDescription(JSON.parse(locationDetails[2].hotspotArray)[7].hotspotDescriptionTamil);
        }
        break;
      default:
        if (JSON.parse(locationDetails[2].hotspotArray)[7].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[2].hotspotArray)[7].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[2].hotspotArray)[7].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[2].hotspotArray)[7].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[2].hotspotArray)[7].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[7].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[2].hotspotArray)[7].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[7].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[2].hotspotArray)[7].hotspotDescriptionEnglish);
        }
        break;
    }
    setHotspotOpen(true);
  };

  const handleAudioPlay = () => {
    audio.play();
    setPlayAudio(true);
  };

  const pauseAudioPlay = () => {
    audio.pause();
    setPlayAudio(false);
  };

  audio.onended = function () {
    setPlayAudio(false);
  };

  const handleHotspotClose = () => {
    setHotspotOpen(false);
    setFullImageOpen(false);
    setShowAudio(false);
    setPlayAudio(false);
    audio.pause();
  };

  const handleFullImageOpen = () => {
    setFullImageOpen(true);
    audio.pause();
    setPlayAudio(false);
  };

  const handleFullImageClose = () => {
    setFullImageOpen(false);
  };

  const goHome = () => {
    setAnchorEl(null);
    navigate("/");
  };

  const [isEnter, setIsEnter] = useState(false);

  const navigateToSilverDoor = () => {
    navigate("/silver-door");
  };

  const navigateToPortraitCollection = () => {
    navigate("/portrait-collection");
  };

  const [isDrag, setIsDrag] = React.useState(false);

  const onDrag = () => {
    setIsDrag(true);
  };

  const onDragLeave = () => {
    setIsDrag(false);
  };

  const handleTutorialOpen = () => {
    setAnchorEl(null);
    setOpen(false);
    setHotspotOpen(false);
    setFullImageOpen(false);
    setShowAudio(false);
    setPlayAudio(false);
    audio.pause();
    setTutorialOpen(true);
    setVariable('Open');
  };

  const handleTutorialClose = () => {
    setTutorialOpen(false);
  };

  const endSliderClick = () => {
    setAnchorEl(null);
    setTutorialOpen(false);
    setOpen(false);
    setHotspotOpen(false);
    setFullImageOpen(false);
    setShowAudio(false);
    setPlayAudio(false);
    audio.pause();
    setVariable('Close');
  };

  const handlePresentaionViewClose = () => {
    setAnchorEl(null);
    setTutorialOpen(false);
    setOpen(false);
    setHotspotOpen(false);
    setFullImageOpen(false);
    setShowAudio(false);
    setPlayAudio(false);
    setPresentationOpen(false);
  };

  const handlePresentaionViewOpen = () => {
    setAnchorEl(null);
    setTutorialOpen(false);
    setOpen(false);
    setHotspotOpen(false);
    setFullImageOpen(false);
    setShowAudio(false);
    setPlayAudio(false);
    setPresentationOpen(true);
  };

  const handleSnackBarOpen = () => {
    setShowSnackBar(true);
  };

  const handleSnackBarClose = () => {
    setShowSnackBar(false);
  };

  return (
    <Fullscreen isEnter={isEnter} onChange={setIsEnter}>
      <div className="page-container">
        <Invisible />
        {isLoading && (<Loading />)}
        <div
          className={[
            "all-locations-popup-container",
            open ? "dialog-open" : "dialog-close",
          ].join(" ")}
        >
          <IconButton className="all-locations-close-icon" onClick={handleClose}>
            <CancelIcon />
          </IconButton>
          <AllLocations />
        </div>

        <div
          className={[
            "full-info-dialog",
            fullImageOpen ? "dialog-open" : "dialog-close",
          ].join(" ")}
        >
          <IconButton className="close-full-icon" onClick={handleFullImageClose}>
            <CancelIcon />
          </IconButton>
          <div className="full-dialog-container">
            <img className="full-dialog-image" src={hotspotImage} alt="" />
          </div>
        </div>

        <Snackbar open={showSnackBar} autoHideDuration={6000} onClose={handleSnackBarClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={handleSnackBarClose} severity="warning" sx={{ width: '100%' }}>
            Please close the information pop up to resume navigation
          </Alert>
        </Snackbar>

        <div
          className={[
            "info-dialog-popup-container",
            hotspotOpen ? "dialog-open" : "dialog-close",
          ].join(" ")}
        >
          <div onClick={handleSnackBarOpen}
            className={[
              hotspotOpen ? "modal-bg" : "",
            ].join(" ")}
          >
          </div>
          <IconButton className="info-close-icon" onClick={handleHotspotClose}>
            <CancelIcon />
          </IconButton>
          <div className={[
            "info-dialog",
            hotspotText ? "info-dialog-long" : "",
          ].join(" ")}>
            {showIframe && (
              <iframe className="mb-2" src={iframeUrl}></iframe>
            )}
            {hotspotImage && showImageLoading && (
              <section className="skeleton-container">
                <Skeleton className={[
                  "img-skeleton",
                  showAudio ? "" : "img-skeleton-no-audio",
                ].join(" ")} variant="rectangular" sx={{ bgcolor: '#606060', borderRadius: 5, marginBottom: 3 }} />
              </section>
            )}
            {hotspotImage && !showImageLoading && (
              <img className="info-dialog-img info-dialog-img-sm" src={hotspotImage} alt="" onClick={handleFullImageOpen} />
            )}
            <div className={[
              "info-content-row",
              hotspotText ? "info-content-row-margin" : "",
            ].join(" ")}>
              {!showAudio && (
                <div className="flex-row-center">
                  <span className="info-view-text">{hotspotTitle}</span>
                </div>
              )}
              {showAudio && (
                <section>
                  {!playAudio && (
                    <div className="flex-row-center">
                      <span className="info-view-text">{hotspotTitle}</span>
                      <div className="audio-icon-container" onClick={handleAudioPlay}>
                        <div className="audio-img"></div>
                      </div>
                    </div>
                  )}
                  {playAudio && (
                    <div className="audio-container" onClick={pauseAudioPlay}>
                      <ul className="audio-div">
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                      </ul>
                      <div className="audio-icon-container" onClick={handleAudioPlay}>
                        <div className="audio-pause-img"></div>
                      </div>
                    </div>
                  )}
                </section>
              )}
              {hotspotText && (
                <section>
                  <div className="info-desc-container">
                    <span
                      className={[
                        "info-desc",
                        seeMore ? "info-desc-expand" : "",
                        seeMore ? "fade-in" : "",
                      ].join(" ")}
                    >
                      {hotspotText}
                    </span>
                  </div>
                  {!seeMore && (
                    <div className="see-more-less-container" onClick={seeMoreClick}>
                      <span>View More</span>
                      <ExpandMoreIcon />
                    </div>
                  )}
                  {seeMore && (
                    <div className="see-more-less-container" onClick={seeLessClick}>
                      <span>View Less</span>
                      <ExpandLessIcon />
                    </div>
                  )}
                </section>
              )}
            </div>
          </div>
        </div>

        <div
          className={[
            "tutorial-modal-container",
            tutorialOpen ? "dialog-open" : "dialog-close",
          ].join(" ")}
        >
          <div className="tutorial-popup">
            <div className="tutorial-popup-container">
              <TutorialSlider onSliderEndClick={endSliderClick} variable={variable} />
            </div>
          </div>
        </div>

        <div
          className={[
            "presentation-popup-container",
            presentationOpen ? "dialog-open" : "dialog-close",
          ].join(" ")}
        >
          <div className="presentation-popup">
            <div className="tutorial-popup-container">
              <div className="presentation-view-container">
                <span className="presentation-popup-text">Switching to presentation view will load images in high resolution.</span>
                <span className="presentation-popup-text">Do you want to continue ?</span>
                <div className="presentation-view-btn-row">
                  <Button
                    className="presentation-btn-yes"
                    variant="contained"
                    onClick={handlePresentaionViewClose}
                  >
                    Yes
                  </Button>
                  <Button
                    className="presentation-btn-no"
                    variant="contained"
                    onClick={handlePresentaionViewClose}
                  >
                    No
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <IconButton className="mob-menu-btn" onClick={handleMenuOpen}>
          <MenuIcon />
        </IconButton>
        <Menu
          className="menu-container"
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={handleMenuClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={goHome}>
            <div className="home-icon"></div>
          </MenuItem>
          <MenuItem onClick={handleTutorialOpen}>
            <div className="tutorial-icon"></div>
          </MenuItem>
        </Menu>

        <div
          className={[
            "pannellum-image",
            imageIndex == 1 ? "pannellum-image-fade-in" : "pannellum-image-fade-out",
          ].join(" ")}
        >
          <Pannellum
            width="100%"
            height="100vh"
            image={imageOneSrc}
            pitch={-5}
            yaw={360}
            hfov={hfov}
            autoLoad
            showZoomCtrl={false}
            showFullscreenCtrl={false}
            onLoad={() => { onImageLoad() }}
            onMousedown={(evt) => {
              onDrag();
            }}
            onMouseup={(evt) => {
              onDragLeave();
            }}
            onTouchstart={(evt) => {
              onDrag();
            }}
            onTouchend={(evt) => {
              onDragLeave();
            }}
          >
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot"
              pitch={9.8}
              yaw={363}
              handleClick={(evt, name) => handleHotspotOneOneClickOpen()}
              name="hs01"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot-two"
              pitch={-46.5}
              yaw={180}
              handleClick={(evt, name) => handleHotspotOneTwoClickOpen()}
              name="hs02"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot-three"
              pitch={3}
              yaw={120}
              handleClick={(evt, name) => handleHotspotOneThreeClickOpen()}
              name="hs03"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot-four"
              pitch={4.5}
              yaw={240}
              handleClick={(evt, name) => handleHotspotOneFourClickOpen()}
              name="hs04"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot-five"
              pitch={-15}
              yaw={141}
              handleClick={(evt, name) => handleHotspotOneFiveClickOpen()}
              name="hs05"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot-six"
              pitch={-15}
              yaw={220}
              handleClick={(evt, name) => handleHotspotOneSixClickOpen()}
              name="hs06"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot-seven"
              pitch={-10}
              yaw={330}
              handleClick={(evt, name) => handleHotspotOneSevenClickOpen()}
              name="hs07"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot-eight"
              pitch={-8}
              yaw={385}
              handleClick={(evt, name) => handleHotspotOneEightClickOpen()}
              name="hs08"
            />

            {/* Right side flags */}
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot-nine"
              pitch={20.5}
              yaw={381}
              handleClick={(evt, name) => handleHotspotOneNineClickOpen()}
              name="hs09"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot-ten"
              pitch={20.5}
              yaw={398}
              handleClick={(evt, name) => handleHotspotOneTenClickOpen()}
              name="hs10"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot-eleven"
              pitch={22.4}
              yaw={403.4}
              handleClick={(evt, name) => handleHotspotOneElevenClickOpen()}
              name="hs11"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot-twelve"
              pitch={26}
              yaw={410.6}
              handleClick={(evt, name) => handleHotspotOneTwelveClickOpen()}
              name="hs12"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot-thirteen"
              pitch={29}
              yaw={420}
              handleClick={(evt, name) => handleHotspotOneThirteenClickOpen()}
              name="hs13"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot-fourteen"
              pitch={31.8}
              yaw={432}
              handleClick={(evt, name) => handleHotspotOneFourteenClickOpen()}
              name="hs14"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot-fifteen"
              pitch={32.3}
              yaw={446}
              handleClick={(evt, name) => handleHotspotOneFifteenClickOpen()}
              name="hs15"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot-sixteen"
              pitch={32.3}
              yaw={461}
              handleClick={(evt, name) => handleHotspotOneSixteenClickOpen()}
              name="hs16"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot-seventeen"
              pitch={44.5}
              yaw={487}
              handleClick={(evt, name) => handleHotspotOneSeventeenClickOpen()}
              name="hs17"
            />

            {/* Left Side Flags */}
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot-eighteen"
              pitch={20.5}
              yaw={345}
              handleClick={(evt, name) => handleHotspotOneEighteenClickOpen()}
              name="hs18"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot-nineteen"
              pitch={20.8}
              yaw={327.5}
              handleClick={(evt, name) => handleHotspotOneNineteenClickOpen()}
              name="hs19"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot-twenty"
              pitch={24.2}
              yaw={322}
              handleClick={(evt, name) => handleHotspotOneTwentyClickOpen()}
              name="hs20"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot-twentyone"
              pitch={26.5}
              yaw={314.5}
              handleClick={(evt, name) => handleHotspotOneTwentyOneClickOpen()}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot-twentytwo"
              pitch={30.2}
              yaw={305.2}
              handleClick={(evt, name) => handleHotspotOneTwentyTwoClickOpen()}
              name="hs22"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot-twentythree"
              pitch={33.6}
              yaw={293}
              handleClick={(evt, name) => handleHotspotOneTwentyThreeClickOpen()}
              name="hs23"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot-twentyfour"
              pitch={34.5}
              yaw={278}
              handleClick={(evt, name) => handleHotspotOneTwentyFourClickOpen()}
              name="hs24"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot-twentyfive"
              pitch={35}
              yaw={262}
              handleClick={(evt, name) => handleHotspotOneTwentyFiveClickOpen()}
              name="hs25"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot-twentysix"
              pitch={46.2}
              yaw={235}
              handleClick={(evt, name) => handleHotspotOneTwentySixClickOpen()}
              name="hs26"
            />
          </Pannellum>
        </div>
        <div
          className={[
            "pannellum-image",
            imageIndex == 2 ? "pannellum-image-fade-in" : "pannellum-image-fade-out",
          ].join(" ")}
        >
          <Pannellum ref={panImage}
            width="100%"
            height="100vh"
            image={imageTwoSrc}
            pitch={-5}
            yaw={180}
            hfov={hfov}
            autoLoad
            showZoomCtrl={false}
            showFullscreenCtrl={false}
            onLoad={() => { onImageLoad() }}
            onMousedown={(evt) => {
              onDrag();
            }}
            onMouseup={(evt) => {
              onDragLeave();
            }}
            onTouchstart={(evt) => {
              onDrag();
            }}
            onTouchend={(evt) => {
              onDragLeave();
            }}
          >
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot-two"
              pitch={-6}
              yaw={200}
              handleClick={(evt, name) => handleHotspotTwoTwoClickOpen()}
              name="hs02"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot-three"
              pitch={-7}
              yaw={165}
              handleClick={(evt, name) => handleHotspotTwoThreeClickOpen()}
              name="hs03"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot-four"
              pitch={15}
              yaw={183}
              handleClick={(evt, name) => handleHotspotTwoFourClickOpen()}
              name="hs03"
            />
            {/* Right side first row */}
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot"
              pitch={-3.6}
              yaw={206}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot"
              pitch={-4}
              yaw={208.5}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot"
              pitch={-4.3}
              yaw={211}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot"
              pitch={-4.6}
              yaw={214}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot"
              pitch={-6}
              yaw={222}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot"
              pitch={-6.4}
              yaw={226}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot"
              pitch={-7}
              yaw={231}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot"
              pitch={-7.8}
              yaw={238}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot"
              pitch={-9}
              yaw={256}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot"
              pitch={-9.1}
              yaw={266}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot"
              pitch={-9.1}
              yaw={277}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot"
              pitch={-8.8}
              yaw={288}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot"
              pitch={-8}
              yaw={305}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot"
              pitch={-7}
              yaw={312}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot"
              pitch={-6.2}
              yaw={318}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot"
              pitch={-5.6}
              yaw={323}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot"
              pitch={-4.4}
              yaw={330}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot"
              pitch={-4}
              yaw={333}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot"
              pitch={-3.7}
              yaw={335}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot"
              pitch={-3.4}
              yaw={337}
              name="hs21"
            />

            {/* Right side second row */}
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-two-p-hidden"
              pitch={0.5}
              yaw={210}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-two-p-hidden"
              pitch={0.3}
              yaw={213}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-two-p-hidden"
              pitch={0.1}
              yaw={216}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-two-p-hidden"
              pitch={-0.3}
              yaw={219}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-two-p-hidden"
              pitch={-0.6}
              yaw={224}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-two-p-hidden"
              pitch={-0.6}
              yaw={230}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-two-p-hidden"
              pitch={-0.6}
              yaw={236}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-two-p-hidden"
              pitch={-0.5}
              yaw={246}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-two-p-hidden"
              pitch={-0.1}
              yaw={260}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-two-p-hidden"
              pitch={-0.15}
              yaw={270}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-two-p-hidden"
              pitch={-0.15}
              yaw={278}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-two-p-hidden"
              pitch={-0.15}
              yaw={285}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-two-p-hidden"
              pitch={-0.1}
              yaw={300}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-two-p-hidden"
              pitch={-0.15}
              yaw={308}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-two-p-hidden"
              pitch={-0.15}
              yaw={314}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-two-p-hidden"
              pitch={-0.15}
              yaw={320}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-two-p-hidden"
              pitch={-0.15}
              yaw={328}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-two-p-hidden"
              pitch={-0.15}
              yaw={331}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-two-p-hidden"
              pitch={-0.15}
              yaw={333}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-two-p-hidden"
              pitch={-0.15}
              yaw={335}
              name="hs21"
            />

            {/* Right side third row */}
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-three-p-hidden"
              pitch={2.3}
              yaw={218}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-three-p-hidden"
              pitch={2.3}
              yaw={221}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-three-p-hidden"
              pitch={2.3}
              yaw={224}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-three-p-hidden"
              pitch={2.1}
              yaw={227}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-three-p-hidden"
              pitch={2.3}
              yaw={234}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-three-p-hidden"
              pitch={2.3}
              yaw={238}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-three-p-hidden"
              pitch={2.3}
              yaw={244}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-three-p-hidden"
              pitch={2.3}
              yaw={250}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-three-p-hidden"
              pitch={2.5}
              yaw={262}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-three-p-hidden"
              pitch={2.4}
              yaw={270}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-three-p-hidden"
              pitch={2.4}
              yaw={278}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-three-p-hidden"
              pitch={2.4}
              yaw={285}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-three-p-hidden"
              pitch={2.5}
              yaw={296}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-three-p-hidden"
              pitch={2.5}
              yaw={303}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-three-p-hidden"
              pitch={2.5}
              yaw={308}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-three-p-hidden"
              pitch={2.5}
              yaw={312}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-three-p-hidden"
              pitch={2.4}
              yaw={318}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-three-p-hidden"
              pitch={2.4}
              yaw={321}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-three-p-hidden"
              pitch={2.4}
              yaw={324}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-three-p-hidden"
              pitch={2.4}
              yaw={327}
              name="hs21"
            />

            {/* Right side fourth row */}
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-four-p-hidden"
              pitch={4.1}
              yaw={224}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-four-p-hidden"
              pitch={4.1}
              yaw={227}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-four-p-hidden"
              pitch={4.1}
              yaw={230}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-four-p-hidden"
              pitch={4.1}
              yaw={233}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-four-p-hidden"
              pitch={4.3}
              yaw={240}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-four-p-hidden"
              pitch={4.3}
              yaw={243}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-four-p-hidden"
              pitch={4.3}
              yaw={248}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-four-p-hidden"
              pitch={4.3}
              yaw={254}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-four-p-hidden"
              pitch={4.5}
              yaw={264}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-four-p-hidden"
              pitch={4.5}
              yaw={270}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-four-p-hidden"
              pitch={4.5}
              yaw={276}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-four-p-hidden"
              pitch={4.5}
              yaw={282}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-four-p-hidden"
              pitch={4.6}
              yaw={292}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-four-p-hidden"
              pitch={4.6}
              yaw={298}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-four-p-hidden"
              pitch={4.6}
              yaw={302}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-four-p-hidden"
              pitch={4.5}
              yaw={306}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-four-p-hidden"
              pitch={4.3}
              yaw={310}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-four-p-hidden"
              pitch={4.2}
              yaw={313}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-four-p-hidden"
              pitch={4.2}
              yaw={316}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-four-p-hidden"
              pitch={4.2}
              yaw={318}
              name="hs21"
            />

            {/* Right side FIFTH row */}
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-five-p-hidden"
              pitch={5.7}
              yaw={227}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-five-p-hidden"
              pitch={5.7}
              yaw={230}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-five-p-hidden"
              pitch={5.7}
              yaw={233}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-five-p-hidden"
              pitch={5.7}
              yaw={236}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-five-p-hidden"
              pitch={5.8}
              yaw={244}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-five-p-hidden"
              pitch={5.8}
              yaw={248}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-five-p-hidden"
              pitch={5.8}
              yaw={252}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-five-p-hidden"
              pitch={5.8}
              yaw={258}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-five-p-hidden"
              pitch={6.2}
              yaw={265}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-five-p-hidden"
              pitch={6.2}
              yaw={270}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-five-p-hidden"
              pitch={6.2}
              yaw={276}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-five-p-hidden"
              pitch={6.2}
              yaw={280}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-five-p-hidden"
              pitch={6.2}
              yaw={286}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-five-p-hidden"
              pitch={6.2}
              yaw={292}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-five-p-hidden"
              pitch={6.2}
              yaw={298}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-five-p-hidden"
              pitch={6.2}
              yaw={302}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-five-p-hidden"
              pitch={6.2}
              yaw={306}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-five-p-hidden"
              pitch={6.1}
              yaw={309}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-five-p-hidden"
              pitch={5.9}
              yaw={312}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-five-p-hidden"
              pitch={5.8}
              yaw={314}
              name="hs21"
            />


            {/* Left side First row */}
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot"
              pitch={-3.3}
              yaw={-202}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot"
              pitch={-3.65}
              yaw={-204}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot"
              pitch={-4}
              yaw={-206}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot"
              pitch={-4.4}
              yaw={-209}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot"
              pitch={-6}
              yaw={-216}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot"
              pitch={-7}
              yaw={-221}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot"
              pitch={-7.8}
              yaw={-226}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot"
              pitch={-8.7}
              yaw={-233}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot"
              pitch={-8.8}
              yaw={-250}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot"
              pitch={-9.2}
              yaw={-262}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot"
              pitch={-9.2}
              yaw={-273}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot"
              pitch={-9}
              yaw={-284}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot"
              pitch={-8.2}
              yaw={-302}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot"
              pitch={-7.4}
              yaw={-309}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot"
              pitch={-6.6}
              yaw={-315}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot"
              pitch={-6}
              yaw={-320}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot"
              pitch={-5}
              yaw={-327}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot"
              pitch={-4.5}
              yaw={-330}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot"
              pitch={-4.2}
              yaw={-332}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot"
              pitch={-4}
              yaw={-334}
              name="hs21"
            />

            {/* Left side Second row */}
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-two-p-hidden"
              pitch={0.5}
              yaw={-206}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-two-p-hidden"
              pitch={0.3}
              yaw={-209}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-two-p-hidden"
              pitch={0.1}
              yaw={-212}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-two-p-hidden"
              pitch={-0.1}
              yaw={-215}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-two-p-hidden"
              pitch={-0.2}
              yaw={-222}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-two-p-hidden"
              pitch={-0.2}
              yaw={-226}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-two-p-hidden"
              pitch={-0.2}
              yaw={-230}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-two-p-hidden"
              pitch={-0.2}
              yaw={-236}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-two-p-hidden"
              pitch={-0.2}
              yaw={-254}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-two-p-hidden"
              pitch={-0.2}
              yaw={-264}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-two-p-hidden"
              pitch={-0.2}
              yaw={-272}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-two-p-hidden"
              pitch={-0.2}
              yaw={-282}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-two-p-hidden"
              pitch={-0.2}
              yaw={-294}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-two-p-hidden"
              pitch={-0.2}
              yaw={-300}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-two-p-hidden"
              pitch={-0.2}
              yaw={-306}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-two-p-hidden"
              pitch={-0.2}
              yaw={-312}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-two-p-hidden"
              pitch={-0.2}
              yaw={-320}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-two-p-hidden"
              pitch={-0.2}
              yaw={-324}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-two-p-hidden"
              pitch={-0.2}
              yaw={-327}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-two-p-hidden"
              pitch={-0.2}
              yaw={-330}
              name="hs21"
            />

            {/* Left side Third row */}
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-three-p-hidden"
              pitch={2}
              yaw={-214}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-three-p-hidden"
              pitch={2}
              yaw={-218}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-three-p-hidden"
              pitch={2}
              yaw={-222}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-three-p-hidden"
              pitch={2}
              yaw={-226}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-three-p-hidden"
              pitch={2}
              yaw={-232}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-three-p-hidden"
              pitch={2}
              yaw={-236}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-three-p-hidden"
              pitch={2}
              yaw={-240}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-three-p-hidden"
              pitch={2}
              yaw={-246}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-three-p-hidden"
              pitch={2.4}
              yaw={-258}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-three-p-hidden"
              pitch={2.4}
              yaw={-264}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-three-p-hidden"
              pitch={2.4}
              yaw={-272}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-three-p-hidden"
              pitch={2.4}
              yaw={-280}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-three-p-hidden"
              pitch={2.4}
              yaw={-288}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-three-p-hidden"
              pitch={2.4}
              yaw={-294}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-three-p-hidden"
              pitch={2.4}
              yaw={-298}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-three-p-hidden"
              pitch={2.4}
              yaw={-302}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-three-p-hidden"
              pitch={2.4}
              yaw={-312}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-three-p-hidden"
              pitch={2.4}
              yaw={-314}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-three-p-hidden"
              pitch={2.4}
              yaw={-316}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-three-p-hidden"
              pitch={2.4}
              yaw={-320}
              name="hs21"
            />

            {/* Left side fourth row */}
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-four-p-hidden"
              pitch={4}
              yaw={-220}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-four-p-hidden"
              pitch={4}
              yaw={-224}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-four-p-hidden"
              pitch={4}
              yaw={-228}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-four-p-hidden"
              pitch={4}
              yaw={-230}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-four-p-hidden"
              pitch={4}
              yaw={-236}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-four-p-hidden"
              pitch={4.1}
              yaw={-240}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-four-p-hidden"
              pitch={4.2}
              yaw={-244}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-four-p-hidden"
              pitch={4.2}
              yaw={-248}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-four-p-hidden"
              pitch={4.6}
              yaw={-260}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-four-p-hidden"
              pitch={4.6}
              yaw={-264}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-four-p-hidden"
              pitch={4.6}
              yaw={-272}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-four-p-hidden"
              pitch={4.6}
              yaw={-278}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-four-p-hidden"
              pitch={4.6}
              yaw={-285}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-four-p-hidden"
              pitch={4.6}
              yaw={-290}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-four-p-hidden"
              pitch={4.6}
              yaw={-294}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-four-p-hidden"
              pitch={4.6}
              yaw={-298}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-four-p-hidden"
              pitch={4.2}
              yaw={-306}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-four-p-hidden"
              pitch={4.2}
              yaw={-308}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-four-p-hidden"
              pitch={4.2}
              yaw={-310}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-four-p-hidden"
              pitch={4.2}
              yaw={-312}
              name="hs21"
            />

            {/* Left side fifth row */}
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-five-p-hidden"
              pitch={5.8}
              yaw={-226}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-five-p-hidden"
              pitch={5.8}
              yaw={-228}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-five-p-hidden"
              pitch={5.8}
              yaw={-230}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-five-p-hidden"
              pitch={5.8}
              yaw={-232}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-five-p-hidden"
              pitch={6}
              yaw={-240}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-five-p-hidden"
              pitch={6}
              yaw={-244}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-five-p-hidden"
              pitch={6}
              yaw={-248}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-five-p-hidden"
              pitch={6}
              yaw={-252}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-five-p-hidden"
              pitch={6.4}
              yaw={-261}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-five-p-hidden"
              pitch={6.4}
              yaw={-264}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-five-p-hidden"
              pitch={6.4}
              yaw={-272}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-five-p-hidden"
              pitch={6.4}
              yaw={-276}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-five-p-hidden"
              pitch={6.4}
              yaw={-284}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-five-p-hidden"
              pitch={6.4}
              yaw={-288}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-five-p-hidden"
              pitch={6.4}
              yaw={-292}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-five-p-hidden"
              pitch={6.4}
              yaw={-296}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-five-p-hidden"
              pitch={6.4}
              yaw={-304}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-five-p-hidden"
              pitch={6.2}
              yaw={-306}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-five-p-hidden"
              pitch={6}
              yaw={-308}
              name="hs21"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot row-five-p-hidden"
              pitch={6}
              yaw={-310}
              name="hs21"
            />

          </Pannellum>
        </div>
        <div
          className={[
            "pannellum-image",
            imageIndex == 3 ? "pannellum-image-fade-in" : "pannellum-image-fade-out",
          ].join(" ")}
        >
          <Pannellum
            width="100%"
            height="100vh"
            image={imageThreeSrc}
            pitch={-5}
            yaw={180}
            hfov={hfov}
            autoLoad
            showZoomCtrl={false}
            showFullscreenCtrl={false}
            onLoad={() => { onImageLoad() }}
            onMousedown={(evt) => {
              onDrag();
            }}
            onMouseup={(evt) => {
              onDragLeave();
            }}
            onTouchstart={(evt) => {
              onDrag();
            }}
            onTouchend={(evt) => {
              onDragLeave();
            }}
          >
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot"
              pitch={14}
              yaw={178}
              handleClick={(evt, name) => handleHotspotThreeOneClickOpen()}
              name="hs31"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot-two"
              pitch={-5}
              yaw={160}
              handleClick={(evt, name) => handleHotspotThreeTwoClickOpen()}
              name="hs32"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot-three"
              pitch={4.5}
              yaw={120}
              handleClick={(evt, name) => handleHotspotThreeThreeClickOpen()}
              name="hs33"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot-four"
              pitch={3.5}
              yaw={240}
              handleClick={(evt, name) => handleHotspotThreeFourClickOpen()}
              name="hs34"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot-five"
              pitch={110}
              yaw={200}
              handleClick={(evt, name) => handleHotspotThreeFiveClickOpen()}
              name="hs35"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot-six"
              pitch={130}
              yaw={160}
              handleClick={(evt, name) => handleHotspotThreeSixClickOpen()}
              name="hs36"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot-seven"
              pitch={-10}
              yaw={180}
              handleClick={(evt, name) => handleHotspotThreeSevenClickOpen()}
              name="hs37"
            />
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot-eight"
              pitch={-24}
              yaw={180}
              handleClick={(evt, name) => handleHotspotThreeEightClickOpen()}
              name="hs38"
            />
          </Pannellum>
        </div>

        <section className={[
          "footer-section",
          isShowFooter ? "show-footer" : "hide-footer",
        ].join(" ")}>
          <div
            className={[
              "footer-container",
              isDrag ? "footer-fade-out" : "footer-fade-in",
            ].join(" ")}
          >
            <Button
              onClick={handleClickOpen}
              className="locations-btn"
              variant="outlined"
              startIcon={<MenuIcon />}
            >
              {localStorage.getItem("language") == 'en' && (
                <span>All Locations</span>
              )}
              {localStorage.getItem("language") == 'sl' && (
                <span>සියලුම ස්ථාන</span>
              )}
              {localStorage.getItem("language") == 'tm' && (
                <span>அனைத்து இடங்களும்</span>
              )}
            </Button>
            <div className="stepper-container">
              <IconButton className="prev-next-icon" onClick={() => goPrevious()}>
                <ArrowBackIosNewIcon />
              </IconButton>
              <div className="stepper-nav-container">
                <div className="stepper-locations">
                  <span
                    className="stepper-location-name"
                    onClick={navigateToSilverDoor}
                  >
                    {localStorage.getItem("language") == 'en' && (
                      <span>Silver Door</span>
                    )}
                    {localStorage.getItem("language") == 'sl' && (
                      <span>රිදී දොර</span>
                    )}
                    {localStorage.getItem("language") == 'tm' && (
                      <span>மவள்ளிக் கதவு</span>
                    )}
                  </span>
                  <span className={[
                    "stepper-location-name-active",
                    localStorage.getItem("language") == 'sl' ? "mlc-60" : "mlc-72",
                    localStorage.getItem("language") == 'tm' ? "mlc-50" : "mlc-72",
                  ].join(" ")}>
                    {locationName}
                  </span>
                  <span className="stepper-location-name" onClick={navigateToPortraitCollection}>
                    {localStorage.getItem("language") == 'en' && (
                      <span>Portrait Gallery</span>
                    )}
                    {localStorage.getItem("language") == 'sl' && (
                      <span>ඡායරූප ගැලරිය</span>
                    )}
                    {localStorage.getItem("language") == 'tm' && (
                      <span>புககப்பட மதாகுப்ப</span>
                    )}
                  </span>
                </div>
                <div className="stepper-navigations">
                  <span
                    className="stepper-navigations-name"
                    onClick={() => goPrevious()}
                  >
                    {localStorage.getItem("language") == 'en' && (
                      <span>Previous</span>
                    )}
                    {localStorage.getItem("language") == 'sl' && (
                      <span>පෙර</span>
                    )}
                    {localStorage.getItem("language") == 'tm' && (
                      <span>முந்தைய</span>
                    )}
                  </span>
                  <span className="stepper-line-left"></span>
                  <div className="stepper-circle-container">
                    <section
                      className={["mr-10", imageIndex === 1 ? "pulse" : ""].join(
                        " "
                      )}
                      onClick={() => changeImage(1)}
                    >
                      <div
                        className={[
                          "stepper-circle-multi",
                          imageIndex === 1 ? "active-stepper" : "",
                          imageIndex === 1 ? "fade-in-fwd" : "",
                        ].join(" ")}
                      >
                        <div className="stepper-circle-multi-inner"></div>
                      </div>
                    </section>

                    <section
                      className={["mr-10", imageIndex === 2 ? "pulse" : ""].join(
                        " "
                      )}
                      onClick={() => changeImage(2)}
                    >
                      <div
                        className={[
                          "stepper-circle-multi",
                          imageIndex === 2 ? "active-stepper" : "",
                          imageIndex === 2 ? "fade-in-fwd" : "",
                        ].join(" ")}
                      >
                        <div className="stepper-circle-multi-inner"></div>
                      </div>
                    </section>

                    <section
                      className={["", imageIndex === 3 ? "pulse" : ""].join(" ")}
                      onClick={() => changeImage(3)}
                    >
                      <div
                        className={[
                          "stepper-circle-multi",
                          imageIndex === 3 ? "active-stepper" : "",
                          imageIndex === 3 ? "fade-in-fwd" : "",
                        ].join(" ")}
                      >
                        <div className="stepper-circle-multi-inner"></div>
                      </div>
                    </section>
                  </div>
                  <span className="stepper-line-right"></span>
                  <span
                    className="stepper-navigations-name"
                    onClick={() => goNext()}
                  >
                    {localStorage.getItem("language") == 'en' && (
                      <span>Next</span>
                    )}
                    {localStorage.getItem("language") == 'sl' && (
                      <span>ඊළඟ</span>
                    )}
                    {localStorage.getItem("language") == 'tm' && (
                      <span>அடுத்தது</span>
                    )}
                  </span>
                </div>
              </div>
              <IconButton className="prev-next-icon" onClick={() => goNext()}>
                <ArrowForwardIosIcon />
              </IconButton>
            </div>
            <section
              className={[
                "footer-icon-section",
                isEnter ? "exit-full-screen" : "",
              ].join(" ")}
            >
              <FooterIconBar
                onOpenPresentaionView={handlePresentaionViewOpen}
                onOpenTutorial={handleTutorialOpen}
                onFullscreenToggle={() => {
                  setIsEnter(!isEnter);
                }}
              />
            </section>
          </div>
          <div
            className={[
              "footer-container-mob",
              isDrag ? "footer-fade-out" : "footer-fade-in",
            ].join(" ")}
          >
            <IconButton className="prev-next-icon" onClick={() => goPrevious()}>
              <ArrowBackIosNewIcon />
            </IconButton>
            <div className="flex-column-container">
              <div className="stepper-circle-container">
                <section
                  className={["mr-10", imageIndex === 1 ? "pulse" : ""].join(" ")}
                  onClick={() => changeImage(1)}
                >
                  <div
                    className={[
                      "stepper-circle-multi",
                      imageIndex === 1 ? "active-stepper" : "",
                      imageIndex === 1 ? "fade-in-fwd" : "",
                    ].join(" ")}
                  >
                    <div className="stepper-circle-multi-inner"></div>
                  </div>
                </section>

                <section
                  className={["mr-10", imageIndex === 2 ? "pulse" : ""].join(" ")}
                  onClick={() => changeImage(2)}
                >
                  <div
                    className={[
                      "stepper-circle-multi",
                      imageIndex === 2 ? "active-stepper" : "",
                      imageIndex === 2 ? "fade-in-fwd" : "",
                    ].join(" ")}
                  >
                    <div className="stepper-circle-multi-inner"></div>
                  </div>
                </section>

                <section
                  className={["", imageIndex === 3 ? "pulse" : ""].join(" ")}
                  onClick={() => changeImage(3)}
                >
                  <div
                    className={[
                      "stepper-circle-multi",
                      imageIndex === 3 ? "active-stepper" : "",
                      imageIndex === 3 ? "fade-in-fwd" : "",
                    ].join(" ")}
                  >
                    <div className="stepper-circle-multi-inner"></div>
                  </div>
                </section>
              </div>
              <span className="stepper-location-name-active">{locationName}</span>
              <span className="locations-btn-link" onClick={handleClickOpen}>
                {localStorage.getItem("language") == 'en' && (
                  <span>All Locations</span>
                )}
                {localStorage.getItem("language") == 'sl' && (
                  <span>සියලුම ස්ථාන</span>
                )}
                {localStorage.getItem("language") == 'tm' && (
                  <span>அனைத்து இடங்களும்</span>
                )}
              </span>
            </div>
            <IconButton className="prev-next-icon" onClick={() => goNext()}>
              <ArrowForwardIosIcon />
            </IconButton>
          </div>
        </section>


      </div>
    </Fullscreen >
  );
};

export default Chambers;
