import React, { useState, useEffect } from "react";
import { Pannellum } from "pannellum-react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MenuIcon from "@mui/icons-material/Menu";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CancelIcon from "@mui/icons-material/Cancel";
import Fullscreen from "fullscreen-react";
import Slide from "@mui/material/Slide";
import { useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import projectorIcon from "../../images/icons/projector.svg";
import AllLocations from "../../components/AllLocations";
import FooterIconBar from "../../components/FooterIconBar";
import TutorialSlider from "../../components/TutorialSlider";
import Loading from "../../components/Loading";

import axios from "axios";
import Invisible from "../../components/Invisible";
import Skeleton from '@mui/material/Skeleton';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PortraitCollectionSecondFloor = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [isShowFooter, setIsShowFooter] = useState(false);

  const [locationDetails, setLocationDetails] = React.useState([]);
  const [locationName, setLocationName] = React.useState("");
  const [hotspotArray, setHotspotArray] = useState([]);
  const [imageOneSrc, setImageOneSrc] = React.useState("");
  const [imageTwoSrc, setImageTwoSrc] = React.useState("");
  const [imageThreeSrc, setImageThreeSrc] = React.useState("");
  const [imageFourSrc, setImageFourSrc] = React.useState("");
  const [imageFiveSrc, setImageFiveSrc] = React.useState("");
  const [imageSixSrc, setImageSixSrc] = React.useState("");
  const [hotspotText, setHostpotDescription] = React.useState("");
  const [hotspotImage, setHotspotImage] = useState("");
  const [audio, setAudio] = useState(new Audio(null));
  const [playAudio, setPlayAudio] = React.useState(false);
  const [showIframe, setShowIframe] = React.useState(false);
  const [iframeUrl, setIframeUrl] = useState("");
  const [hotspotOpen, setHotspotOpen] = React.useState(false);
  const [fullImageOpen, setFullImageOpen] = React.useState(false);
  const [showAudio, setShowAudio] = React.useState(false);
  const [showImageLoading, setShowImageLoading] = React.useState(false);
  const [seeMore, setSeeMore] = React.useState(false);
  const [hotspotTitle, setHostpotTitle] = React.useState("");
  const [presentationOpen, setPresentationOpen] = React.useState(false);
  const [showSnackBar, setShowSnackBar] = React.useState(false);
  const [variable, setVariable] = useState(null);

  const getLocationDetails = async () => {
    setIsShowFooter(false);
    setIsLoading(true);
    try {
      const response = await axios.get(process.env.REACT_APP_API + 'Locations/locationDetails/getDetailsByLocation/portrait%20collection%20second%20floor');
      setLocationDetails(response.data);
      switch (localStorage.getItem("language")) {
        case 'en':
          setLocationName(response.data[0].nameInEnglish);
          break;
        case 'sl':
          setLocationName(response.data[0].nameInSinhala);
          break;
        case 'tm':
          setLocationName(response.data[0].nameInTamil);
          break;
        default:
          setLocationName(response.data[0].nameInEnglish);
          break;
      }
      setImageOneSrc(response.data[0].locationImage);
      setImageTwoSrc(response.data[1].locationImage);
      setImageThreeSrc(response.data[2].locationImage);
      setImageIndex(1);
      if (response.data[0].hotspotArray) {
        setHotspotArray(JSON.parse(response.data[0].hotspotArray));
        if (JSON.parse(response.data[0].hotspotArray)[0]) {
          if (JSON.parse(response.data[0].hotspotArray)[0].disable) {
            var hotspotsOne = document.getElementsByClassName('loc-hotspot');
            for (var i = 0; i < hotspotsOne.length; i++) {
              hotspotsOne[i].style.visibility = 'hidden';
              hotspotsOne[i].style.display = 'none';
            }
          } else {
            let hotspotString = '';
            switch (localStorage.getItem("language")) {
              case 'en':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[0].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeft", '-280px'
                );
                break;
              case 'sl':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[0].hotspotTitleSinhala + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeft", '-234px'
                );
                break;
              case 'tm':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[0].hotspotTitleTamil + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeft", '-400px'
                );
                break;
              default:
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[0].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeft", '-280px'
                );
                break;
            }
            document.documentElement.style.setProperty(
              "--hotspotText", hotspotString
            );
          }
        }

        if (JSON.parse(response.data[0].hotspotArray)[1]) {
          if (JSON.parse(response.data[0].hotspotArray)[1].disable) {
            var hotspotsTwo = document.getElementsByClassName('loc-hotspot-two');
            for (var i = 0; i < hotspotsTwo.length; i++) {
              hotspotsTwo[i].style.visibility = 'hidden';
              hotspotsTwo[i].style.display = 'none';
            }
          } else {
            let hotspotString = '';
            switch (localStorage.getItem("language")) {
              case 'en':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[1].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftTwo", '-260px'
                );
                break;
              case 'sl':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[1].hotspotTitleSinhala + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftTwo", '-220px'
                );
                break;
              case 'tm':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[1].hotspotTitleTamil + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftTwo", '-290px'
                );
                break;
              default:
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[1].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftTwo", '-260px'
                );
                break;
            }
            document.documentElement.style.setProperty(
              "--hotspotTextTwo", hotspotString
            );
          }
        }
        if (JSON.parse(response.data[0].hotspotArray)[2]) {
          if (JSON.parse(response.data[0].hotspotArray)[2].disable) {
            var hotspotsThree = document.getElementsByClassName('loc-hotspot-three');
            for (var i = 0; i < hotspotsThree.length; i++) {
              hotspotsThree[i].style.visibility = 'hidden';
              hotspotsThree[i].style.display = 'none';
            }
          } else {
            let hotspotString = '';
            switch (localStorage.getItem("language")) {
              case 'en':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[2].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftThree", '-206px'
                );
                break;
              case 'sl':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[2].hotspotTitleSinhala + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftThree", '-206px'
                );
                break;
              case 'tm':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[2].hotspotTitleTamil + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftThree", '-344px'
                );
                break;
              default:
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[2].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftThree", '-206px'
                );
                break;
            }
            document.documentElement.style.setProperty(
              "--hotspotTextThree", hotspotString
            );
          }
        }
        if (JSON.parse(response.data[0].hotspotArray)[3]) {
          if (JSON.parse(response.data[0].hotspotArray)[3].disable) {
            var hotspotsFour = document.getElementsByClassName('loc-hotspot-four');
            for (var i = 0; i < hotspotsFour.length; i++) {
              hotspotsFour[i].style.visibility = 'hidden';
              hotspotsFour[i].style.display = 'none';
            }
          } else {
            let hotspotString = '';
            switch (localStorage.getItem("language")) {
              case 'en':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[3].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftFour", '-300px'
                );
                break;
              case 'sl':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[3].hotspotTitleSinhala + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftFour", '-300px'
                );
                break;
              case 'tm':
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[3].hotspotTitleTamil + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftFour", '-412px'
                );
                break;
              default:
                hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[3].hotspotTitleEnglish + `'`;
                document.documentElement.style.setProperty(
                  "--hotspotLeftFour", '-300px'
                );
                break;
            }
            document.documentElement.style.setProperty(
              "--hotspotTextFour", hotspotString
            );
          }
        }

      } else {
        setHotspotArray([]);
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      console.log("There was an error!", error);
    }
  };

  const onImageLoad = () => {
    setTimeout(() => {
      setIsShowFooter(true);
    }, 1000);
  }

  const [open, setOpen] = React.useState(false);
  const [imageIndex, setImageIndex] = React.useState(1);
  const [hfov, setHfov] = React.useState(100);
  const [tutorialOpen, setTutorialOpen] = React.useState(false);
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getLocationDetails();
    localStorage.setItem("portraitCollectionSecond", "1");
    setHotspotOpen(false);
    setFullImageOpen(false);
    if (window.innerWidth < 481) {
      setHfov(60);
    } else {
      setHfov(100);
    }
  }, []);

  const changeImage = (id) => {
    setHotspotOpen(false);
    setShowIframe(false);
    setIframeUrl("");
    setFullImageOpen(false);
    setShowImageLoading(false);
    switch (id) {
      case 1:
        switch (localStorage.getItem("language")) {
          case 'en':
            setLocationName(locationDetails[0].nameInEnglish);
            break;
          case 'sl':
            setLocationName(locationDetails[0].nameInSinhala);
            break;
          case 'tm':
            setLocationName(locationDetails[0].nameInTamil);
            break;
          default:
            setLocationName(locationDetails[0].nameInEnglish);
            break;
        }
        setImageIndex(1);
        if (locationDetails[0].hotspotArray) {
          setHotspotArray(JSON.parse(locationDetails[0].hotspotArray));
          if (JSON.parse(locationDetails[0].hotspotArray)[0]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[0].disable) {
              var hotspotsOne = document.getElementsByClassName('loc-hotspot');
              for (var i = 0; i < hotspotsOne.length; i++) {
                hotspotsOne[i].style.visibility = 'hidden';
                hotspotsOne[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[0].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeft", '-280px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[0].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeft", '-234px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[0].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeft", '-400px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[0].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeft", '-280px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotText", hotspotString
              );
            }
          }

          if (JSON.parse(locationDetails[0].hotspotArray)[1]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[1].disable) {
              var hotspotsTwo = document.getElementsByClassName('loc-hotspot-two');
              for (var i = 0; i < hotspotsTwo.length; i++) {
                hotspotsTwo[i].style.visibility = 'hidden';
                hotspotsTwo[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[1].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwo", '-260px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[1].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwo", '-220px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[1].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwo", '-290px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[1].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwo", '-260px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextTwo", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[2]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[2].disable) {
              var hotspotsThree = document.getElementsByClassName('loc-hotspot-three');
              for (var i = 0; i < hotspotsThree.length; i++) {
                hotspotsThree[i].style.visibility = 'hidden';
                hotspotsThree[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[2].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftThree", '-206px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[2].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftThree", '-230px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[2].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftThree", '-344px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[2].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftThree", '-206px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextThree", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[0]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[0].disable) {
              var hotspotsFour = document.getElementsByClassName('loc-hotspot-four');
              for (var i = 0; i < hotspotsFour.length; i++) {
                hotspotsFour[i].style.visibility = 'hidden';
                hotspotsFour[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[3].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFour", '-300px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[3].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFour", '-300px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[3].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFour", '-412px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[3].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFour", '-300px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextFour", hotspotString
              );
            }
          }
        } else {
          setHotspotArray([]);
        }
        break;
      case 2:
        switch (localStorage.getItem("language")) {
          case 'en':
            setLocationName(locationDetails[1].nameInEnglish);
            break;
          case 'sl':
            setLocationName(locationDetails[1].nameInSinhala);
            break;
          case 'tm':
            setLocationName(locationDetails[1].nameInTamil);
            break;
          default:
            setLocationName(locationDetails[1].nameInEnglish);
            break;
        }
        setImageIndex(2);
        if (locationDetails[1].hotspotArray) {
          setHotspotArray(JSON.parse(locationDetails[1].hotspotArray));
          if (JSON.parse(locationDetails[1].hotspotArray)[0]) {
            if (JSON.parse(locationDetails[1].hotspotArray)[0].disable) {
              var hotspotsOne = document.getElementsByClassName('loc-hotspot');
              for (var i = 0; i < hotspotsOne.length; i++) {
                hotspotsOne[i].style.visibility = 'hidden';
                hotspotsOne[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[0].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeft", '-300px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[0].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeft", '-220px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[0].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeft", '-360px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[0].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeft", '-300px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotText", hotspotString
              );
            }
          }

          if (JSON.parse(locationDetails[1].hotspotArray)[1]) {
            if (JSON.parse(locationDetails[1].hotspotArray)[1].disable) {
              var hotspotsTwo = document.getElementsByClassName('loc-hotspot-two');
              for (var i = 0; i < hotspotsTwo.length; i++) {
                hotspotsTwo[i].style.visibility = 'hidden';
                hotspotsTwo[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[1].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwo", '-254px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[1].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwo", '-254px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[1].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwo", '-320px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[1].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwo", '-254px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextTwo", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[1].hotspotArray)[2]) {
            if (JSON.parse(locationDetails[1].hotspotArray)[2].disable) {
              var hotspotsThree = document.getElementsByClassName('loc-hotspot-three');
              for (var i = 0; i < hotspotsThree.length; i++) {
                hotspotsThree[i].style.visibility = 'hidden';
                hotspotsThree[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[2].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftThree", '-300px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[2].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftThree", '-254px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[2].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftThree", '-340px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[2].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftThree", '-300px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextThree", hotspotString
              );
            }
          }
        } else {
          setHotspotArray([]);
        }
        break;
      case 3:
        switch (localStorage.getItem("language")) {
          case 'en':
            setLocationName(locationDetails[2].nameInEnglish);
            break;
          case 'sl':
            setLocationName(locationDetails[2].nameInSinhala);
            break;
          case 'tm':
            setLocationName(locationDetails[2].nameInTamil);
            break;
          default:
            setLocationName(locationDetails[2].nameInEnglish);
            break;
        }
        setImageIndex(3);
        if (locationDetails[2].hotspotArray) {
          setHotspotArray(JSON.parse(locationDetails[2].hotspotArray));
          if (JSON.parse(locationDetails[2].hotspotArray)[0]) {
            if (JSON.parse(locationDetails[2].hotspotArray)[0].disable) {
              var hotspotsOne = document.getElementsByClassName('loc-hotspot');
              for (var i = 0; i < hotspotsOne.length; i++) {
                hotspotsOne[i].style.visibility = 'hidden';
                hotspotsOne[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[0].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeft", '-260px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[0].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeft", '-260px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[0].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeft", '-340px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[0].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeft", '-260px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotText", hotspotString
              );
            }
          }

          if (JSON.parse(locationDetails[2].hotspotArray)[1]) {
            if (JSON.parse(locationDetails[2].hotspotArray)[1].disable) {
              var hotspotsTwo = document.getElementsByClassName('loc-hotspot-two');
              for (var i = 0; i < hotspotsTwo.length; i++) {
                hotspotsTwo[i].style.visibility = 'hidden';
                hotspotsTwo[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[1].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwo", '-334px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[1].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwo", '-310px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[1].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwo", '-430px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[1].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwo", '-334px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextTwo", hotspotString
              );
            }
          }
        } else {
          setHotspotArray([]);
        }
        break;
      default:
        break;
    }
  };

  const goPrevious = () => {
    setHotspotOpen(false);
    setShowIframe(false);
    setIframeUrl("");
    setFullImageOpen(false);
    setShowImageLoading(false);
    switch (imageIndex) {
      case 1:
        navigate("/portrait-collection");
        break;
      case 2:
        switch (localStorage.getItem("language")) {
          case 'en':
            setLocationName(locationDetails[0].nameInEnglish);
            break;
          case 'sl':
            setLocationName(locationDetails[0].nameInSinhala);
            break;
          case 'tm':
            setLocationName(locationDetails[0].nameInTamil);
            break;
          default:
            setLocationName(locationDetails[0].nameInEnglish);
            break;
        }
        setImageIndex(1);
        if (locationDetails[0].hotspotArray) {
          setHotspotArray(JSON.parse(locationDetails[0].hotspotArray));
          if (JSON.parse(locationDetails[0].hotspotArray)[0]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[0].disable) {
              var hotspotsOne = document.getElementsByClassName('loc-hotspot');
              for (var i = 0; i < hotspotsOne.length; i++) {
                hotspotsOne[i].style.visibility = 'hidden';
                hotspotsOne[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[0].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeft", '-280px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[0].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeft", '-234px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[0].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeft", '-400px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[0].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeft", '-280px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotText", hotspotString
              );
            }
          }

          if (JSON.parse(locationDetails[0].hotspotArray)[1]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[1].disable) {
              var hotspotsTwo = document.getElementsByClassName('loc-hotspot-two');
              for (var i = 0; i < hotspotsTwo.length; i++) {
                hotspotsTwo[i].style.visibility = 'hidden';
                hotspotsTwo[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[1].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwo", '-260px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[1].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwo", '-220px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[1].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwo", '-290px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[1].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwo", '-260px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextTwo", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[2]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[2].disable) {
              var hotspotsThree = document.getElementsByClassName('loc-hotspot-three');
              for (var i = 0; i < hotspotsThree.length; i++) {
                hotspotsThree[i].style.visibility = 'hidden';
                hotspotsThree[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[2].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftThree", '-206px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[2].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftThree", '-230px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[2].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftThree", '-344px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[0].hotspotArray)[2].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftThree", '-206px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextThree", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[0].hotspotArray)[0]) {
            if (JSON.parse(locationDetails[0].hotspotArray)[0].disable) {
              var hotspotsFour = document.getElementsByClassName('loc-hotspot-four');
              for (var i = 0; i < hotspotsFour.length; i++) {
                hotspotsFour[i].style.visibility = 'hidden';
                hotspotsFour[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[3].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFour", '-300px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[3].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFour", '-300px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[3].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFour", '-412px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(response.data[0].hotspotArray)[3].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftFour", '-300px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextFour", hotspotString
              );
            }
          }
        } else {
          setHotspotArray([]);
        }
        break;
      case 3:
        switch (localStorage.getItem("language")) {
          case 'en':
            setLocationName(locationDetails[1].nameInEnglish);
            break;
          case 'sl':
            setLocationName(locationDetails[1].nameInSinhala);
            break;
          case 'tm':
            setLocationName(locationDetails[1].nameInTamil);
            break;
          default:
            setLocationName(locationDetails[1].nameInEnglish);
            break;
        }
        setImageIndex(2);
        if (locationDetails[1].hotspotArray) {
          setHotspotArray(JSON.parse(locationDetails[1].hotspotArray));
          if (JSON.parse(locationDetails[1].hotspotArray)[0]) {
            if (JSON.parse(locationDetails[1].hotspotArray)[0].disable) {
              var hotspotsOne = document.getElementsByClassName('loc-hotspot');
              for (var i = 0; i < hotspotsOne.length; i++) {
                hotspotsOne[i].style.visibility = 'hidden';
                hotspotsOne[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[0].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeft", '-300px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[0].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeft", '-220px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[0].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeft", '-360px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[0].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeft", '-300px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotText", hotspotString
              );
            }
          }

          if (JSON.parse(locationDetails[1].hotspotArray)[1]) {
            if (JSON.parse(locationDetails[1].hotspotArray)[1].disable) {
              var hotspotsTwo = document.getElementsByClassName('loc-hotspot-two');
              for (var i = 0; i < hotspotsTwo.length; i++) {
                hotspotsTwo[i].style.visibility = 'hidden';
                hotspotsTwo[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[1].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwo", '-254px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[1].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwo", '-254px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[1].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwo", '-320px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[1].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwo", '-254px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextTwo", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[1].hotspotArray)[2]) {
            if (JSON.parse(locationDetails[1].hotspotArray)[2].disable) {
              var hotspotsThree = document.getElementsByClassName('loc-hotspot-three');
              for (var i = 0; i < hotspotsThree.length; i++) {
                hotspotsThree[i].style.visibility = 'hidden';
                hotspotsThree[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[2].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftThree", '-300px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[2].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftThree", '-254px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[2].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftThree", '-340px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[2].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftThree", '-300px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextThree", hotspotString
              );
            }
          }
        } else {
          setHotspotArray([]);
        }
        break;
      default:
        break;
    }
  };

  const goNext = () => {
    setHotspotOpen(false);
    setShowIframe(false);
    setIframeUrl("");
    setFullImageOpen(false);
    setShowImageLoading(false);
    switch (imageIndex) {
      case 1:
        switch (localStorage.getItem("language")) {
          case 'en':
            setLocationName(locationDetails[1].nameInEnglish);
            break;
          case 'sl':
            setLocationName(locationDetails[1].nameInSinhala);
            break;
          case 'tm':
            setLocationName(locationDetails[1].nameInTamil);
            break;
          default:
            setLocationName(locationDetails[1].nameInEnglish);
            break;
        }
        setImageIndex(2);
        if (locationDetails[1].hotspotArray) {
          setHotspotArray(JSON.parse(locationDetails[1].hotspotArray));
          if (JSON.parse(locationDetails[1].hotspotArray)[0]) {
            if (JSON.parse(locationDetails[1].hotspotArray)[0].disable) {
              var hotspotsOne = document.getElementsByClassName('loc-hotspot');
              for (var i = 0; i < hotspotsOne.length; i++) {
                hotspotsOne[i].style.visibility = 'hidden';
                hotspotsOne[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[0].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeft", '-300px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[0].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeft", '-220px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[0].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeft", '-360px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[0].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeft", '-300px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotText", hotspotString
              );
            }
          }

          if (JSON.parse(locationDetails[1].hotspotArray)[1]) {
            if (JSON.parse(locationDetails[1].hotspotArray)[1].disable) {
              var hotspotsTwo = document.getElementsByClassName('loc-hotspot-two');
              for (var i = 0; i < hotspotsTwo.length; i++) {
                hotspotsTwo[i].style.visibility = 'hidden';
                hotspotsTwo[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[1].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwo", '-254px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[1].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwo", '-254px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[1].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwo", '-320px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[1].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwo", '-254px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextTwo", hotspotString
              );
            }
          }
          if (JSON.parse(locationDetails[1].hotspotArray)[2]) {
            if (JSON.parse(locationDetails[1].hotspotArray)[2].disable) {
              var hotspotsThree = document.getElementsByClassName('loc-hotspot-three');
              for (var i = 0; i < hotspotsThree.length; i++) {
                hotspotsThree[i].style.visibility = 'hidden';
                hotspotsThree[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[2].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftThree", '-300px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[2].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftThree", '-254px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[2].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftThree", '-340px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[1].hotspotArray)[2].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftThree", '-300px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextThree", hotspotString
              );
            }
          }
        } else {
          setHotspotArray([]);
        }
        break;
      case 2:
        switch (localStorage.getItem("language")) {
          case 'en':
            setLocationName(locationDetails[2].nameInEnglish);
            break;
          case 'sl':
            setLocationName(locationDetails[2].nameInSinhala);
            break;
          case 'tm':
            setLocationName(locationDetails[2].nameInTamil);
            break;
          default:
            setLocationName(locationDetails[2].nameInEnglish);
            break;
        }
        setImageIndex(3);
        if (locationDetails[2].hotspotArray) {
          setHotspotArray(JSON.parse(locationDetails[2].hotspotArray));
          if (JSON.parse(locationDetails[2].hotspotArray)[0]) {
            if (JSON.parse(locationDetails[2].hotspotArray)[0].disable) {
              var hotspotsOne = document.getElementsByClassName('loc-hotspot');
              for (var i = 0; i < hotspotsOne.length; i++) {
                hotspotsOne[i].style.visibility = 'hidden';
                hotspotsOne[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[0].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeft", '-260px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[0].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeft", '-260px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[0].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeft", '-340px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[0].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeft", '-260px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotText", hotspotString
              );
            }
          }

          if (JSON.parse(locationDetails[2].hotspotArray)[1]) {
            if (JSON.parse(locationDetails[2].hotspotArray)[1].disable) {
              var hotspotsTwo = document.getElementsByClassName('loc-hotspot-two');
              for (var i = 0; i < hotspotsTwo.length; i++) {
                hotspotsTwo[i].style.visibility = 'hidden';
                hotspotsTwo[i].style.display = 'none';
              }
            } else {
              let hotspotString = '';
              switch (localStorage.getItem("language")) {
                case 'en':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[1].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwo", '-334px'
                  );
                  break;
                case 'sl':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[1].hotspotTitleSinhala + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwo", '-310px'
                  );
                  break;
                case 'tm':
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[1].hotspotTitleTamil + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwo", '-430px'
                  );
                  break;
                default:
                  hotspotString = `'` + JSON.parse(locationDetails[2].hotspotArray)[1].hotspotTitleEnglish + `'`;
                  document.documentElement.style.setProperty(
                    "--hotspotLeftTwo", '-334px'
                  );
                  break;
              }
              document.documentElement.style.setProperty(
                "--hotspotTextTwo", hotspotString
              );
            }
          }
        } else {
          setHotspotArray([]);
        }
        break;
      case 3:
        navigate("/committee-rooms");
        break;
      default:
        break;
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAudioPlay = () => {
    audio.play();
    setPlayAudio(true);
  };

  const pauseAudioPlay = () => {
    audio.pause();
    setPlayAudio(false);
  };

  audio.onended = function () {
    setPlayAudio(false);
  };

  const handleHotspotClose = () => {
    setHotspotOpen(false);
    setFullImageOpen(false);
    setShowAudio(false);
    setPlayAudio(false);
    audio.pause();
  };

  const goHome = () => {
    setAnchorEl(null);
    navigate("/");
  };

  const handleHotspotFourOneClickOpen = () => {
    setShowImageLoading(true);
    setShowIframe(false);
    setIframeUrl("");
    setHotspotOpen(false);
    setFullImageOpen(false);
    setHotspotImage('');
    setHostpotDescription('');
    setHostpotTitle('');
    if (!JSON.parse(locationDetails[0].hotspotArray)[0].disableHotspotIframe) {
      console.log(JSON.parse(locationDetails[0].hotspotArray)[0].hotspotIframe)
      if (JSON.parse(locationDetails[0].hotspotArray)[0].hotspotIframe) {
        setShowIframe(true);
        setIframeUrl(JSON.parse(locationDetails[0].hotspotArray)[0].hotspotIframe);
      }
    } else {
      setShowIframe(false);
      setIframeUrl("");
    }
    var audioUrl = null;
    switch (localStorage.getItem("language")) {
      case 'en':
        if (JSON.parse(locationDetails[0].hotspotArray)[0].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[0].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[0].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[0].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[0].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[0].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[0].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[0].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[0].hotspotDescriptionEnglish);
        }
        break;
      case 'sl':
        if (JSON.parse(locationDetails[0].hotspotArray)[0].hotspotVoiceSinhala) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[0].hotspotVoiceSinhala;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[0].hotspotVoiceSinhala));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[0].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[0].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[0].hotspotTitleSinhala) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[0].hotspotTitleSinhala);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[0].hotspotDescriptionSinhala) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[0].hotspotDescriptionSinhala);
        }
        break;
      case 'tm':
        if (JSON.parse(locationDetails[0].hotspotArray)[0].hotspotVoiceTamil) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[0].hotspotVoiceTamil;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[0].hotspotVoiceTamil));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[0].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[0].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[0].hotspotTitleTamil) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[0].hotspotTitleTamil);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[0].hotspotDescriptionTamil) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[0].hotspotDescriptionTamil);
        }
        break;
      default:
        if (JSON.parse(locationDetails[0].hotspotArray)[0].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[0].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[0].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[0].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[0].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[0].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[0].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[0].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[0].hotspotDescriptionEnglish);
        }
        break;
    }
    setHotspotOpen(true);
  };

  const handleHotspotFourTwoClickOpen = () => {
    setShowImageLoading(true);
    setShowIframe(false);
    setIframeUrl("");
    setHotspotOpen(false);
    setFullImageOpen(false);
    setHotspotImage('');
    setHostpotDescription('');
    setHostpotTitle('');
    if (!JSON.parse(locationDetails[0].hotspotArray)[1].disableHotspotIframe) {
      console.log(JSON.parse(locationDetails[0].hotspotArray)[1].hotspotIframe)
      if (JSON.parse(locationDetails[0].hotspotArray)[1].hotspotIframe) {
        setShowIframe(true);
        setIframeUrl(JSON.parse(locationDetails[0].hotspotArray)[1].hotspotIframe);
      }
    } else {
      setShowIframe(false);
      setIframeUrl("");
    }
    var audioUrl = null;
    switch (localStorage.getItem("language")) {
      case 'en':
        if (JSON.parse(locationDetails[0].hotspotArray)[1].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[1].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[1].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[1].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[1].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[1].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[1].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[1].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[1].hotspotDescriptionEnglish);
        }
        break;
      case 'sl':
        if (JSON.parse(locationDetails[0].hotspotArray)[1].hotspotVoiceSinhala) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[1].hotspotVoiceSinhala;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[1].hotspotVoiceSinhala));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[1].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[1].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[1].hotspotTitleSinhala) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[1].hotspotTitleSinhala);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[1].hotspotDescriptionSinhala) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[1].hotspotDescriptionSinhala);
        }
        break;
      case 'tm':
        if (JSON.parse(locationDetails[0].hotspotArray)[1].hotspotVoiceTamil) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[1].hotspotVoiceTamil;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[1].hotspotVoiceTamil));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[1].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[1].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[1].hotspotTitleTamil) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[1].hotspotTitleTamil);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[1].hotspotDescriptionTamil) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[1].hotspotDescriptionTamil);
        }
        break;
      default:
        if (JSON.parse(locationDetails[0].hotspotArray)[1].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[1].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[1].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[1].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[1].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[1].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[1].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[1].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[1].hotspotDescriptionEnglish);
        }
        break;
    }
    setHotspotOpen(true);
  };

  const handleHotspotFourThreeClickOpen = () => {
    setShowImageLoading(true);
    setShowIframe(false);
    setIframeUrl("");
    setHotspotOpen(false);
    setFullImageOpen(false);
    setHotspotImage('');
    setHostpotDescription('');
    setHostpotTitle('');
    if (!JSON.parse(locationDetails[0].hotspotArray)[2].disableHotspotIframe) {
      console.log(JSON.parse(locationDetails[0].hotspotArray)[2].hotspotIframe)
      if (JSON.parse(locationDetails[0].hotspotArray)[2].hotspotIframe) {
        setShowIframe(true);
        setIframeUrl(JSON.parse(locationDetails[0].hotspotArray)[2].hotspotIframe);
      }
    } else {
      setShowIframe(false);
      setIframeUrl("");
    }
    var audioUrl = null;
    switch (localStorage.getItem("language")) {
      case 'en':
        if (JSON.parse(locationDetails[0].hotspotArray)[2].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[2].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[2].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[2].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[2].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[2].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[2].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[2].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[2].hotspotDescriptionEnglish);
        }
        break;
      case 'sl':
        if (JSON.parse(locationDetails[0].hotspotArray)[2].hotspotVoiceSinhala) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[2].hotspotVoiceSinhala;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[2].hotspotVoiceSinhala));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[2].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[2].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[2].hotspotTitleSinhala) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[2].hotspotTitleSinhala);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[2].hotspotDescriptionSinhala) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[2].hotspotDescriptionSinhala);
        }
        break;
      case 'tm':
        if (JSON.parse(locationDetails[0].hotspotArray)[2].hotspotVoiceTamil) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[2].hotspotVoiceTamil;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[2].hotspotVoiceTamil));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[2].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[2].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[2].hotspotTitleTamil) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[2].hotspotTitleTamil);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[2].hotspotDescriptionTamil) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[2].hotspotDescriptionTamil);
        }
        break;
      default:
        if (JSON.parse(locationDetails[0].hotspotArray)[2].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[2].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[2].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[2].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[2].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[2].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[2].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[2].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[2].hotspotDescriptionEnglish);
        }
        break;
    }
    setHotspotOpen(true);
  };

  const handleHotspotFourFourClickOpen = () => {
    setShowImageLoading(true);
    setShowIframe(false);
    setIframeUrl("");
    setHotspotOpen(false);
    setFullImageOpen(false);
    setHotspotImage('');
    setHostpotDescription('');
    setHostpotTitle('');
    if (!JSON.parse(locationDetails[0].hotspotArray)[3].disableHotspotIframe) {
      console.log(JSON.parse(locationDetails[0].hotspotArray)[3].hotspotIframe)
      if (JSON.parse(locationDetails[0].hotspotArray)[3].hotspotIframe) {
        setShowIframe(true);
        setIframeUrl(JSON.parse(locationDetails[0].hotspotArray)[3].hotspotIframe);
      }
    } else {
      setShowIframe(false);
      setIframeUrl("");
    }
    var audioUrl = null;
    switch (localStorage.getItem("language")) {
      case 'en':
        if (JSON.parse(locationDetails[0].hotspotArray)[3].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[3].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[3].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[3].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[3].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[3].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[3].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[3].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[3].hotspotDescriptionEnglish);
        }
        break;
      case 'sl':
        if (JSON.parse(locationDetails[0].hotspotArray)[3].hotspotVoiceSinhala) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[3].hotspotVoiceSinhala;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[3].hotspotVoiceSinhala));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[3].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[3].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[3].hotspotTitleSinhala) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[3].hotspotTitleSinhala);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[3].hotspotDescriptionSinhala) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[3].hotspotDescriptionSinhala);
        }
        break;
      case 'tm':
        if (JSON.parse(locationDetails[0].hotspotArray)[3].hotspotVoiceTamil) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[3].hotspotVoiceTamil;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[3].hotspotVoiceTamil));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[3].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[3].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[3].hotspotTitleTamil) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[3].hotspotTitleTamil);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[3].hotspotDescriptionTamil) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[3].hotspotDescriptionTamil);
        }
        break;
      default:
        if (JSON.parse(locationDetails[0].hotspotArray)[3].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[0].hotspotArray)[3].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[0].hotspotArray)[3].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[0].hotspotArray)[3].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[0].hotspotArray)[3].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[3].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[0].hotspotArray)[3].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[0].hotspotArray)[3].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[0].hotspotArray)[3].hotspotDescriptionEnglish);
        }
        break;
    }
    setHotspotOpen(true);
  };

  const handleHotspotFiveOneClickOpen = () => {
    setShowImageLoading(true);
    setShowIframe(false);
    setIframeUrl("");
    setHotspotOpen(false);
    setFullImageOpen(false);
    setHotspotImage('');
    setHostpotDescription('');
    setHostpotTitle('');
    if (!JSON.parse(locationDetails[1].hotspotArray)[0].disableHotspotIframe) {
      console.log(JSON.parse(locationDetails[1].hotspotArray)[0].hotspotIframe)
      if (JSON.parse(locationDetails[1].hotspotArray)[0].hotspotIframe) {
        setShowIframe(true);
        setIframeUrl(JSON.parse(locationDetails[1].hotspotArray)[0].hotspotIframe);
      }
    } else {
      setShowIframe(false);
      setIframeUrl("");
    }
    var audioUrl = null;
    switch (localStorage.getItem("language")) {
      case 'en':
        if (JSON.parse(locationDetails[1].hotspotArray)[0].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[1].hotspotArray)[0].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[1].hotspotArray)[0].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[1].hotspotArray)[0].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[1].hotspotArray)[0].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[1].hotspotArray)[0].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[1].hotspotArray)[0].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[1].hotspotArray)[0].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[1].hotspotArray)[0].hotspotDescriptionEnglish);
        }
        break;
      case 'sl':
        if (JSON.parse(locationDetails[1].hotspotArray)[0].hotspotVoiceSinhala) {
          audioUrl = JSON.parse(locationDetails[1].hotspotArray)[0].hotspotVoiceSinhala;
          setAudio(new Audio(JSON.parse(locationDetails[1].hotspotArray)[0].hotspotVoiceSinhala));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[1].hotspotArray)[0].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[1].hotspotArray)[0].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[1].hotspotArray)[0].hotspotTitleSinhala) {
          setHostpotTitle(JSON.parse(locationDetails[1].hotspotArray)[0].hotspotTitleSinhala);
        }
        if (JSON.parse(locationDetails[1].hotspotArray)[0].hotspotDescriptionSinhala) {
          setHostpotDescription(JSON.parse(locationDetails[1].hotspotArray)[0].hotspotDescriptionSinhala);
        }
        break;
      case 'tm':
        if (JSON.parse(locationDetails[1].hotspotArray)[0].hotspotVoiceTamil) {
          audioUrl = JSON.parse(locationDetails[1].hotspotArray)[0].hotspotVoiceTamil;
          setAudio(new Audio(JSON.parse(locationDetails[1].hotspotArray)[0].hotspotVoiceTamil));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[1].hotspotArray)[0].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[1].hotspotArray)[0].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[1].hotspotArray)[0].hotspotTitleTamil) {
          setHostpotTitle(JSON.parse(locationDetails[1].hotspotArray)[0].hotspotTitleTamil);
        }
        if (JSON.parse(locationDetails[1].hotspotArray)[0].hotspotDescriptionTamil) {
          setHostpotDescription(JSON.parse(locationDetails[1].hotspotArray)[0].hotspotDescriptionTamil);
        }
        break;
      default:
        if (JSON.parse(locationDetails[1].hotspotArray)[0].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[1].hotspotArray)[0].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[1].hotspotArray)[0].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[1].hotspotArray)[0].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[1].hotspotArray)[0].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[1].hotspotArray)[0].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[1].hotspotArray)[0].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[1].hotspotArray)[0].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[1].hotspotArray)[0].hotspotDescriptionEnglish);
        }
        break;
    }
    setHotspotOpen(true);
  };

  const handleHotspotFiveTwoClickOpen = () => {
    setShowImageLoading(true);
    setShowIframe(false);
    setIframeUrl("");
    setHotspotOpen(false);
    setFullImageOpen(false);
    setHotspotImage('');
    setHostpotDescription('');
    setHostpotTitle('');
    if (!JSON.parse(locationDetails[1].hotspotArray)[1].disableHotspotIframe) {
      console.log(JSON.parse(locationDetails[1].hotspotArray)[1].hotspotIframe)
      if (JSON.parse(locationDetails[1].hotspotArray)[1].hotspotIframe) {
        setShowIframe(true);
        setIframeUrl(JSON.parse(locationDetails[1].hotspotArray)[1].hotspotIframe);
      }
    } else {
      setShowIframe(false);
      setIframeUrl("");
    }
    var audioUrl = null;
    switch (localStorage.getItem("language")) {
      case 'en':
        if (JSON.parse(locationDetails[1].hotspotArray)[1].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[1].hotspotArray)[1].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[1].hotspotArray)[1].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[1].hotspotArray)[1].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[1].hotspotArray)[1].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[1].hotspotArray)[1].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[1].hotspotArray)[1].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[1].hotspotArray)[1].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[1].hotspotArray)[1].hotspotDescriptionEnglish);
        }
        break;
      case 'sl':
        if (JSON.parse(locationDetails[1].hotspotArray)[1].hotspotVoiceSinhala) {
          audioUrl = JSON.parse(locationDetails[1].hotspotArray)[1].hotspotVoiceSinhala;
          setAudio(new Audio(JSON.parse(locationDetails[1].hotspotArray)[1].hotspotVoiceSinhala));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[1].hotspotArray)[1].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[1].hotspotArray)[1].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[1].hotspotArray)[1].hotspotTitleSinhala) {
          setHostpotTitle(JSON.parse(locationDetails[1].hotspotArray)[1].hotspotTitleSinhala);
        }
        if (JSON.parse(locationDetails[1].hotspotArray)[1].hotspotDescriptionSinhala) {
          setHostpotDescription(JSON.parse(locationDetails[1].hotspotArray)[1].hotspotDescriptionSinhala);
        }
        break;
      case 'tm':
        if (JSON.parse(locationDetails[1].hotspotArray)[1].hotspotVoiceTamil) {
          audioUrl = JSON.parse(locationDetails[1].hotspotArray)[1].hotspotVoiceTamil;
          setAudio(new Audio(JSON.parse(locationDetails[1].hotspotArray)[1].hotspotVoiceTamil));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[1].hotspotArray)[1].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[1].hotspotArray)[1].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[1].hotspotArray)[1].hotspotTitleTamil) {
          setHostpotTitle(JSON.parse(locationDetails[1].hotspotArray)[1].hotspotTitleTamil);
        }
        if (JSON.parse(locationDetails[1].hotspotArray)[1].hotspotDescriptionTamil) {
          setHostpotDescription(JSON.parse(locationDetails[1].hotspotArray)[1].hotspotDescriptionTamil);
        }
        break;
      default:
        if (JSON.parse(locationDetails[1].hotspotArray)[1].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[1].hotspotArray)[1].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[1].hotspotArray)[1].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[1].hotspotArray)[1].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[1].hotspotArray)[1].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[1].hotspotArray)[1].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[1].hotspotArray)[1].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[1].hotspotArray)[1].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[1].hotspotArray)[1].hotspotDescriptionEnglish);
        }
        break;
    }
    setHotspotOpen(true);
  };

  const handleHotspotFiveThreeClickOpen = () => {
    setShowImageLoading(true);
    setShowIframe(false);
    setIframeUrl("");
    setHotspotOpen(false);
    setFullImageOpen(false);
    setHotspotImage('');
    setHostpotDescription('');
    setHostpotTitle('');
    if (!JSON.parse(locationDetails[1].hotspotArray)[2].disableHotspotIframe) {
      console.log(JSON.parse(locationDetails[1].hotspotArray)[2].hotspotIframe)
      if (JSON.parse(locationDetails[1].hotspotArray)[2].hotspotIframe) {
        setShowIframe(true);
        setIframeUrl(JSON.parse(locationDetails[1].hotspotArray)[2].hotspotIframe);
      }
    } else {
      setShowIframe(false);
      setIframeUrl("");
    }
    var audioUrl = null;
    switch (localStorage.getItem("language")) {
      case 'en':
        if (JSON.parse(locationDetails[1].hotspotArray)[2].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[1].hotspotArray)[2].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[1].hotspotArray)[2].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[1].hotspotArray)[2].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[1].hotspotArray)[2].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[1].hotspotArray)[2].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[1].hotspotArray)[2].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[1].hotspotArray)[2].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[1].hotspotArray)[2].hotspotDescriptionEnglish);
        }
        break;
      case 'sl':
        if (JSON.parse(locationDetails[1].hotspotArray)[2].hotspotVoiceSinhala) {
          audioUrl = JSON.parse(locationDetails[1].hotspotArray)[2].hotspotVoiceSinhala;
          setAudio(new Audio(JSON.parse(locationDetails[1].hotspotArray)[2].hotspotVoiceSinhala));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[1].hotspotArray)[2].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[1].hotspotArray)[2].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[1].hotspotArray)[2].hotspotTitleSinhala) {
          setHostpotTitle(JSON.parse(locationDetails[1].hotspotArray)[2].hotspotTitleSinhala);
        }
        if (JSON.parse(locationDetails[1].hotspotArray)[2].hotspotDescriptionSinhala) {
          setHostpotDescription(JSON.parse(locationDetails[1].hotspotArray)[2].hotspotDescriptionSinhala);
        }
        break;
      case 'tm':
        if (JSON.parse(locationDetails[1].hotspotArray)[2].hotspotVoiceTamil) {
          audioUrl = JSON.parse(locationDetails[1].hotspotArray)[2].hotspotVoiceTamil;
          setAudio(new Audio(JSON.parse(locationDetails[1].hotspotArray)[2].hotspotVoiceTamil));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[1].hotspotArray)[2].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[1].hotspotArray)[2].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[1].hotspotArray)[2].hotspotTitleTamil) {
          setHostpotTitle(JSON.parse(locationDetails[1].hotspotArray)[2].hotspotTitleTamil);
        }
        if (JSON.parse(locationDetails[1].hotspotArray)[2].hotspotDescriptionTamil) {
          setHostpotDescription(JSON.parse(locationDetails[1].hotspotArray)[2].hotspotDescriptionTamil);
        }
        break;
      default:
        if (JSON.parse(locationDetails[1].hotspotArray)[2].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[1].hotspotArray)[2].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[1].hotspotArray)[2].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[1].hotspotArray)[2].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[1].hotspotArray)[2].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[1].hotspotArray)[2].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[1].hotspotArray)[2].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[1].hotspotArray)[2].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[1].hotspotArray)[2].hotspotDescriptionEnglish);
        }
        break;
    }
    setHotspotOpen(true);
  };

  const handleHotspotSixOneClickOpen = () => {
    setShowImageLoading(true);
    setShowIframe(false);
    setIframeUrl("");
    setHotspotOpen(false);
    setFullImageOpen(false);
    setHotspotImage('');
    setHostpotDescription('');
    setHostpotTitle('');
    if (!JSON.parse(locationDetails[2].hotspotArray)[0].disableHotspotIframe) {
      console.log(JSON.parse(locationDetails[2].hotspotArray)[0].hotspotIframe)
      if (JSON.parse(locationDetails[2].hotspotArray)[0].hotspotIframe) {
        setShowIframe(true);
        setIframeUrl(JSON.parse(locationDetails[2].hotspotArray)[0].hotspotIframe);
      }
    } else {
      setShowIframe(false);
      setIframeUrl("");
    }
    var audioUrl = null;
    switch (localStorage.getItem("language")) {
      case 'en':
        if (JSON.parse(locationDetails[2].hotspotArray)[0].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[2].hotspotArray)[0].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[2].hotspotArray)[0].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[2].hotspotArray)[0].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[2].hotspotArray)[0].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[0].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[2].hotspotArray)[0].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[0].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[2].hotspotArray)[0].hotspotDescriptionEnglish);
        }
        break;
      case 'sl':
        if (JSON.parse(locationDetails[2].hotspotArray)[0].hotspotVoiceSinhala) {
          audioUrl = JSON.parse(locationDetails[2].hotspotArray)[0].hotspotVoiceSinhala;
          setAudio(new Audio(JSON.parse(locationDetails[2].hotspotArray)[0].hotspotVoiceSinhala));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[2].hotspotArray)[0].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[2].hotspotArray)[0].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[0].hotspotTitleSinhala) {
          setHostpotTitle(JSON.parse(locationDetails[2].hotspotArray)[0].hotspotTitleSinhala);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[0].hotspotDescriptionSinhala) {
          setHostpotDescription(JSON.parse(locationDetails[2].hotspotArray)[0].hotspotDescriptionSinhala);
        }
        break;
      case 'tm':
        if (JSON.parse(locationDetails[2].hotspotArray)[0].hotspotVoiceTamil) {
          audioUrl = JSON.parse(locationDetails[2].hotspotArray)[0].hotspotVoiceTamil;
          setAudio(new Audio(JSON.parse(locationDetails[2].hotspotArray)[0].hotspotVoiceTamil));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[2].hotspotArray)[0].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[2].hotspotArray)[0].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[0].hotspotTitleTamil) {
          setHostpotTitle(JSON.parse(locationDetails[2].hotspotArray)[0].hotspotTitleTamil);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[0].hotspotDescriptionTamil) {
          setHostpotDescription(JSON.parse(locationDetails[2].hotspotArray)[0].hotspotDescriptionTamil);
        }
        break;
      default:
        if (JSON.parse(locationDetails[2].hotspotArray)[0].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[2].hotspotArray)[0].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[2].hotspotArray)[0].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[2].hotspotArray)[0].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[2].hotspotArray)[0].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[0].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[2].hotspotArray)[0].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[0].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[2].hotspotArray)[0].hotspotDescriptionEnglish);
        }
        break;
    }
    setHotspotOpen(true);
  };

  const handleHotspotSixTwoClickOpen = () => {
    setShowImageLoading(true);
    setShowIframe(false);
    setIframeUrl("");
    setHotspotOpen(false);
    setFullImageOpen(false);
    setHotspotImage('');
    setHostpotDescription('');
    setHostpotTitle('');
    if (!JSON.parse(locationDetails[2].hotspotArray)[1].disableHotspotIframe) {
      console.log(JSON.parse(locationDetails[2].hotspotArray)[1].hotspotIframe)
      if (JSON.parse(locationDetails[2].hotspotArray)[1].hotspotIframe) {
        setShowIframe(true);
        setIframeUrl(JSON.parse(locationDetails[2].hotspotArray)[1].hotspotIframe);
      }
    } else {
      setShowIframe(false);
      setIframeUrl("");
    }
    var audioUrl = null;
    switch (localStorage.getItem("language")) {
      case 'en':
        if (JSON.parse(locationDetails[2].hotspotArray)[1].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[2].hotspotArray)[1].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[2].hotspotArray)[1].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[2].hotspotArray)[1].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[2].hotspotArray)[1].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[1].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[2].hotspotArray)[1].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[1].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[2].hotspotArray)[1].hotspotDescriptionEnglish);
        }
        break;
      case 'sl':
        if (JSON.parse(locationDetails[2].hotspotArray)[1].hotspotVoiceSinhala) {
          audioUrl = JSON.parse(locationDetails[2].hotspotArray)[1].hotspotVoiceSinhala;
          setAudio(new Audio(JSON.parse(locationDetails[2].hotspotArray)[1].hotspotVoiceSinhala));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[2].hotspotArray)[1].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[2].hotspotArray)[1].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[1].hotspotTitleSinhala) {
          setHostpotTitle(JSON.parse(locationDetails[2].hotspotArray)[1].hotspotTitleSinhala);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[1].hotspotDescriptionSinhala) {
          setHostpotDescription(JSON.parse(locationDetails[2].hotspotArray)[1].hotspotDescriptionSinhala);
        }
        break;
      case 'tm':
        if (JSON.parse(locationDetails[2].hotspotArray)[1].hotspotVoiceTamil) {
          audioUrl = JSON.parse(locationDetails[2].hotspotArray)[1].hotspotVoiceTamil;
          setAudio(new Audio(JSON.parse(locationDetails[2].hotspotArray)[1].hotspotVoiceTamil));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[2].hotspotArray)[1].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[2].hotspotArray)[1].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[1].hotspotTitleTamil) {
          setHostpotTitle(JSON.parse(locationDetails[2].hotspotArray)[1].hotspotTitleTamil);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[1].hotspotDescriptionTamil) {
          setHostpotDescription(JSON.parse(locationDetails[2].hotspotArray)[1].hotspotDescriptionTamil);
        }
        break;
      default:
        if (JSON.parse(locationDetails[2].hotspotArray)[1].hotspotVoiceEnglish) {
          audioUrl = JSON.parse(locationDetails[2].hotspotArray)[1].hotspotVoiceEnglish;
          setAudio(new Audio(JSON.parse(locationDetails[2].hotspotArray)[1].hotspotVoiceEnglish));
        }
        if (audioUrl != null) {
          setShowAudio(true);
        } else {
          setShowAudio(false);
        }
        setPlayAudio(false);
        audio.pause();
        if (JSON.parse(locationDetails[2].hotspotArray)[1].hotspotImage) {
          setHotspotImage(JSON.parse(locationDetails[2].hotspotArray)[1].hotspotImage);
          setTimeout(() => {
            setShowImageLoading(false);
          }, 2000);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[1].hotspotTitleEnglish) {
          setHostpotTitle(JSON.parse(locationDetails[2].hotspotArray)[1].hotspotTitleEnglish);
        }
        if (JSON.parse(locationDetails[2].hotspotArray)[1].hotspotDescriptionEnglish) {
          setHostpotDescription(JSON.parse(locationDetails[2].hotspotArray)[1].hotspotDescriptionEnglish);
        }
        break;
    }
    setHotspotOpen(true);
  };

  const [isEnter, setIsEnter] = useState(false);

  const navigateToPortraitCollectionFirstFloor = () => {
    navigate("/portrait-collection");
  };

  const navigateToCommitteeRooms = () => {
    navigate("/committee-rooms");
  };

  const [isDrag, setIsDrag] = React.useState(false);

  const onDrag = () => {
    setIsDrag(true);
  };

  const onDragLeave = () => {
    setIsDrag(false);
  };

  const handleTutorialOpen = () => {
    setAnchorEl(null);
    setOpen(false);
    setShowIframe(false);
    setIframeUrl("");
    setHotspotOpen(false);
    setFullImageOpen(false);
    setShowAudio(false);
    setPlayAudio(false);
    audio.pause();
    setTutorialOpen(true);
    setVariable('Open');
  };

  const handleTutorialClose = () => {
    setTutorialOpen(false);
  };

  const endSliderClick = () => {
    setAnchorEl(null);
    setShowIframe(false);
    setIframeUrl("");
    setTutorialOpen(false);
    setOpen(false);
    setHotspotOpen(false);
    setFullImageOpen(false);
    setShowAudio(false);
    setPlayAudio(false);
    audio.pause();
    setVariable('Close');
  };


  const handleFullImageOpen = () => {
    setFullImageOpen(true);
    setShowIframe(false);
    setIframeUrl("");
    audio.pause();
    setPlayAudio(false);
  };

  const handleFullImageClose = () => {
    setFullImageOpen(false);
  };

  const seeMoreClick = () => {
    setSeeMore(true);
  };

  const seeLessClick = () => {
    setSeeMore(false);
  };

  const handlePresentaionViewClose = () => {
    setAnchorEl(null);
    setTutorialOpen(false);
    setShowIframe(false);
    setIframeUrl("");
    setOpen(false);
    setHotspotOpen(false);
    setFullImageOpen(false);
    setShowAudio(false);
    setPlayAudio(false);
    setPresentationOpen(false);
  };

  const handlePresentaionViewOpen = () => {
    setAnchorEl(null);
    setTutorialOpen(false);
    setShowIframe(false);
    setIframeUrl("");
    setOpen(false);
    setHotspotOpen(false);
    setFullImageOpen(false);
    setShowAudio(false);
    setPlayAudio(false);
    setPresentationOpen(true);
  };

  const handleSnackBarOpen = () => {
    setShowSnackBar(true);
  };

  const handleSnackBarClose = () => {
    setShowSnackBar(false);
  };


  return (
    <Fullscreen isEnter={isEnter} onChange={setIsEnter}>
      <div className="page-container">
        <Invisible />
        {isLoading && (<Loading />)}
        <div
          className={[
            "all-locations-popup-container",
            open ? "dialog-open" : "dialog-close",
          ].join(" ")}
        >
          <IconButton className="all-locations-close-icon" onClick={handleClose}>
            <CancelIcon />
          </IconButton>
          <AllLocations />
        </div>

        <div
          className={[
            "full-info-dialog",
            fullImageOpen ? "dialog-open" : "dialog-close",
          ].join(" ")}
        >
          <IconButton className="close-full-icon" onClick={handleFullImageClose}>
            <CancelIcon />
          </IconButton>
          <div className="full-dialog-container">
            <img className="full-dialog-image" src={hotspotImage} alt="" />
          </div>
        </div>

        <Snackbar open={showSnackBar} autoHideDuration={6000} onClose={handleSnackBarClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={handleSnackBarClose} severity="warning" sx={{ width: '100%' }}>
            Please close the information pop up to resume navigation
          </Alert>
        </Snackbar>

        <div
          className={[
            "info-dialog-popup-container",
            hotspotOpen ? "dialog-open" : "dialog-close",
          ].join(" ")}
        >
          <div onClick={handleSnackBarOpen}
            className={[
              hotspotOpen ? "modal-bg" : "",
            ].join(" ")}
          >
          </div>
          <IconButton className="info-close-icon" onClick={handleHotspotClose}>
            <CancelIcon />
          </IconButton>
          <div className={[
            "info-dialog",
            hotspotText ? "info-dialog-long" : "",
          ].join(" ")}>
            {hotspotImage && showImageLoading && (
              <section className="skeleton-container">
                <Skeleton className={[
                  "img-skeleton",
                  showAudio ? "" : "img-skeleton-no-audio",
                ].join(" ")} variant="rectangular" sx={{ bgcolor: '#606060', borderRadius: 5, marginBottom: 3 }} />
              </section>
            )}
            {hotspotImage && !showImageLoading && (
              <img className="info-dialog-img info-dialog-img-sm" src={hotspotImage} alt="" onClick={handleFullImageOpen} />
            )}
            <div className={[
              "info-content-row",
              hotspotText ? "info-content-row-margin" : "",
            ].join(" ")}>
              {!showAudio && (
                <div className="flex-row-center">
                  <span className="info-view-text">{hotspotTitle}</span>
                </div>
              )}
              {showAudio && (
                <section>
                  {!playAudio && (
                    <div className="flex-row-center">
                      <span className="info-view-text">{hotspotTitle}</span>
                      <div className="audio-icon-container" onClick={handleAudioPlay}>
                        <div className="audio-img"></div>
                      </div>
                    </div>
                  )}
                  {playAudio && (
                    <div className="audio-container" onClick={pauseAudioPlay}>
                      <ul className="audio-div">
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                        <li className="audio-wave"></li>
                      </ul>
                      <div className="audio-icon-container" onClick={handleAudioPlay}>
                        <div className="audio-pause-img"></div>
                      </div>
                    </div>
                  )}
                </section>
              )}
              {hotspotText && (
                <section>
                  <div className="info-desc-container">
                    <span
                      className={[
                        "info-desc",
                        seeMore ? "info-desc-expand" : "",
                        seeMore ? "fade-in" : "",
                      ].join(" ")}
                    >
                      {hotspotText}
                    </span>
                  </div>
                  {!seeMore && (
                    <div className="see-more-less-container" onClick={seeMoreClick}>
                      <span>View More</span>
                      <ExpandMoreIcon />
                    </div>
                  )}
                  {seeMore && (
                    <div className="see-more-less-container" onClick={seeLessClick}>
                      <span>View Less</span>
                      <ExpandLessIcon />
                    </div>
                  )}
                </section>
              )}
            </div>
          </div>
        </div>

        <div
          className={[
            "tutorial-modal-container",
            tutorialOpen ? "dialog-open" : "dialog-close",
          ].join(" ")}
        >
          <div className="tutorial-popup">
            <div className="tutorial-popup-container">
              <TutorialSlider onSliderEndClick={endSliderClick} variable={variable} />
            </div>
          </div>
        </div>

        <div
          className={[
            "presentation-popup-container",
            presentationOpen ? "dialog-open" : "dialog-close",
          ].join(" ")}
        >
          <div className="presentation-popup">
            <div className="tutorial-popup-container">
              <div className="presentation-view-container">
                <span className="presentation-popup-text">Switching to presentation view will load images in high resolution.</span>
                <span className="presentation-popup-text">Do you want to continue ?</span>
                <div className="presentation-view-btn-row">
                  <Button
                    className="presentation-btn-yes"
                    variant="contained"
                    onClick={handlePresentaionViewClose}
                  >
                    Yes
                  </Button>
                  <Button
                    className="presentation-btn-no"
                    variant="contained"
                    onClick={handlePresentaionViewClose}
                  >
                    No
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <IconButton className="mob-menu-btn" onClick={handleMenuOpen}>
          <MenuIcon />
        </IconButton>
        <Menu
          className="menu-container"
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={handleMenuClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={goHome}>
            <div className="home-icon"></div>
          </MenuItem>
          <MenuItem onClick={handleTutorialOpen}>
            <div className="tutorial-icon"></div>
          </MenuItem>
        </Menu>

        <div
          className={[
            "pannellum-image",
            imageIndex == 1 ? "pannellum-image-fade-in" : "pannellum-image-fade-out",
          ].join(" ")}
        >
          <Pannellum
            width="100%"
            height="100vh"
            image={imageOneSrc}
            pitch={2}
            yaw={840}
            hfov={hfov}
            autoLoad
            showZoomCtrl={false}
            showFullscreenCtrl={false}
            onLoad={() => { onImageLoad() }}
            onMousedown={(evt) => {
              onDrag();
            }}
            onMouseup={(evt) => {
              onDragLeave();
            }}
            onTouchstart={(evt) => {
              onDrag();
            }}
            onTouchend={(evt) => {
              onDragLeave();
            }}
          >
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot"
              pitch={-6}
              yaw={811.5}
              handleClick={(evt, name) => handleHotspotFourOneClickOpen()}
              name="hs11"
            />

            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot-two"
              pitch={-5.2}
              yaw={847}
              handleClick={(evt, name) => handleHotspotFourTwoClickOpen()}
              name="hs12"
            />

            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot-three"
              pitch={-4.9}
              yaw={308}
              handleClick={(evt, name) => handleHotspotFourThreeClickOpen()}
              name="hs13"
            />

            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot-four"
              pitch={-4.5}
              yaw={272}
              handleClick={(evt, name) => handleHotspotFourFourClickOpen()}
              name="hs14"
            />
          </Pannellum>
        </div>
        <div
          className={[
            "pannellum-image",
            imageIndex == 2 ? "pannellum-image-fade-in" : "pannellum-image-fade-out",
          ].join(" ")}
        >
          <Pannellum
            width="100%"
            height="100vh"
            image={imageTwoSrc}
            pitch={2}
            yaw={155}
            hfov={hfov}
            autoLoad
            showZoomCtrl={false}
            showFullscreenCtrl={false}
            onLoad={() => { onImageLoad() }}
            onMousedown={(evt) => {
              onDrag();
            }}
            onMouseup={(evt) => {
              onDragLeave();
            }}
            onTouchstart={(evt) => {
              onDrag();
            }}
            onTouchend={(evt) => {
              onDragLeave();
            }}
          >
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot"
              pitch={-9}
              yaw={307}
              handleClick={(evt, name) => handleHotspotFiveOneClickOpen()}
              name="hs21"
            />

            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot-two"
              pitch={-3.2}
              yaw={165.5}
              handleClick={(evt, name) => handleHotspotFiveTwoClickOpen()}
              name="hs22"
            />

            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot-three"
              pitch={-10.5}
              yaw={136}
              handleClick={(evt, name) => handleHotspotFiveThreeClickOpen()}
              name="hs23"
            />
          </Pannellum>
        </div>
        <div
          className={[
            "pannellum-image",
            imageIndex == 3 ? "pannellum-image-fade-in" : "pannellum-image-fade-out",
          ].join(" ")}
        >
          <Pannellum
            width="100%"
            height="100vh"
            image={imageThreeSrc}
            pitch={5}
            yaw={300}
            hfov={hfov}
            autoLoad
            showZoomCtrl={false}
            showFullscreenCtrl={false}
            onLoad={() => { onImageLoad() }}
            onMousedown={(evt) => {
              onDrag();
            }}
            onMouseup={(evt) => {
              onDragLeave();
            }}
            onTouchstart={(evt) => {
              onDrag();
            }}
            onTouchend={(evt) => {
              onDragLeave();
            }}
          >
            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot"
              pitch={-8.9}
              yaw={142.5}
              handleClick={(evt, name) => handleHotspotSixOneClickOpen()}
              name="hs31"
            />

            <Pannellum.Hotspot
              type="custom"
              cssClass="loc-hotspot-two"
              pitch={-20}
              yaw={312}
              handleClick={(evt, name) => handleHotspotSixTwoClickOpen()}
              name="hs32"
            />
          </Pannellum>
        </div>


        <section className={[
          "footer-section",
          isShowFooter ? "show-footer" : "hide-footer",
        ].join(" ")}>
          <div
            className={[
              "footer-container",
              isDrag ? "footer-fade-out" : "footer-fade-in",
            ].join(" ")}
          >
            <Button
              onClick={handleClickOpen}
              className="locations-btn"
              variant="outlined"
              startIcon={<MenuIcon />}
            >
              {localStorage.getItem("language") == 'en' && (
                <span>All Locations</span>
              )}
              {localStorage.getItem("language") == 'sl' && (
                <span>සියලුම ස්ථාන</span>
              )}
              {localStorage.getItem("language") == 'tm' && (
                <span>அனைத்து இடங்களும்</span>
              )}
            </Button>
            <div className="stepper-container">
              <IconButton className="prev-next-icon" onClick={() => goPrevious()}>
                <ArrowBackIosNewIcon />
              </IconButton>
              <div className="stepper-nav-container">
                <div className="stepper-locations">
                  <span
                    className="stepper-location-name w-160"
                    onClick={navigateToPortraitCollectionFirstFloor}
                  >
                    {localStorage.getItem("language") == 'en' && (
                      <span>Portrait Gallery (First Floor)</span>
                    )}
                    {localStorage.getItem("language") == 'sl' && (
                      <span>ඡායරූප ගැලරිය (පළමු මහල)</span>
                    )}
                    {localStorage.getItem("language") == 'tm' && (
                      <span>புககப்பட மதாகுப்பு (முதைாவது தளம்)</span>
                    )}
                  </span>
                  <span className={[
                    "stepper-location-name-active w-160",
                    localStorage.getItem("language") == 'sl' ? "mlpm-30" : "mlp-42",
                    localStorage.getItem("language") == 'tm' ? "mlpm-0" : "mlp-42",
                  ].join(" ")}>
                    {locationName}
                  </span>
                  <span className="stepper-location-name" onClick={navigateToCommitteeRooms}>
                    {localStorage.getItem("language") == 'en' && (
                      <span>Committee Rooms</span>
                    )}
                    {localStorage.getItem("language") == 'sl' && (
                      <span>කාරක සභා කාමර</span>
                    )}
                    {localStorage.getItem("language") == 'tm' && (
                      <span>குழு அறைகள்</span>
                    )}
                  </span>
                </div>
                <div className="stepper-navigations">
                  <span
                    className="stepper-navigations-name"
                    onClick={() => goPrevious()}
                  >
                    {localStorage.getItem("language") == 'en' && (
                      <span>Previous</span>
                    )}
                    {localStorage.getItem("language") == 'sl' && (
                      <span>පෙර</span>
                    )}
                    {localStorage.getItem("language") == 'tm' && (
                      <span>முந்தைய</span>
                    )}
                  </span>
                  <span className="stepper-line-left"></span>
                  <div className="stepper-circle-container">
                    <section
                      className={["mr-10", imageIndex === 1 ? "pulse" : ""].join(
                        " "
                      )}
                      onClick={() => changeImage(1)}
                    >
                      <div
                        className={[
                          "stepper-circle-multi",
                          imageIndex === 1 ? "active-stepper" : "",
                          imageIndex === 1 ? "fade-in-fwd" : "",
                        ].join(" ")}
                      >
                        <div className="stepper-circle-multi-inner"></div>
                      </div>
                    </section>

                    <section
                      className={["mr-10", imageIndex === 2 ? "pulse" : ""].join(
                        " "
                      )}
                      onClick={() => changeImage(2)}
                    >
                      <div
                        className={[
                          "stepper-circle-multi",
                          imageIndex === 2 ? "active-stepper" : "",
                          imageIndex === 2 ? "fade-in-fwd" : "",
                        ].join(" ")}
                      >
                        <div className="stepper-circle-multi-inner"></div>
                      </div>
                    </section>

                    <section
                      className={["", imageIndex === 3 ? "pulse" : ""].join(" ")}
                      onClick={() => changeImage(3)}
                    >
                      <div
                        className={[
                          "stepper-circle-multi",
                          imageIndex === 3 ? "active-stepper" : "",
                          imageIndex === 3 ? "fade-in-fwd" : "",
                        ].join(" ")}
                      >
                        <div className="stepper-circle-multi-inner"></div>
                      </div>
                    </section>

                  </div>
                  <span className="stepper-line-right"></span>
                  <span
                    className="stepper-navigations-name"
                    onClick={() => goNext()}
                  >
                    {localStorage.getItem("language") == 'en' && (
                      <span>Next</span>
                    )}
                    {localStorage.getItem("language") == 'sl' && (
                      <span>ඊළඟ</span>
                    )}
                    {localStorage.getItem("language") == 'tm' && (
                      <span>அடுத்தது</span>
                    )}
                  </span>
                </div>
              </div>
              <IconButton className="prev-next-icon" onClick={() => goNext()}>
                <ArrowForwardIosIcon />
              </IconButton>
            </div>
            <section
              className={[
                "footer-icon-section",
                isEnter ? "exit-full-screen" : "",
              ].join(" ")}
            >
              <FooterIconBar
                onOpenPresentaionView={handlePresentaionViewOpen}
                onOpenTutorial={handleTutorialOpen}
                onFullscreenToggle={() => {
                  setIsEnter(!isEnter);
                }}
              />
            </section>
          </div>
          <div
            className={[
              "footer-container-mob",
              isDrag ? "footer-fade-out" : "footer-fade-in",
            ].join(" ")}
          >
            <IconButton className="prev-next-icon" onClick={() => goPrevious()}>
              <ArrowBackIosNewIcon />
            </IconButton>
            <div className="flex-column-container">
              <div className="stepper-circle-container">
                <section
                  className={["mr-10", imageIndex === 1 ? "pulse" : ""].join(" ")}
                  onClick={() => changeImage(1)}
                >
                  <div
                    className={[
                      "stepper-circle-multi",
                      imageIndex === 1 ? "active-stepper" : "",
                      imageIndex === 1 ? "fade-in-fwd" : "",
                    ].join(" ")}
                  >
                    <div className="stepper-circle-multi-inner"></div>
                  </div>
                </section>

                <section
                  className={["mr-10", imageIndex === 2 ? "pulse" : ""].join(" ")}
                  onClick={() => changeImage(2)}
                >
                  <div
                    className={[
                      "stepper-circle-multi",
                      imageIndex === 2 ? "active-stepper" : "",
                      imageIndex === 2 ? "fade-in-fwd" : "",
                    ].join(" ")}
                  >
                    <div className="stepper-circle-multi-inner"></div>
                  </div>
                </section>

                <section
                  className={["", imageIndex === 3 ? "pulse" : ""].join(" ")}
                  onClick={() => changeImage(3)}
                >
                  <div
                    className={[
                      "stepper-circle-multi",
                      imageIndex === 3 ? "active-stepper" : "",
                      imageIndex === 3 ? "fade-in-fwd" : "",
                    ].join(" ")}
                  >
                    <div className="stepper-circle-multi-inner"></div>
                  </div>
                </section>

              </div>
              <span className="stepper-location-name-active">{locationName}</span>
              <span className="locations-btn-link" onClick={handleClickOpen}>
                {localStorage.getItem("language") == 'en' && (
                  <span>All Locations</span>
                )}
                {localStorage.getItem("language") == 'sl' && (
                  <span>සියලුම ස්ථාන</span>
                )}
                {localStorage.getItem("language") == 'tm' && (
                  <span>அனைத்து இடங்களும்</span>
                )}
              </span>
            </div>
            <IconButton className="prev-next-icon" onClick={() => goNext()}>
              <ArrowForwardIosIcon />
            </IconButton>
          </div>
        </section>


      </div>
    </Fullscreen>
  );
};

export default PortraitCollectionSecondFloor;
