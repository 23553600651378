import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import Landing from "./pages/landing/Landing";
import Video from "./pages/landing/Video";
import Tutorial from "./pages/tutorial/Tutorial";
import PublicEntrance from "./pages/locations/PublicEntrance";
import CopperGate from "./pages/locations/CopperGate";
import Chambers from "./pages/locations/Chambers";
import NotFound from "./pages/general/NotFound";
import ExhibitsArea from "./pages/locations/ExhibitsArea";
import SilverDoor from "./pages/locations/SilverDoor";
import PortraitCollection from "./pages/locations/PortraitCollection";
import CommitteeRooms from "./pages/locations/CommitteeRooms";
import ParliamentLibrary from "./pages/locations/ParliamentLibrary";
import MinisterEntrance from "./pages/locations/MinisterEntrance";
import PortraitCollectionSecondFloor from "./pages/locations/PortraitCollectionSecondFloor";
import ExitVideo from "./pages/landing/ExitVideo";

function App() {
  let location = useLocation();
  return (
    <div className="App">
      {/* <TransitionGroup>
        <CSSTransition key={location.pathname} classNames="fade" timeout={3000}>
           
        </CSSTransition>
      </TransitionGroup> */}
      <Routes>
        <Route path="/" element={<Landing />}></Route>
        <Route path="/video" element={<Video />}></Route>
        <Route path="/exit-video" element={<ExitVideo />}></Route>
        <Route path="/tutorial" element={<Tutorial />}></Route>
        <Route path="/public-entrance" element={<PublicEntrance />}></Route>
        <Route path="/exhibits-area" element={<ExhibitsArea />}></Route>
        <Route path="/copper-gate" element={<CopperGate />}></Route>
        <Route path="/silver-door" element={<SilverDoor />}></Route>
        <Route path="/chamber" element={<Chambers />}></Route>
        <Route path="/portrait-collection" element={<PortraitCollection />}></Route>
        <Route path="/portrait-collection-second-floor" element={<PortraitCollectionSecondFloor />}></Route>
        <Route path="/committee-rooms" element={<CommitteeRooms />}></Route>
        <Route path="/parliament-library" element={<ParliamentLibrary />}></Route>
        <Route path="/minister-entrance" element={<MinisterEntrance />}></Route>
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </div>
  );
}

export default App;
