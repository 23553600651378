import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import slEmblem from "../../images/logo.jpg";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";

import axios from "axios";

const Landing = () => {
  const [playVideo, setPlayVideo] = React.useState(false);

  const navigate = useNavigate();

  const navigateToVideo = () => navigate("/video");

  const onLanguageSelect = (langCode) => {
    localStorage.setItem("language", langCode);
    const seenTutorial = localStorage.getItem("seenTutorial");
    if (seenTutorial === "1") {
      navigate("/video");
    } else {
      navigate("/tutorial");
    }
  };

  const videoReady = () => {
    setPlayVideo(true);
  };

  useEffect(() => {
    getColors();
  }, []);

  const getColors = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API + "Colors/getColors");
      document.documentElement.style.setProperty(
        "--primary-color",
        response.data[0].primaryColor
      );
      document.documentElement.style.setProperty(
        "--secondary-color",
        response.data[0].secondaryColor
      );
    } catch (error) {
      console.error(error);
      console.log("There was an error!", error);
    }
  };


  return (
    <div className="video-page-container">
      {!playVideo && (<div className="loading-black-bg"></div>)}
      <div className="video-bg">
        <ReactPlayer
          className="video-container"
          url="https://res.cloudinary.com/ditejyeth/video/upload/v1694443118/intro_video_lowest_bx8gbw.mp4"
          loop={true}
          playing={playVideo}
          muted={true}
          controls={false}
          playsinline={true}
          onReady={() => videoReady()}
        />
        <div className="landing-container">
          <div className="center-container">
            <div className="landing-center-container">
              <div className="flex-column-container">
                <img className="sl-emblem" src={slEmblem} alt="" />
                <span className="landing-heading">VIRTUAL TOUR OF</span>
                <span className="landing-heading">PARLIAMENT OF SRI LANKA</span>
              </div>
              <div className="flex-column-container language-div">
                <span className="language-select-text">
                  SELECT LANGUAGE TO START TOUR
                </span>
                <Button
                  className="language-btn"
                  variant="contained" onClick={() => onLanguageSelect('en')}
                >
                  English
                </Button>
                <Button
                  className="language-btn"
                  variant="contained"
                   onClick={() => onLanguageSelect('sl')}
                >
                  සිංහල
                </Button>
                <Button
                  className="language-btn"
                  variant="contained"
                   onClick={() => onLanguageSelect('tm')}
                >
                  தமிழ்
                </Button>
              </div>
            </div>
            <span className="background-video-link" onClick={navigateToVideo}>
              WATCH BACKGROUND VIDEO
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
