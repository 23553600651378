import React, { useEffect } from "react";
import TutorialSlider from "../../components/TutorialSlider";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Tutorial = () => {
  const navigate = useNavigate();

  useEffect(() => {
    getColors();
  }, []);

  const getColors = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API + "Colors/getColors");
      document.documentElement.style.setProperty(
        "--primary-color",
        response.data[0].primaryColor
      );
      document.documentElement.style.setProperty(
        "--secondary-color",
        response.data[0].secondaryColor
      );
    } catch (error) {
      console.error(error);
      console.log("There was an error!", error);
    }
  };

  const endSliderClick = () => {
    localStorage.setItem("seenTutorial", "1");
    navigate("/copper-gate");
  };

  return (
    <div className="page-container">
      <div className="tutorial-bg">
        <div className="tutorial-backdrop">
          <div className="tutorial-dialog-container">
            <TutorialSlider onSliderEndClick={endSliderClick} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tutorial;
