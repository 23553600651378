import React, { useState, useEffect } from "react";
import { Pannellum } from "pannellum-react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MenuIcon from "@mui/icons-material/Menu";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CancelIcon from "@mui/icons-material/Cancel";
import Fullscreen from "fullscreen-react";
import Slide from "@mui/material/Slide";
import { useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import projectorIcon from "../../images/icons/projector.svg";
import AllLocations from "../../components/AllLocations";
import FooterIconBar from "../../components/FooterIconBar";
import TutorialSlider from "../../components/TutorialSlider";
import Loading from "../../components/Loading";

import axios from "axios";
import Invisible from "../../components/Invisible";

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MinisterEntrance = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [isShowFooter, setIsShowFooter] = useState(false);

  const [locationDetails, setLocationDetails] = React.useState([]);
  const [locationName, setLocationName] = React.useState("");
  const [imageOneSrc, setImageOneSrc] = React.useState("");
  const [imageTwoSrc, setImageTwoSrc] = React.useState("");
  const [imageThreeSrc, setImageThreeSrc] = React.useState("");
  const [imageFourSrc, setImageFourSrc] = React.useState("");
  const [presentationOpen, setPresentationOpen] = React.useState(false);
  const [showSnackBar, setShowSnackBar] = React.useState(false);
  const [variable, setVariable] = useState(null);

  const getLocationDetails = async () => {
    setIsShowFooter(false);
    setIsLoading(true);
    try {
      const response = await axios.get(process.env.REACT_APP_API + 'Locations/locationDetails/getDetailsByLocation/mpp%20entrance');
      setLocationDetails(response.data);
      switch (localStorage.getItem("language")) {
        case 'en':
          setLocationName(response.data[0].nameInEnglish);
          break;
        case 'sl':
          setLocationName(response.data[0].nameInSinhala);
          break;
        case 'tm':
          setLocationName(response.data[0].nameInTamil);
          break;
        default:
          setLocationName(response.data[0].nameInEnglish);
          break;
      }
      setImageOneSrc(response.data[0].locationImage);
      setImageTwoSrc(response.data[1].locationImage);
      setImageThreeSrc(response.data[2].locationImage);
      setImageFourSrc(response.data[3].locationImage);
      setImageIndex(1);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      console.log("There was an error!", error);
    }
  };

  const onImageLoad = () => {
    setTimeout(() => {
      setIsShowFooter(true);
    }, 1000);
  }

  const [open, setOpen] = React.useState(false);
  const [imageIndex, setImageIndex] = React.useState(1);
  const [hfov, setHfov] = React.useState(100);
  const [tutorialOpen, setTutorialOpen] = React.useState(false);
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getLocationDetails();
    localStorage.setItem("mppEntrance", "1");
    if (window.innerWidth < 481) {
      setHfov(60);
    } else {
      setHfov(100);
    }
  }, []);

  const changeImage = (id) => {
    switch (id) {
      case 1:
        switch (localStorage.getItem("language")) {
          case 'en':
            setLocationName(locationDetails[0].nameInEnglish);
            break;
          case 'sl':
            setLocationName(locationDetails[0].nameInSinhala);
            break;
          case 'tm':
            setLocationName(locationDetails[0].nameInTamil);
            break;
          default:
            setLocationName(locationDetails[0].nameInEnglish);
            break;
        }
        setImageIndex(1);
        break;
      case 2:
        switch (localStorage.getItem("language")) {
          case 'en':
            setLocationName(locationDetails[1].nameInEnglish);
            break;
          case 'sl':
            setLocationName(locationDetails[1].nameInSinhala);
            break;
          case 'tm':
            setLocationName(locationDetails[1].nameInTamil);
            break;
          default:
            setLocationName(locationDetails[1].nameInEnglish);
            break;
        }
        setImageIndex(2);
        break;
      case 3:
        switch (localStorage.getItem("language")) {
          case 'en':
            setLocationName(locationDetails[2].nameInEnglish);
            break;
          case 'sl':
            setLocationName(locationDetails[2].nameInSinhala);
            break;
          case 'tm':
            setLocationName(locationDetails[2].nameInTamil);
            break;
          default:
            setLocationName(locationDetails[2].nameInEnglish);
            break;
        }
        setImageIndex(3);
        break;
      case 4:
        switch (localStorage.getItem("language")) {
          case 'en':
            setLocationName(locationDetails[3].nameInEnglish);
            break;
          case 'sl':
            setLocationName(locationDetails[3].nameInSinhala);
            break;
          case 'tm':
            setLocationName(locationDetails[3].nameInTamil);
            break;
          default:
            setLocationName(locationDetails[3].nameInEnglish);
            break;
        }
        setImageIndex(4);
        break;
      default:
        break;
    }
  };

  const goPrevious = () => {
    switch (imageIndex) {
      case 1:
        navigate("/copper-gate");
        break;
      case 2:
        switch (localStorage.getItem("language")) {
          case 'en':
            setLocationName(locationDetails[0].nameInEnglish);
            break;
          case 'sl':
            setLocationName(locationDetails[0].nameInSinhala);
            break;
          case 'tm':
            setLocationName(locationDetails[0].nameInTamil);
            break;
          default:
            setLocationName(locationDetails[0].nameInEnglish);
            break;
        }
        setImageIndex(1);
        break;
      case 3:
        switch (localStorage.getItem("language")) {
          case 'en':
            setLocationName(locationDetails[1].nameInEnglish);
            break;
          case 'sl':
            setLocationName(locationDetails[1].nameInSinhala);
            break;
          case 'tm':
            setLocationName(locationDetails[1].nameInTamil);
            break;
          default:
            setLocationName(locationDetails[1].nameInEnglish);
            break;
        }
        setImageIndex(2);
        break;
      case 4:
        switch (localStorage.getItem("language")) {
          case 'en':
            setLocationName(locationDetails[2].nameInEnglish);
            break;
          case 'sl':
            setLocationName(locationDetails[2].nameInSinhala);
            break;
          case 'tm':
            setLocationName(locationDetails[2].nameInTamil);
            break;
          default:
            setLocationName(locationDetails[2].nameInEnglish);
            break;
        }
        setImageIndex(3);
        break;
      default:
        break;
    }
  };

  const goNext = () => {
    switch (imageIndex) {
      case 1:
        switch (localStorage.getItem("language")) {
          case 'en':
            setLocationName(locationDetails[1].nameInEnglish);
            break;
          case 'sl':
            setLocationName(locationDetails[1].nameInSinhala);
            break;
          case 'tm':
            setLocationName(locationDetails[1].nameInTamil);
            break;
          default:
            setLocationName(locationDetails[1].nameInEnglish);
            break;
        }
        setImageIndex(2);
        break;
      case 2:
        switch (localStorage.getItem("language")) {
          case 'en':
            setLocationName(locationDetails[2].nameInEnglish);
            break;
          case 'sl':
            setLocationName(locationDetails[2].nameInSinhala);
            break;
          case 'tm':
            setLocationName(locationDetails[2].nameInTamil);
            break;
          default:
            setLocationName(locationDetails[2].nameInEnglish);
            break;
        }
        setImageIndex(3);
        break;
      case 3:
        switch (localStorage.getItem("language")) {
          case 'en':
            setLocationName(locationDetails[3].nameInEnglish);
            break;
          case 'sl':
            setLocationName(locationDetails[3].nameInSinhala);
            break;
          case 'tm':
            setLocationName(locationDetails[3].nameInTamil);
            break;
          default:
            setLocationName(locationDetails[3].nameInEnglish);
            break;
        }
        setImageIndex(4);
        break;
      case 4:
        navigate("/public-entrance");
        break;
      default:
        break;
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const goHome = () => {
    setAnchorEl(null);
    navigate("/");
  };

  const [isEnter, setIsEnter] = useState(false);

  const navigateToPublicEntrance = () => {
    navigate("/public-entrance");
  };

  const navigateToCopperGate = () => {
    navigate("/copper-gate");
  };

  const [isDrag, setIsDrag] = React.useState(false);

  const onDrag = () => {
    setIsDrag(true);
  };

  const onDragLeave = () => {
    setIsDrag(false);
  };

  const handleTutorialOpen = () => {
    setAnchorEl(null);
    setOpen(false);
    setTutorialOpen(true);
    setVariable('Open');
  };

  const handleTutorialClose = () => {
    setTutorialOpen(false);
  };

  const endSliderClick = () => {
    setAnchorEl(null);
    setTutorialOpen(false);
    setOpen(false);
    setVariable('Close');
  };

  const handlePresentaionViewClose = () => {
    setAnchorEl(null);
    setTutorialOpen(false);
    setOpen(false);
    setPresentationOpen(false);
  };

  const handlePresentaionViewOpen = () => {
    setAnchorEl(null);
    setTutorialOpen(false);
    setOpen(false);
    setPresentationOpen(true);
  };

  const handleSnackBarOpen = () => {
    setShowSnackBar(true);
  };

  const handleSnackBarClose = () => {
    setShowSnackBar(false);
  };


  return (
    <Fullscreen isEnter={isEnter} onChange={setIsEnter}>
      <div className="page-container">
        <Invisible />
        {isLoading && (<Loading />)}
        <div
          className={[
            "all-locations-popup-container",
            open ? "dialog-open" : "dialog-close",
          ].join(" ")}
        >
          <IconButton className="all-locations-close-icon" onClick={handleClose}>
            <CancelIcon />
          </IconButton>
          <AllLocations />
        </div>

        <div
          className={[
            "tutorial-modal-container",
            tutorialOpen ? "dialog-open" : "dialog-close",
          ].join(" ")}
        >
          <div className="tutorial-popup">
            <div className="tutorial-popup-container">
              <TutorialSlider onSliderEndClick={endSliderClick} variable={variable} />
            </div>
          </div>
        </div>

        <div
          className={[
            "presentation-popup-container",
            presentationOpen ? "dialog-open" : "dialog-close",
          ].join(" ")}
        >
          <div className="presentation-popup">
            <div className="tutorial-popup-container">
              <div className="presentation-view-container">
                <span className="presentation-popup-text">Switching to presentation view will load images in high resolution.</span>
                <span className="presentation-popup-text">Do you want to continue ?</span>
                <div className="presentation-view-btn-row">
                  <Button
                    className="presentation-btn-yes"
                    variant="contained"
                    onClick={handlePresentaionViewClose}
                  >
                    Yes
                  </Button>
                  <Button
                    className="presentation-btn-no"
                    variant="contained"
                    onClick={handlePresentaionViewClose}
                  >
                    No
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <IconButton className="mob-menu-btn" onClick={handleMenuOpen}>
          <MenuIcon />
        </IconButton>
        <Menu
          className="menu-container"
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={handleMenuClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={goHome}>
            <div className="home-icon"></div>
          </MenuItem>
          <MenuItem onClick={handleTutorialOpen}>
            <div className="tutorial-icon"></div>
          </MenuItem>
        </Menu>

        <div
          className={[
            "pannellum-image",
            imageIndex == 1 ? "pannellum-image-fade-in" : "pannellum-image-fade-out",
          ].join(" ")}
        >
          <Pannellum
            width="100%"
            height="100vh"
            image={imageOneSrc}
            pitch={-5}
            yaw={362}
            hfov={hfov}
            autoLoad
            showZoomCtrl={false}
            showFullscreenCtrl={false}
            onLoad={() => { onImageLoad() }}
            onMousedown={(evt) => {
              onDrag();
            }}
            onMouseup={(evt) => {
              onDragLeave();
            }}
            onTouchstart={(evt) => {
              onDrag();
            }}
            onTouchend={(evt) => {
              onDragLeave();
            }}
          >
          </Pannellum>
        </div>
        <div
          className={[
            "pannellum-image",
            imageIndex == 2 ? "pannellum-image-fade-in" : "pannellum-image-fade-out",
          ].join(" ")}
        >
          <Pannellum
            width="100%"
            height="100vh"
            image={imageTwoSrc}
            pitch={-5}
            yaw={362}
            hfov={hfov}
            autoLoad
            showZoomCtrl={false}
            showFullscreenCtrl={false}
            onLoad={() => { onImageLoad() }}
            onMousedown={(evt) => {
              onDrag();
            }}
            onMouseup={(evt) => {
              onDragLeave();
            }}
            onTouchstart={(evt) => {
              onDrag();
            }}
            onTouchend={(evt) => {
              onDragLeave();
            }}
          >
          </Pannellum>
        </div>

        <div
          className={[
            "pannellum-image",
            imageIndex == 3 ? "pannellum-image-fade-in" : "pannellum-image-fade-out",
          ].join(" ")}
        >
          <Pannellum
            width="100%"
            height="100vh"
            image={imageThreeSrc}
            pitch={-5}
            yaw={362}
            hfov={hfov}
            autoLoad
            showZoomCtrl={false}
            showFullscreenCtrl={false}
            onLoad={() => { onImageLoad() }}
            onMousedown={(evt) => {
              onDrag();
            }}
            onMouseup={(evt) => {
              onDragLeave();
            }}
            onTouchstart={(evt) => {
              onDrag();
            }}
            onTouchend={(evt) => {
              onDragLeave();
            }}
          >
          </Pannellum>
        </div>

        <div
          className={[
            "pannellum-image",
            imageIndex == 4 ? "pannellum-image-fade-in" : "pannellum-image-fade-out",
          ].join(" ")}
        >
          <Pannellum
            width="100%"
            height="100vh"
            image={imageFourSrc}
            pitch={-5}
            yaw={362}
            hfov={hfov}
            autoLoad
            showZoomCtrl={false}
            showFullscreenCtrl={false}
            onLoad={() => { onImageLoad() }}
            onMousedown={(evt) => {
              onDrag();
            }}
            onMouseup={(evt) => {
              onDragLeave();
            }}
            onTouchstart={(evt) => {
              onDrag();
            }}
            onTouchend={(evt) => {
              onDragLeave();
            }}
          >
          </Pannellum>
        </div>

        <section className={[
          "footer-section",
          isShowFooter ? "show-footer" : "hide-footer",
        ].join(" ")}>
          <div
            className={[
              "footer-container",
              isDrag ? "footer-fade-out" : "footer-fade-in",
            ].join(" ")}
          >
            <Button
              onClick={handleClickOpen}
              className="locations-btn"
              variant="outlined"
              startIcon={<MenuIcon />}
            >
              {localStorage.getItem("language") == 'en' && (
                <span>All Locations</span>
              )}
              {localStorage.getItem("language") == 'sl' && (
                <span>සියලුම ස්ථාන</span>
              )}
              {localStorage.getItem("language") == 'tm' && (
                <span>அனைத்து இடங்களும்</span>
              )}
            </Button>
            <div className="stepper-container">
              <IconButton className="prev-next-icon" onClick={() => goPrevious()}>
                <ArrowBackIosNewIcon />
              </IconButton>
              <div className="stepper-nav-container">
                <div className="stepper-locations">
                  <span
                    className="stepper-location-name"
                    onClick={navigateToCopperGate}
                  >
                    {localStorage.getItem("language") == 'en' && (
                      <span>Copper Door</span>
                    )}
                    {localStorage.getItem("language") == 'sl' && (
                      <span>තඹ දොරටුව</span>
                    )}
                    {localStorage.getItem("language") == 'tm' && (
                      <span>மெப்புக் கதவ</span>
                    )}
                  </span>
                  <span className={[
                    "stepper-location-name-active",
                    localStorage.getItem("language") == 'en' ? "mlpme" : "",
                    localStorage.getItem("language") == 'sl' ? "mlpme-66" : "",
                    localStorage.getItem("language") == 'tm' ? "mlpme-24" : ""
                  ].join(" ")}>
                    {locationName}
                  </span>
                  <span className={[
                    "stepper-location-name",
                    localStorage.getItem("language") == 'tm' ? "w-160" : ""
                  ].join(" ")} onClick={navigateToPublicEntrance}>
                    {localStorage.getItem("language") == 'en' && (
                      <span>Public Entrance</span>
                    )}
                    {localStorage.getItem("language") == 'sl' && (
                      <span>කාර්ය මණ්ඩල පිවිසුම</span>
                    )}
                    {localStorage.getItem("language") == 'tm' && (
                      <span>பணியாளர் நுழைவாயில்</span>
                    )}
                  </span>
                </div>
                <div className="stepper-navigations">
                  <span
                    className="stepper-navigations-name"
                    onClick={() => goPrevious()}
                  >
                    {localStorage.getItem("language") == 'en' && (
                      <span>Previous</span>
                    )}
                    {localStorage.getItem("language") == 'sl' && (
                      <span>පෙර</span>
                    )}
                    {localStorage.getItem("language") == 'tm' && (
                      <span>முந்தைய</span>
                    )}
                  </span>
                  <span className="stepper-line-left"></span>
                  <div className="stepper-circle-container">
                    <section
                      className={["mr-10", imageIndex === 1 ? "pulse" : ""].join(
                        " "
                      )}
                      onClick={() => changeImage(1)}
                    >
                      <div
                        className={[
                          "stepper-circle-multi",
                          imageIndex === 1 ? "active-stepper" : "",
                          imageIndex === 1 ? "fade-in-fwd" : "",
                        ].join(" ")}
                      >
                        <div className="stepper-circle-multi-inner"></div>
                      </div>
                    </section>

                    <section
                      className={["mr-10", imageIndex === 2 ? "pulse" : ""].join(
                        " "
                      )}
                      onClick={() => changeImage(2)}
                    >
                      <div
                        className={[
                          "stepper-circle-multi",
                          imageIndex === 2 ? "active-stepper" : "",
                          imageIndex === 2 ? "fade-in-fwd" : "",
                        ].join(" ")}
                      >
                        <div className="stepper-circle-multi-inner"></div>
                      </div>
                    </section>

                    <section
                      className={["mr-10", imageIndex === 3 ? "pulse" : ""].join(
                        " "
                      )}
                      onClick={() => changeImage(3)}
                    >
                      <div
                        className={[
                          "stepper-circle-multi",
                          imageIndex === 3 ? "active-stepper" : "",
                          imageIndex === 3 ? "fade-in-fwd" : "",
                        ].join(" ")}
                      >
                        <div className="stepper-circle-multi-inner"></div>
                      </div>
                    </section>

                    <section
                      className={["", imageIndex === 4 ? "pulse" : ""].join(
                        " "
                      )}
                      onClick={() => changeImage(4)}
                    >
                      <div
                        className={[
                          "stepper-circle-multi",
                          imageIndex === 4 ? "active-stepper" : "",
                          imageIndex === 4 ? "fade-in-fwd" : "",
                        ].join(" ")}
                      >
                        <div className="stepper-circle-multi-inner"></div>
                      </div>
                    </section>

                  </div>
                  <span className="stepper-line-right"></span>
                  <span
                    className="stepper-navigations-name"
                    onClick={() => goNext()}
                  >
                    {localStorage.getItem("language") == 'en' && (
                      <span>Next</span>
                    )}
                    {localStorage.getItem("language") == 'sl' && (
                      <span>ඊළඟ</span>
                    )}
                    {localStorage.getItem("language") == 'tm' && (
                      <span>அடுத்தது</span>
                    )}
                  </span>
                </div>
              </div>
              <IconButton className="prev-next-icon" onClick={() => goNext()}>
                <ArrowForwardIosIcon />
              </IconButton>
            </div>
            <section
              className={[
                "footer-icon-section",
                isEnter ? "exit-full-screen" : "",
              ].join(" ")}
            >
              <FooterIconBar
                onOpenPresentaionView={handlePresentaionViewOpen}
                onOpenTutorial={handleTutorialOpen}
                onFullscreenToggle={() => {
                  setIsEnter(!isEnter);
                }}
              />
            </section>
          </div>
          <div
            className={[
              "footer-container-mob",
              isDrag ? "footer-fade-out" : "footer-fade-in",
            ].join(" ")}
          >
            <IconButton className="prev-next-icon" onClick={() => goPrevious()}>
              <ArrowBackIosNewIcon />
            </IconButton>
            <div className="flex-column-container">
              <div className="stepper-circle-container">
                <section
                  className={["mr-10", imageIndex === 1 ? "pulse" : ""].join(" ")}
                  onClick={() => changeImage(1)}
                >
                  <div
                    className={[
                      "stepper-circle-multi",
                      imageIndex === 1 ? "active-stepper" : "",
                      imageIndex === 1 ? "fade-in-fwd" : "",
                    ].join(" ")}
                  >
                    <div className="stepper-circle-multi-inner"></div>
                  </div>
                </section>

                <section
                  className={["mr-10", imageIndex === 2 ? "pulse" : ""].join(" ")}
                  onClick={() => changeImage(2)}
                >
                  <div
                    className={[
                      "stepper-circle-multi",
                      imageIndex === 2 ? "active-stepper" : "",
                      imageIndex === 2 ? "fade-in-fwd" : "",
                    ].join(" ")}
                  >
                    <div className="stepper-circle-multi-inner"></div>
                  </div>
                </section>

                <section
                  className={["mr-10", imageIndex === 3 ? "pulse" : ""].join(" ")}
                  onClick={() => changeImage(3)}
                >
                  <div
                    className={[
                      "stepper-circle-multi",
                      imageIndex === 3 ? "active-stepper" : "",
                      imageIndex === 3 ? "fade-in-fwd" : "",
                    ].join(" ")}
                  >
                    <div className="stepper-circle-multi-inner"></div>
                  </div>
                </section>

                <section
                  className={["", imageIndex === 4 ? "pulse" : ""].join(" ")}
                  onClick={() => changeImage(4)}
                >
                  <div
                    className={[
                      "stepper-circle-multi",
                      imageIndex === 4 ? "active-stepper" : "",
                      imageIndex === 4 ? "fade-in-fwd" : "",
                    ].join(" ")}
                  >
                    <div className="stepper-circle-multi-inner"></div>
                  </div>
                </section>

              </div>
              <span className="stepper-location-name-active">{locationName}</span>
              <span className="locations-btn-link" onClick={handleClickOpen}>
                {localStorage.getItem("language") == 'en' && (
                  <span>All Locations</span>
                )}
                {localStorage.getItem("language") == 'sl' && (
                  <span>සියලුම ස්ථාන</span>
                )}
                {localStorage.getItem("language") == 'tm' && (
                  <span>அனைத்து இடங்களும்</span>
                )}
              </span>
            </div>
            <IconButton className="prev-next-icon" onClick={() => goNext()}>
              <ArrowForwardIosIcon />
            </IconButton>
          </div>
        </section>


      </div>
    </Fullscreen>
  );
};

export default MinisterEntrance;
